import React, {Suspense} from 'react'
import './LuckyDraw.scss'
import Navbar from "../../components/Navbar/Navbar";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Footer from "../../components/Footer/Footer";

// assets
const pageLounge = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/pagehome.jpg`
const person1 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/person1.jpg`

const personList = [
    {
        name: 'Alia Bhatt',
        image: person1,
    },
    {
        name: 'Rakesh K',
        image: person1,
    },
    {
        name: 'Jessica H',
        image: person1,
    },
    {
        name: 'Shraddha A',
        image: person1,
    },
    {
        name: 'Aayushi S',
        image: person1,
    },
]

const LuckyDraw = () => {

    return (
        <AuthModalLayout>
            <Suspense fallback={<div>Loading...</div>}>
                {/* navbar */}
                <div style={{marginBottom: '100px'}}>
                    <Navbar/>
                </div>
                <div className={'lucky-draw-page'}>

                    <div className={'white-fold mt-0 pb-4'}>
                        <div className="content">

                            <div className="lounge-section-2">
                                <div className="brand-section">
                                    <h5>Home</h5>
                                    <h5 className="px-1">/</h5>
                                    <h4>Lucky Draw</h4>
                                </div>
                                <div className="text-section">
                                    <h2>NueGo Lucky Draw Results</h2>
                                </div>
                                <div className="image-section2">
                                    <img src={pageLounge} alt="fram1"/>
                                </div>
                            </div>

                            <div className="winner-section">
                                <h2 className={'mb-2'}> Winner’s List</h2>
                                <p><a href={'http://bit.ly/3X8bbIF'} target={'_blank'}>Click on this link</a> to
                                    download the list of lucky ones who have won free travel for a year on our Indore -
                                    Bhopal route.</p>
                                {/*<div className="winner-list">*/}
                                {/*	<div className="row  winner-list-items gx-5 mt-0">*/}
                                {/*		{*/}
                                {/*			personList.map((item, index) => {*/}
                                {/*				return <div key={index} className="col-12 col-lg-4 col-md-6 box-1">*/}
                                {/*					<div className="item-1">*/}
                                {/*						<img src={item.image} className="rounded-pill" alt="person"/>*/}
                                {/*						<h2 className="px-3">{item.name}</h2>*/}
                                {/*					</div>*/}
                                {/*				</div>*/}
                                {/*			})*/}
                                {/*		}*/}
                                {/*		*/}
                                {/*	</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                        <div className="terms-condition">
                            <div className="terms-text">
                                <h2>Policies and T&C*</h2>
                                <p>Winners will have to present valid ID proof at the time of boarding their free trip,
                                    failing which they will be charged the Route fare in full. NueGo reserves the right
                                    to revoke the offer any time and all decisions taken by NueGo are binding and final.
                                    <a href={'https://bit.ly/3GQE9GG'} target={'_blank'}>Click on this link</a> to
                                    download the detailed T&C.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Suspense>
        </AuthModalLayout>

    )
}

export default LuckyDraw
