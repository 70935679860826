import graphql from "babel-plugin-relay/macro";

const PendingReviewQuery = graphql`
		query PendingReviewQuery{
			pendingReviews {
			    id
			    sourceCityName
			    destinationCityName
			}
		}	
	`;

export default PendingReviewQuery