import { useRef, useState } from "react";
import { createContext, useContext } from "react";
import { store } from "../../index";
import { verifyPersonalDetailsExtendedisValid } from "../../utils/utils";
import { useEffect } from "react";

const ReviewPaymentContext = createContext(null);

const ReviewPaymentContextProvider = ({ children }) => {
  const passengersCountFromStore =
    store.getState().BookingReducer.passengersCount;
  const passengerDataFromStore = store.getState().BookingReducer.passengers || [];
  const contactDataFromStore = store.getState().BookingReducer.setContactData;



  
  const [name, setName] = useState( passengerDataFromStore[0]?.name ?? null);
  const [age, setAge] = useState(passengerDataFromStore[0]?.age ?? null);
  const [error, setError] = useState({
    name: false,
    age: false,
  });
  const [contactDetail, setContactDetail] = useState({
    email: contactDataFromStore.email
      ? contactDataFromStore.email
      : localStorage.getItem("userEmail") ?? "",
    phoneNumber: contactDataFromStore.phoneNumber
      ? contactDataFromStore.phoneNumber
      : localStorage.getItem("userNumber") ?? "",
    gstName: contactDataFromStore.gstName,
    gstNumber: contactDataFromStore.gstNumber,
    gstState: contactDataFromStore.gstState,
    gstInvoice: contactDataFromStore.gstInvoice,
  });

  useEffect(() => {
    setContactDetail(contactDataFromStore);
  }
  , [contactDataFromStore]);

  return (
    <ReviewPaymentContext.Provider
      value={{
        age,
        setAge,
        setName,
        name,
        error,
        setError,
        contactDetail,
        setContactDetail,
        passengersCountFromStore,
        passengerDataFromStore
      }}
    >
      {children}
    </ReviewPaymentContext.Provider>
  );
};

export const useReviewPaymentContext = () => {
  const context = useContext(ReviewPaymentContext);
  if (!context) {
    throw new Error(
      "useReviewPaymentContext must be used within a ReviewPaymentContextProvider"
    );
  }
  return context;
};

export default ReviewPaymentContextProvider;
