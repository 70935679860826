import React, { useEffect, useState } from "react";
import "../../AboutUsPage/style.scss";
import { useMediaQuery } from "react-responsive";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation, A11y } from 'swiper/modules';
import Markdown from 'react-markdown'
;
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";

const NueGoTrivia = () => {

  const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
  const [TriviasData, setTriviasData] = useState([])

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  let pendingObject = { value: true, Response: "pending" }
  let sucessObject = { value: false, Response: "success" }
  let errorObject = { value: false, Response: "error" }




  useEffect(() => {

    setLoadingSpiner(pendingObject)

    fetch(`${process.env.REACT_APP_CMS_PROD_API}api/trivias`,
      {
        headers: {
          'Content-Type': 'application/json',
        },

      })
      .then((res) => res.json())

      .then(
        (res) => {
          if (res?.error) {
            setLoadingSpiner(errorObject)

          } else {
            setLoadingSpiner(sucessObject)
            setTriviasData(res?.data)
          }

          return res;

        })

      .catch((err) => {
        setLoadingSpiner(errorObject)
        return err;

      });

  }, [])




  return (
    <>
      {LoadingSpiner?.value == true ? <Loader /> : <div
        className={
          isDesktopOrLaptop
            ? "nuego-trivia-part d-flex justify-content-center pt-5"
            : "nuego-trivia-part px-3  pt-4"
        }
      >
        <p className={" text-nowrap trivia-title"}>NueGo Trivia</p>

        {LoadingSpiner?.Response != "error" ?
          <div className="nuego-trivia-content">
            {/* <p>
          Integrate and create powerful connected platforms/ecosystem across
          products and services segments in the value chain to create and offer
          a
        </p>
        <p className="nuego-sub-head">
          robust e-mobility infrastructure capability in the B2G, B2C and B2B
          domains. NueGo wants to be the prime mover in building a sustainable
          growth momentum in the shared e-mobility space.
        </p> */}

            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
              }}
              loop={true}

              modules={[Autoplay,]}
              className="mySwiper">
              {TriviasData?.map((items, id) => {

                return <SwiperSlide>

                  <Markdown
  >
                    {items?.attributes?.triviaText}
                    </Markdown>
                </SwiperSlide>
              })}


            </Swiper>

          </div> :
          <div className="nuego-trivia-content">
            <p>
              Integrate and create powerful connected platforms/ecosystem across
              products and services segments in the value chain to create and offer
              a
              
            </p>
            <p className="nuego-sub-head">
              robust e-mobility infrastructure capability in the B2G, B2C and B2B
              domains. NueGo wants to be the prime mover in building a sustainable
              growth momentum in the shared e-mobility space.
            </p>



          </div>
        }

      </div>}
    </>
  );
};

export default NueGoTrivia;
