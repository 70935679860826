import React from 'react'
import "./PopularSearches.scss"
import Group from "../../../../assets/revamp/popular-routes.png";
import { Link , useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {setSourceAndDestination} from "../../../../store/Actions/BookingAction"
import { convertDateWithMonth} from "../../../../utils/utils"

function PopularSearches() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const popularRoutes= [
    {
        "id": 1,
        "source": {
            "id": 672,
            "cityName": "Delhi",
            "cmCityId": "2242"
        },
        "destination": {
          "id": 693,
          "cityName": "Chandigarh",
          "cmCityId": "3404"
      }
    },    {
      "id": 2,
      "source": {
          "id": 673,
          "cityName": "Bangalore",
          "cmCityId": "2318"
      },
      "destination": {
        "id": 693,
        "cityName": "Tirupati",
        "cmCityId": "235"
    }
  },    {
    "id": 3,
    "source": {
        "id": 674,
        "cityName": "Delhi",
        "cmCityId": "2242"
    },
    "destination": {
      "id": 694,
      "cityName": "Jaipur",
      "cmCityId": "983"
  }
},    {
  "id": 4,
  "source": {
      "id": 675,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 695,
    "cityName": "Rishikesh",
    "cmCityId": "1932"
}
},    {
  "id": 5,
  "source": {
      "id": 676,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 696,
    "cityName": "Dehradun",
    "cmCityId": "3400"
}
},    {
  "id": 6,
  "source": {
      "id": 677,
      "cityName": "Indore",
      "cmCityId": "2245"
  },
  "destination": {
    "id": 697,
    "cityName": "Bhopal",
    "cmCityId": "1373"
}
},  {
  "id": 7,
  "source": {
      "id": 678,
      "cityName": "Bangalore",
      "cmCityId": "2318"
  },
  "destination": {
    "id": 698,
    "cityName": "Chennai",
    "cmCityId": "1061"
}
},  {
  "id": 8,
  "source": {
      "id": 679,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 699,
    "cityName": "Agra",
    "cmCityId": "1458"
}
},  {
  "id": 9,
  "source": {
      "id": 680,
      "cityName": "Chennai",
      "cmCityId": "1061"
  },
  "destination": {
    "id": 700,
    "cityName": "Tirupati",
    "cmCityId": "235"
}
},  {
  "id": 10,
  "source": {
      "id": 681,
      "cityName": "Chennai",
      "cmCityId": "1061"
  },
  "destination": {
    "id": 701,
    "cityName": "Pondicherry",
    "cmCityId": "5366"
}
}, 
{
  "id": 11,
  "source": {
      "id": 682,
      "cityName": "Hyderabad",
      "cmCityId": "7298"
  },
  "destination": {
    "id": 702,
    "cityName": "Vijayawada",
    "cmCityId": "238"
}
}, 
{
  "id": 12,
  "source": {
      "id": 683,
      "cityName": "Jaipur",
      "cmCityId": "983"
  },
  "destination": {
    "id": 703,
    "cityName": "Agra",
    "cmCityId": "1458"
}
},

    
]

const handleCoachDetails = (item) => {
  dispatch(setSourceAndDestination({
      sourcePoint: item.source.cityName,
      sourceId: item.source.cmCityId,
      destinationPoint: item.destination.cityName,
      destinationId: item.destination.cmCityId,
      passengersCount: 1,
      date: convertDateWithMonth(new Date()),
      rawDate: new Date(),
  }))

 // navigate("/booking")
}

const renderRoutes = () => {
  const columns = [[], [], []];

  popularRoutes.forEach((route, index) => {
    columns[index % 3].push(
      <div key={route.id} className="routeItem">
  {/* Wrap the destination text with Link */}
  <Link
            className="allBusLink available-routes"
            state={route}
            to={`/all-routes/bus/${route?.source.cityName}-to-${route?.destination.cityName}`}
            rel='canonical'
          >
            <p 
            //onClick={()=>handleCoachDetails(route)}
            >{`${route.source.cityName} to ${route.destination.cityName} Bus`}</p>
          </Link>
      </div>
    );
  });

  return columns.map((column, index) => (
    <div key={index} className={`column${index + 1}`}>
      {column}
    </div>
  ));
};

  return (
    <div className='popular-search'>
    <div className='cont'>
        <div className='subcont'>
         <div className='subcont1'>
         <div className='svgicon'>
          <img src={Group} />
         </div>
         <div className='mainbox1'>
          <p>Popular Searches</p>
          <div className='routs1'>
          {renderRoutes()}
          </div>
         </div>
       
         </div>
        </div>

    </div>
    </div>
  )
}

export default PopularSearches;
