import React, { useEffect, useState } from "react";
import "../Careers.scss";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
// icons
import arrow from "../../../assets/icons/white-arrow-right.svg"
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";
//assets
const Search = process.env.REACT_APP_CDN_LINK + `assets/images/news-search.png`

const opportunities = [
    {
        title: "E-Bus Fleet Maintenance Manager",
        location: "Jaipur",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
    {
        title: "Regional Franchisee Managers",
        location: "Pan-India",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
    {
        title: "Manager - Operations",
        location: "Uttar Pradesh",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
    {
        title: "Manager/Senior Manager",
        location: "Mumbai",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
    {
        title: "Manager/Senior Manager (Female Candidate preferred)",
        location: "Mumbai",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
    {
        title: "AGM - Service Process (Female Candidate preferred)",
        location: "Mumbai",
        postedDat: "Posted on 2-Apr",
        type: "Full Time",
    },
];

const Opportunity = ({ setApply }) => {
    const [jobSearch, setJobSearch] = useState("initState");

    const [LoadingSpiner, setLoadingSpiner] = useState({ value: true, Response: "pending" })
    const [LoadingSpinerDepartment , setLoadingSpinerDepartment ] = useState({ value: true, Response: "pending" })
    const [LoadingSpinerLocation, setLoadingSpinerLocation] = useState({ value: true, Response: "pending" })
    const [OpportunityData, setOpportunityData] = useState([])
    const [DepartmentData, setDepartmentData] = useState([])
    const [LocationData, setLocationData] = useState([])
    const [SelectedDepartmentData, setSelectedDepartmentData] = useState("")
    const [SelectedLocationData, setSelectedLocationData] = useState("")
    const [JobRoleSearch, setJobRoleSearch] = useState("")
    // const [FilteredData, setFilteredData] = useState([])

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }


    const applyJobHandler = (value) => {
        setApply(value);
    };




    const OpportunityGetApi = () => {

        setLoadingSpiner(pendingObject)

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/opportunities`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())

            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpiner(errorObject)

                    } else {
                        setLoadingSpiner(sucessObject)
                        setOpportunityData(res?.data)
                    }

                })

            .catch((err) => {
                setLoadingSpiner(errorObject)
                return err;

            });


    }

    const LocationGetApi = () => {
        setLoadingSpinerLocation(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/opportunities?fields[0]=location`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpinerLocation(errorObject)

                    } else {
                        setLoadingSpinerLocation(sucessObject)
                        setLocationData(res?.data)
                    }
                    return res;
                })
            .catch((err) => {
                setLoadingSpinerLocation(errorObject)
                return err;

            });

    }

    const DepartmentGetApi = () => {
        setLoadingSpinerDepartment(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/opportunities?fields[0]=department`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpinerDepartment(errorObject)

                    } else {
                        setLoadingSpinerDepartment(sucessObject)
                        setDepartmentData(res?.data)

                    }
                    return res;

                })

            .catch((err) => {
                setLoadingSpinerDepartment(errorObject)
                return err;

            });
    }


    useEffect(() => {
        OpportunityGetApi()
        LocationGetApi()
        DepartmentGetApi()
    }, [])



    useEffect(() => {
        if (JobRoleSearch || SelectedDepartmentData || SelectedLocationData) {
            fetch(`${process.env.REACT_APP_CMS_PROD_API}api/opportunities?filters[designation][$containsi]=${JobRoleSearch == "null" ? "" : JobRoleSearch}&filters[department][$containsi]=${SelectedDepartmentData == "null" ? "" : SelectedDepartmentData}&filters[location][$containsi]=${SelectedLocationData == "null" ? "" : SelectedLocationData}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },

                })
                .then((res) => res.json())
                .then(
                    (res) => {
                        if (res?.error) {
                            setLoadingSpiner(errorObject)

                        } else {
                            setLoadingSpiner(sucessObject)
                            setOpportunityData(res?.data)

                        }

                        return res;

                    })

                .catch((err) => {
                    setLoadingSpiner(errorObject)
                    return err;

                });
        }

    }, [JobRoleSearch, SelectedDepartmentData, SelectedLocationData] )

    return (<>
        <div className="Nuego-Opportunity pb-5" id={"job-application"}>
            <div className="head">Opportunities with NueGo</div>
            {LoadingSpinerLocation?.value == true ? <Loader />
                : 
                DepartmentData.length>0 && LocationData.length>0 &&
                <div className="box-flex mt-3 mb-5">
                    <div className="box mt-3">
                        <label for="location">Location</label>
                        {LoadingSpinerLocation?.Response != "error" ?
                            <select className="select-location" name="location" onChange={(e) => setSelectedLocationData(e.target.value)}>
                                <option className="option" value="null">
                                    Select Location
                                </option>
                                {LocationData?.map((items, id) => {
                                    return <option className="option" value={items?.attributes?.location}>
                                        {items?.attributes?.location}
                                    </option>

                                })}
                            </select>
                            :
                            <select className="select-location" name="location">
                                <option className="option" value="1">
                                    Select Location
                                </option>
                            </select>}
                    </div>
                    <div className="box mt-3">
                        <label for="department">Department</label>
                        {LoadingSpinerDepartment?.Response != "error" ? <select name="department" onChange={(e) => setSelectedDepartmentData(e.target.value)}>
                            <option className="option" value="null">
                                Select Department
                            </option>
                            {DepartmentData?.map((items, id) => {
                                return <option className="option" value={items?.attributes?.department}>
                                    {items?.attributes?.department}
                                </option>
                            })}
                        </select>
                            :
                            <select name="department">
                                <option className="option" value="1">
                                    Select Department
                                </option>
                            </select>

                        }
                    </div>
                    <div>
                        <label className="mt-3" for="department">
                            Search Job Role
                        </label>

                        {LoadingSpiner?.Response != "error" ? <div className="box3 align-items-center">
                            <input type="text" placeholder="Enter Job Role" className="sub"
                                onChange={(e) => setJobRoleSearch(e.target.value)} />
                            <img src={Search} alt={"alt"} className={"Search icon-16"} />
                        </div>
                            :
                            <div className="box3 align-items-center">
                                <input type="text" placeholder="Enter Job Role" className="sub" />
                                <img src={Search} alt={"alt"} className={"Search icon-16"} />
                            </div>

                        }
                    </div>
                </div>}

            {LoadingSpiner?.value == true ? <Loader /> : LoadingSpiner?.Response != "error"  && OpportunityData.length ?
                <div className={"opportunities-grid"}>
                    {OpportunityData?.map((opportunity, index) => {
                        return (
                            <OppertunityCardComponent
                                key={index}
                                item={opportunity}
                                applyJobHandler={(args) => applyJobHandler(args)}
                                LoadingSpiner={LoadingSpiner}
                            />
                        );
                    })}

                </div>
                :
                <div className={"no-opp-section text-center"}>

                    <div className="sub-parent">
                        <img src="https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/no-oppor-img.png" alt="" />
                        <br />
                        <h3 className="no-opp-sub-head">Thank you for your interest in us!</h3>
                        <br />
                        <p className="text-center open-400w-16s-24h black-1E1E26-color mb-0">We appreciate your enthusiasm to become a part of NueGo. And regret to inform you that we currently do not have any job openings.</p>
                        <br />
                        <p className="text-center open-400w-16s-24h black-1E1E26-color mb-0">However, we are continuously seeking talented and enthusiastic individuals to join our team. So, you can always mail us your Resume for future consideration against suitable opportunities.</p>
                        <br />
                       <a href="mailto: careers@greencellmobility.com"><button className="submit-button-no-opp">Write to Us</button> </a> 
                    </div>
                </div>

            }

        </div>
    </>
    );
};

export const OppertunityCardComponent = ({ item, applyJobHandler, LoadingSpiner }) => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });

    let componentContant;
    if (isDesktopOrLaptop) {
        componentContant = {
            jobPosition_class: "ubuntu-700w-20s-32h black-1E1E26-color mb-2",
            location_class: "open-400w-16s-22h grey-3-adadad-color mb-4",
            jobType_class:
                "open-700w-14s-22h black-1E1E26-color mb-2 yellow-pill whitespace-nowrap",
            posted_class: " open-600w-14s-22h grey-3-adadad-color ",
            submitButton_class:
                "open-600w-18s-28h white-color  submit-button whitespace-nowrap " +
                "d-flex justify-content-between align-items-center",
        };
    } else {
        componentContant = {
            jobPosition_class: "open-700w-16s-24h black-1E1E26-color mb-2",
            location_class: "open-400w-14s-22h grey-3-adadad-color mb-4",
            jobType_class:
                "open-700w-14s-22h black-1E1E26-color mb-2 yellow-pill whitespace-nowrap",
            posted_class: " open-600w-14s-22h grey-3-adadad-color ",
            submitButton_class:
                "open-600w-16s-24h white-color submit-button whitespace-nowrap " +
                "d-flex justify-content-between align-items-center",
        };
    }

    return (
        <>

            {
                LoadingSpiner?.Response != "error" ?
                    <div
                        className={
                            "opportunity-card-component d-flex flex-column justify-content-between"
                        }
                    >
                        <div className={"d-flex justify-content-between align-items-start "}>
                            <div className={"content-card"}>
                                <p className={componentContant.jobPosition_class}>{item?.attributes?.designation}</p>
                                <p className={componentContant.location_class}>{item?.attributes?.location}</p>
                            </div>
                            <div className={componentContant.jobType_class}>{item?.attributes?.type}</div>
                        </div>
                        <div className={"d-flex justify-content-between align-item?attributes?s-baseline"}>
                            <div className={componentContant.posted_class}>Posted {item?.attributes?.publishedAt}</div>
                            <Link
                                activeClass="active"
                                to={"job-application"}
                                spy={true}
                                smooth={true}
                                offset={600}
                                duration={500}
                            >
                                <button
                                    className={componentContant.submitButton_class}
                                    onClick={() => applyJobHandler(item?.attributes?.designation)}
                                >
                                    <p className={"mb-0 white-color applyBtn"}>Apply</p>
                                    <img src={arrow} alt={"arrow"} className={"arrow"} />
                                </button>
                            </Link>
                        </div>
                    </div>
                    : <div
                        className={
                            "opportunity-card-component d-flex flex-column justify-content-between"
                        }
                    >
                        <div className={"d-flex justify-content-between align-items-start "}>
                            <div className={"content-card"}>
                                <p className={componentContant.jobPosition_class}>{item.title}</p>
                                <p className={componentContant.location_class}>{item.location}</p>
                            </div>
                            <div className={componentContant.jobType_class}>{item.type}</div>
                        </div>
                        <div className={"d-flex justify-content-between align-items-baseline"}>
                            <div className={componentContant.posted_class}>{item.postedDat}</div>
                            <Link
                                activeClass="active"
                                to={"job-application"}
                                spy={true}
                                smooth={true}
                                offset={600}
                                duration={500}
                            >
                                <button
                                    className={componentContant.submitButton_class}
                                    onClick={() => applyJobHandler(item.title)}
                                >
                                    <p className={"mb-0 white-color applyBtn"}>Apply</p>
                                    <img src={arrow} alt={"arrow"} className={"arrow"} />
                                </button>
                            </Link>
                        </div>
                    </div>}
        </>

    );
};

export default Opportunity;
