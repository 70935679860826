import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router";
import { postBookingData } from "../../services/api/DataFetching";
import { store } from "../../index";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {
  postBookingDataAction,
  setRescheduleAction,
  setSelectedBookingIdAction,
} from "../../store/Actions/CommonAction";
import { CustomToast } from "../../components/CustomToast/CustomToast";
import cogoToast from "cogo-toast";
import { setAuthType } from "../../store/Actions/AuthActions";
import { connect } from "react-redux";

const LoadingAnimation =
  process.env.REACT_APP_CDN_LINK + `assets/images/Loading_Amination-white.gif`;

const PaymentInProcess = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  let location = useLocation();
  const navigate = useNavigate();
  let pageConstants;
  let localStorageToken = localStorage.getItem("userToken");
  let paramsId = location.search !== "" ? location.search.substring(1) : "";

  const countRef = useRef(0);

  let isToken;
  if (store) {
    isToken =
      (localStorageToken !== undefined && localStorageToken?.length > 0) ||
      (store &&
        store.getState().AuthReducer?.tokenData &&
        Object.keys(store.getState().AuthReducer?.tokenData)?.length > 0);
  }

  const [showRedirectButton, setshowRedirectButton] = useState(false);
  const [bookingInterval, setBookingInterval] = useState(null);

  const {
    postBookingDataFromStore,
    rescheduleFromStore,
    setPostBookingDataToStore,
  } = props;

  if (isDesktopOrLaptop) {
    pageConstants = {
      pageTitle_text: "ubuntu-700w-32s-48h",
      description_text: "ubuntu-700w-24s-32h",
    };
  } else {
    pageConstants = {
      pageTitle_text: "open-700w-24s-36h",
      description_text: "ubuntu-700w-14s-22h",
    };
  }

  const postBookingDataFetchInterval = () => {
    // Assign the interval ID to the variable
    // debugger
    if (countRef.current > 2 && bookingInterval) {
      clearInterval(bookingInterval);
      setBookingInterval(null); // Reset the interval state
    }
    else {
      setBookingInterval(
        setInterval(() => {
          countRef.current < 3 && fetchPostBookingData();
        }, 10000)
      );
    }
  };

  const fetchPostBookingData = () => {
    let variable = {
      bookingId: paramsId,
    };
    countRef.current = countRef.current + 1;
    postBookingData(variable, (response) => {
      //const res = null; // mock data to test false case
       const res = response;
      setPostBookingDataToStore(res);
      if (res && Object.keys(res)?.length !== 0) {
        navigate("/confirmation", { replace: false });
      } else {
        if (countRef.current < 3) {
          postBookingDataFetchInterval();
        } else {
          clearInterval(bookingInterval);
          setshowRedirectButton(true);
          setTimeout(() => {
            navigate("/my-booking", { replace: false });
          }, 5000);
        }
      }
      store.dispatch(setRescheduleAction(false));
      store.dispatch(setAuthType(""));
    });
  };

  useEffect(() => {
    if (paramsId !== "") {
      if (isToken) {
        store.dispatch(setSelectedBookingIdAction(paramsId));
        store.dispatch(postBookingDataAction({}));
        fetchPostBookingData();
      } else {
        navigate("/", { replace: false });
        cogoToast.error(
          <CustomToast
            type={"error"}
            message={"You don't have permisssion to view"}
            heading={"Error"}
          />,
          { position: "top-center" }
        );
      }
    } else if (!isToken) {
      navigate("/", { replace: false });
      cogoToast.error(
        <CustomToast
          type={"error"}
          message={"You don't have permisssion to view"}
          heading={"Error"}
        />,
        { position: "top-center" }
      );
    }
    //     else {
    //   navigate("/", { replace: false });
    //   cogoToast.error(
    //     <CustomToast
    //       type={"error"}
    //       message={
    //         "Something went wrong, Please verify your booking on My Bookings page"
    //       }
    //       heading={"Error"}
    //     />,
    //     { position: "top-center" }
    //   );
    // }
  }, [paramsId]);

  return (
    <AuthModalLayout>
      <div className={"fnb black-1E1E26-bg"}>
        <div className={"navbar-wrapper margin-bottom-100"}>
          <Navbar />
        </div>

        <div className={"white-fold white-fold-padding"}>
          <div className={"inner-div text-center"}>
            <p className={"mb-5 " + pageConstants.pageTitle_text}>
              Processing your payment..
            </p>
            <div
              className={
                "d-flex justify-content-center text-center align-items-center"
              }
            >
              <div>
                <img
                  src={LoadingAnimation}
                  alt="NueGo Mealbox"
                  className={"fnb-illustration"}
                />
                <p className={"" + pageConstants.description_text}>
                  We're securely processing your payment. This may take a few
                  seconds.
                </p>
                {showRedirectButton && (
                  <>
                    <p className={"ubuntu-400w-14s-20h"}>
                      Something went wrong, Redirecting you to My Booking page
                      in 5 seconds...
                    </p>
                    <Link to="/my-booking">
                      <button className={"submit-button-fnb"}>
                        Go To Booking
                      </button>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </AuthModalLayout>
  );
};

const mapStateToProps = (state) => ({
  postBookingDataFromStore: state.CommonReducer.postBookingData,
  loginDetailsFromStore: state.AuthReducer.tokenData,
  rescheduleFromStore: state.CommonReducer.reschedule,
});

const mapDispatchToProps = (dispatch) => ({
  setPostBookingDataToStore: (data) => dispatch(postBookingDataAction(data)),
  //   setReschedule: (data) => dispatch(setRescheduleAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInProcess);
