import React from 'react'
import './AnyPageFAQ.scss'

function AnyPageFAQ({heading,faqArray}) {
    return (
        <div className='AnyPagefaq'>
                <h2 className='title'>{heading}</h2>
            <div class="accordion" id="accordionExample">
            {
  faqArray.map((item,index)=>{
                return<div class="accordion-item">
                    <h2 class="accordion-header" id={`heading${index}`}>
                        <button class="accordion-button collapsed"  type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="false" aria-controls={`collapse${index}`}>
                            {item.question}
                        </button>
                    </h2>
                    <div id={`collapse${index}`} class="accordion-collapse collapse " aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                        <div class="accordion-body"  dangerouslySetInnerHTML={{__html : item.answer}}>
                        </div>
                    </div>
                </div>
                  })
} 
            </div>
        </div>
    )
}

export default AnyPageFAQ