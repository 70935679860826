import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from '../../environment'


const mutation = graphql`
	mutation ApplyInsuranceMutation(
		$bookingId: ID!,
        $isInsuranceApplied : Boolean!
		){
        applyInsurance(
			bookingId:$bookingId,
            isInsuranceApplied:$isInsuranceApplied
		){
		status
		message
		booking{
			totalPayable
			getTotalPaidAmount
		}
		}
	}

`;

export default (variables, callback, errCallback) => {
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.applyInsurance !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			},
			onError: err => {
				errCallback(err);
			},
		},
	)
}