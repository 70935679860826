import React, { useEffect, useState } from "react";
import "../../AboutUsPage/style.scss";
import { useMediaQuery } from "react-responsive";
import Markdown from 'react-markdown'
;
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";

//assets
const mobileWelcomeImage = process.env.REACT_APP_CDN_LINK + `assets/images/mobileAbout.png`
const desktopWelcomeImage = process.env.REACT_APP_CDN_LINK + `assets/images/desktopAbout.png`

const Welcome = () => {
    const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
    const [OurStoryData, setOurStoryData] = useState('')

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }




    useEffect(() => {

        setLoadingSpiner(pendingObject)

        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/our-story?populate=*`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },

            })
            .then((res) => res.json())

            .then(
                (res) => {
                    if (res?.error) {
                        setLoadingSpiner(errorObject)

                    } else {
                        setLoadingSpiner(sucessObject)
                        setOurStoryData(res?.data)
                    }

                    return res;

                })

            .catch((err) => {
                setLoadingSpiner(errorObject)
                return err;

            });

    }, [])



    return (
        <>
            {LoadingSpiner?.value == true ? <Loader /> : <div
                className={
                    isDesktopOrLaptop
                        ? "welcome d-flex align-items-center justify-content-center py-5"
                        : "welcome px-3 pt-4 pb-5"
                }
            >
                <div>
                    {!isDesktopOrLaptop && (
                        <p className="mobile-title">Welcome aboard NueGo</p>
                    )}
                    {isDesktopOrLaptop &&  (
                       LoadingSpiner?.Response != "error" ? <img
                            src={OurStoryData?.attributes?.image}
                            alt="alt"
                            className={"mx-auto img-responsive rounded "}
                        ></img>
                        :<img
                        src={desktopWelcomeImage}
                        alt="alt"
                        className={"mx-auto img-responsive rounded "}
                    ></img>
                    )}

                    {!isDesktopOrLaptop && (
                      LoadingSpiner?.Response != "error" ?  <img
                            src={OurStoryData?.attributes?.image}
                            className="mx-auto d-block img-fluid mobile-image mb-4"
                            alt="alt"
                        ></img>
                        : <img
                        src={mobileWelcomeImage}
                        className="mx-auto d-block img-fluid mobile-image mb-4"
                        alt="alt"
                    ></img>
                    )}
                </div>
                <div>
                    {isDesktopOrLaptop && (
                        <p className="desktop-title text-nowrap">Welcome aboard NueGo</p>
                    )}
                    {LoadingSpiner?.Response != "error" ? <div className="welcome-data">
                        <Markdown
  >
                            {OurStoryData?.attributes?.description}
                        </Markdown>

                    </div>
                        : <div className="welcome-data">
                            <p>
                                We’ve set out on a journey. This journey is a little different from
                                the ones we usually take. It’s the kind you take once in your life,
                                and stay on for life. A journey of changing the world for the
                                better. A journey from the travel experience we’ve all come to
                                expect, to an elevated one that we know we deserve.
                            </p>
                            <p>
                                We are India’s first intercity electric coach service. Why electric?
                                Because it’s more efficient, quieter, and cleaner. It’s just better,
                                simple as that. However, our electric coaches is just the start.
                                That’s because what we do is more than just ‘operate buses’. We are
                                finding ways of making the entire travel experience better. Not in
                                small measures, but by leaps and bounds.
                            </p>
                            <p className="welcome-data-dark-content">
                                We’re bringing a better booking experience, luxurious lounges, guest
                                assist programmes, a superior in-cabin experience, international
                                standard of safety and service; as well as technology-driven
                                value-added services like geo-location sharing through our
                                state-of-the-art app.
                            </p>
                        </div>

                    }
                </div>
            </div>}
        </>
    );
};

export default Welcome;
