import graphql from "babel-plugin-relay/macro";

const GetAvailableBusListingQuery = graphql`
    query GetAvailableBusListingQuery($sourceId: Int!, $destinationId: Int!, $date: String!) {
        getAvailableBusListing(sourceId: $sourceId, destinationId: $destinationId, date: $date) {
            ID
            RouteID
            TripNo
            ArrangementID
            RouteScheduleCode
            BookingDate
            BusType

            FromCityId
            FromCityName

            ToCityId
            ToCityName

            RouteTime

            Kilometer

            CityTime
            ArrivalTime

            DurationMinutes

            EmptySeats

            HoldTime
            Duration

            BusSeatType

            PackageAmenities

            RouteAmenities{
                id
                title
            }

            BoardingPoints
            DroppingPoints

            ReferenceNumber

            AcSeatRate
            NonAcSeatRate

            DisCountType
            DiscountRate

            IsSameDay
            RouteTimeID
            getPrice
            originalPrice

            CarbonEmission
            GreenMiles
        }
    }
`

export default GetAvailableBusListingQuery