export const data = [
    {
       title: "10 Famous Waterfalls in India that You Cannot Miss in 2024",
        img:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/10%20Famous%20Waterfalls%20in%20India%20You%20Must%20See%20in%202024_Desktop_587%20X%20763%20px.jpg',
        mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_mWeb_155%20X%20157%20px.jpg',
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Waterfalls are magnificent performances by nature that mesmerize most people who watch them in awe. India is home to several beautiful waterfalls and is by many accounts home to some of the most mysterious and secret waterfalls found in forests that have legends behind them.",
        route: "/blog/famous-waterfall-in-india",
        category: ["Adventure",'Weekend Trips', 'Hidden Gems'],
        section: "recommended"
    },
    {
       title: "10 Best Places to Visit in India During August for Monsoon Adventure in 2024",
        mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Best%20Places%20to%20Visit%20in%20India%20During%20August%20for%20Monsoon%20Adventu/10%20Best%20Places%20to%20Visit%20in%20India%20During%20August%20for%20Monsoon%20Adventure%20in%202024_557%20X%2099%20px.jpg', 
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Best%20Places%20to%20Visit%20in%20India%20During%20August%20for%20Monsoon%20Adventu/10%20Best%20Places%20to%20Visit%20in%20India%20During%20August%20for%20Monsoon%20Adventure%20in%202024_557%20X%2099%20px.jpg",
        imgAlt: "10 Best Places to Visit in India During August for Monsoon Adventure in 2024",
        desc: "Monsoon season is pleasant, and if you have holidays or vacations in August, a travel plan is an ideal course of action that is guaranteed to make you chill in the thrill.",
        route: "/blog/best-august-travel-destinations-in-india-monsoon",
        category: ['Adventure', 'Weekend Trips', 'Hidden Gems'],
        section: "top"
    },
    {
       title: "7 Best Places to Visit Near Indore in 2024",
        mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_mWeb_155%20X%20157%20px.jpg', 
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/7%20Best%20Places%20to%20Visit%20Near%20Indore%20in%202024%20(1)/7%20Best%20Places%20to%20Visit%20Near%20Indore%20in%202024_557%20X%20310%20px.jpg",
        imgAlt: "7 Best Places to Visit Near Indore in 2024",
        desc: "Indore is one of the fastest-growing cities in India and is quite popular as a tourist spot in its own right. Situated in Madhya Pradesh, it is a beautiful city with a lot of facilities that are on par with metro cities. A trip to Indore will certainly make your life more fun.",
        route: "/blog/places-to-visit-near-indore",
        category: ["Hidden Gems",'Heritage', 'Adventure'],
        section: "recommended"
    },
    {
       title: "Best Places to Visit for One-Day Trips from Bangalore",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/One-Day%20Trips%20from%20Bangalore.jpg",
        mwebimg:"",
        imgAlt: "one day tour from Bangalore by Nuego",
        desc: "Bangalore! The galore for all things fun. The weather is perpetually pleasant, there is an entire city with facilities second to very few, and countryside and villages are awaiting you just outside the city if you are tired of honks and noises. Bangalore is one of India's most developed cities while maintaining a fair balance of natural habitat surrounding nearby rural areas.",
        route: "/blog/one-day-trip-from-bangalore",
        category: ["Weekend Trips",	'Adventure', 'Hidden Gems'],
        section: "top"
        
    },
    {
       title: "20 Travel Destinations in North India",
        mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/20%20Hidden%20North%20India%20Travel%20Destinations%20to%20Explore%20in%202024/20%20Hidden%20North%20India%20Travel%20Destinations%20to%20Explore%20in%202024_557%20X%2099%20px.jpg', 
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/20%20Hidden%20North%20India%20Travel%20Destinations%20to%20Explore%20in%202024/20%20Hidden%20North%20India%20Travel%20Destinations%20to%20Explore%20in%202024_557%20X%2099%20px.jpg",
        imgAlt: "20 Travel Destinations in North India",
        desc: "North India has so many travel destinations to explore. Be it Delhi, Agra, Fatehpur Sikri, Shimla, or Jaipur, there has never been a shortage of places to visit in North India.",
        route: "/blog/travel-destinations-to-explore-in-north-india",
        category: ['Hidden Gems', 'Adventure', 'Weekend Trips'],
        section: "recommended"
    },
 
    {
       title: "Don't Skip Out on These Incredible South Indian Tourist Places to Visit",
        mwebimg:'',
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Don't%20Skip%20Out%20on%20These%20Incredible%20South%20Indian%20Tourist%20Places%20to%20Visit.jpg",
        imgAlt: "Best Places to Visit in South India",
        desc: "In this beautiful land known for so many beautiful things, it is no surprise that the beauty of South India is something that cannot be expressed in mere words, let alone using blog posts to describe these places. However, if you want to know which place to visit, what to do in South India, and how to make the best of your trip by visiting theSouth India tourist placesthat will blow your mind, here we are with a list for exactly that.",
        route: "/blog/best-south-indian-tourist-places-to-visit",
        category: ["Hidden Gems",'Adventure', 'Weekend Trips'],
        section: "Must-Visits"
    },
    {
       title: "10 Things To Do in Pondicherry in 2024 That You Won't Find in Guidebooks",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Things%20To%20Do%20in%20Pondicherry%20in%202024%20That%20You%20Won't%20Find%20in%20Guidebooks.jpg",
        mwebimg:'',
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "If India were an ice cream, Pondicherry would be the cherry on top. Not just because the name has “cherry” in it, but also because despite how small Pondicherry is, it will always pack a punch that leaves every tourist spellbound after visiting this very interesting treasure trove of cultural diversity.",
        route: "/blog/things-to-do-in-pondicherry",
        category: ["Hidden Gems", 'Adventure', 'Weekend Trips'],
        section: "recommended"
    },

    {
       title: "20 Places To Visit in India During Monsoon",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/20_Mansoon_images/mansoon_hero_image.png",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "All umbrellas are anxious during the monsoon, because they are under a lot of pressure to perform. But for humans, the monsoon is a boon. The sheer amount of joy you would get from dancing in the rain, eating pakoras in a cozy room as it rains, or even seeing the onset of rain with dark clouds changing the brightness of your room is nothing short of legendary.",
        route: "/blog/monsoon-tourist-places-in-india",
        category: ["Adventure",'Hidden Gems','Weekend Trips'],
        section: "recommended"
    },
    {
        title: "30 Best Things to Do in Gurugram | Latest List",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/30%20Best%20Things%20to%20Do%20in%20Gurgaon/30%20Best%20Things%20to%20Do%20in%20Gurgaon_Latest%20List_557%20X%2099%20px.jpg",
        mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_mWeb_155%20X%20157%20px.jpg',
        imgAlt: "30 Best Things to Do in Gurugram | Latest List",
        desc: "Gurgaon was officially named Gurugram to capture its Indian roots. It is one of the most well-planned cities in India. Situated in Haryana state, one of India's Northern states, Gurgaon is a part of India's National Capital Region (NCR).",
        route: "/blog/things-to-do-in-gurugram",
        category: ["Weekend Trips", 'Adventure', 'Hidden Gems'],
        section: "recommended"
    },
    {
       title: "Weekend Getaways from Bangalore: Short Getaway Places Near Bangalore in 2024",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Banglore.jpg",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Bangalore is a beautiful place and living there is a privilege despite what anyone will tell you about a bustling city being overcrowded. Bangalore has facilities that not many other places in India can boast of. However, we wouldn’t hold it against you if you wanted to step out of Bangalore on the weekend.",
        route: "/blog/weekend-getaways-from-bangalore",
        category: ["Weekend Trips",'Adventure','Hidden Gems'],
        section: "recommended"
    },
    {
       title: "10 Places to Visit in India in August To Enjoy Rainy Weather 2024",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/placestovisitAugust.png",
        imgAlt: "Best Places To Visit This Summer Vacation In India",
        desc: "A gust of wind, August of winds. No doubt, as monsoon ensures a windy August in India every year, a gust of wind is very likely to be encountered. The best places to visit in August are likely to give you at least some satisfaction of having rain danced.",
        route: "/blog/best-places-to-visit-in-august",
        category: [ "Adventure", 'Hidden Gems', 'Weekend Trips' ],
        section: "recommended"
    },

    {
       title: "Complete Guide To Celebrate Holi In Mathura-Vrindavan",
        img: "https://nuego1.blr1.cdn.digitaloceanspaces.com/web2/blog/holi-in-mathura-vrindavan.jpg",
        imgAlt: "Guide To Celebrate Holi In Mathura-Vrindavan | NueGo",
        desc: "Have you ever wondered where colours blend with culture, creating a spectacle unlike any other? Welcome to the vibrant celebration of Holi in Mathura-Vrindavan, where traditions paint the town in hues of joy and unity.",
        route: "/blog/guide-to-celebrate-holi-in-mathura-vrindavan",
        category: ["Religious"],
        section: "top"
    },
    {
       title: "15 Famous Places to Visit in Vijayawada in 2024 for Sightseeing and Tourist Spots",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/15%20Famous%20Places%20to%20Visit%20in%20Vijayawada%20in%202024%20for%20Sightseeing%20and%20Tourist%20Spots.jpg",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Vijayawada, known as the 'City of Victory', celebrates the triumph of Goddess Durga over Mahishasura, offering a cultural tapestry of festivals and temples steeped in mythological significance. Explore its vibrant heritage through majestic temples and annual festivities honoring the divine intervention in ancient legends.",
        route: "/blog/famous-places-to-visit-in-vijayawada",
        category: ["Heritage",'Hidden Gems','Adventure'],
        section: "Must-Visits"
    },
    {
       title: "15 Best Sightseeing Places to Visit in Haridwar",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Haridwar_15%20Best%20Sightseeing.jpg",
        imgAlt: "Har Ki Pauri: One of the most popular attractions in Haridwar.",
        desc: "Haridwar is cool. Both literally and figuratively. But the pleasant weather and hilly bliss aside, Haridwar is a lot cooler for what it has - a plethora of charming pilgrimage sites awaiting its devotees every year. Theplaces to visit in Haridwarare countless, and the temples and ashrams in Haridwar are all a spectacular kaleidoscope of devotion and grace, waiting to be experienced by a passionate tourist such as yourself.",
        route: "/blog/sightseeing-places-to-visit-in-haridwar",
        category: ["Religious",'Heritage', 'Hidden Gems'],
        section: "History"
    },
    {
       title: "10 Famous Tourist Places and Attractions in Agra You Cannot Miss",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/10%20Famous%20in%20Agra.jpg",
        imgAlt: "Taj Mahal: One of the Famous Places to Visit in Agra",
        desc: "Agra! There’s something about that place, a serene and everlasting charm filled with love and romance. Thefamous places in Agra, all exhibit beauty that even pages from history cannot narrate with words.",
        route: "/blog/famous-tourist-places-to-visit-in-agra",
        category: ["Heritage",'Religious', 'Hidden Gems'],
        section: "History"
    },
    {
       title: "Your Complete Guide to Places to Visit in Ujjain in 2024",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Your%20Complete%20Guide%20to%20Places%20to%20Visit%20in%20Ujjain%20in%202024.jpg",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Immense is the attraction of power, and many people spend their lifetimes trying to attain it. Yet, one city has always tested the powerful, and even ripped it off of them, should they be unworthy. We speak, of course, of the ancient cityAvantika, which we all call Ujjain today. One of the four locations of the Kumbh Mela, held every 12 years, the new name Ujjain may have changed how people see the city, but testing the powerful remains an occurrence even to this day.",
        route: "/blog/places-to-visit-in-ujjain",
        category: ["Religious",'Hidden Gems', 'Heritage'],
        section: "History"
    },
 
    {
       title: "25 Best Places To Visit This Summer Vacation In India",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/summerBlogImage.png",
        imgAlt: "Best Places To Visit This Summer Vacation In India",
        desc: "We’re set to take you on the perfect getaway this summer. India offers a treasure trove of destinations that promise vibrant experiences and relief from the sweltering heat. From breezy hill stations in the north to the serene beaches down south, there's no shortage of memorable places to visit this summer.",
        route: "/blog/places-to-visit-in-summer",
        category: ["Adventure"],
        section: "top"
    },

    {
       title: "March Long Weekend 2024: 7 Places You Must Visit",
        img: "https://nuego1.blr1.cdn.digitaloceanspaces.com/web2/blog/marchlongweekend2024.jpg",
        imgAlt: "March Long Weekend 2024: 7 Places You Must Visit | NueGo",
        desc: "March is here, and with it, the promise of March long weekend 2024. Wondering how to make the most of these golden opportunities? Whether you're a working professional craving a break or simply eager for a getaway, we've got you covered.",
        route: "/blog/march-long-weekend-trip-ideas",
        category: ["Weekend Trips"],
        section: "top"
    },
    {
       title: "Top 20 Solo Travel Destinations For Women | NueGo",
        img: "https://nuego-cms.blr1.cdn.digitaloceanspaces.com/whatsapp-creatives/soloFemaleTravellers.png",
        imgAlt: "Best Places to Visit with Your Friends",
        desc: "Are you craving a solo adventure, but hesitant to navigate the world alone? Solo travel for women offers unparalleled independence and self-discovery, yet safety considerations can emerge. Here, we ease those anxieties. This curated list unveils the best travel destinations for solo female travellers.",
        route: "/blog/solo-travel-destinations-for-women",
        category: ["Adventure"],
        section: "top"
    },
    {
       title: "Friendship Day Getaways: 30 Best Places to Visit with Your Friends",
        img: "https://nuego-cms.blr1.cdn.digitaloceanspaces.com/whatsapp-creatives/BestPlacesToVisitPlaces.png",
        imgAlt: "Best Places to Visit with Your Friends",
        desc: "Ever feel like life's daily grind is putting a damper on your friendships? We've all been there. But what if there was a cure - a chance to reconnect and create memories that'll last a lifetime? Yes, we’re hinting towards an epic trip with your best buds!",
        route: "/blog/travel-places-to-visit-with-friends",
        category: ["Weekend Trips"],
        section: "top"
    },
    {
       title: "10 Famous Temples in Chennai You Just Cannot Miss",
        mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_mWeb_155%20X%20157%20px.jpg', 
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_587%20X%20286%20px.jpg",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Chennai! The oldest metropolitan city of South India, where one of the oldest languages in the world is spoken. It is no surprise that so many ancient, medieval, and modern temples exist in Chennai, which attract a lot of devotees from around the world throughout the year.",
        route: "/blog/famous-temples-to-visit-in-chennai-you-must-visit",
        category: ["Religious", 'Heritage', 'Hidden Gems'],
        section: "History"
    },
    {
       title: "20 Best Tourist Attractions and Activities in Noida You Must Try",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_587%20X%20286%20px.jpg",
        mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_mWeb_155%20X%20157%20px.jpg',
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Noida. Wow is not just the expression you exclaim here, but also the name of one of the places in Noida. Such is the beauty of advancement and style in Noida that anyone who decides to visit here is bound to be flabbergasted at how uniquely blended and well-planned Noida is after seeing the Noida tourist attractions.",
        route: "/blog/top-tourist-attractions-and-activities-in-noida",
        category: ["Adventure", 'Weekend Trips', 'Hidden Gems'],
        section: "Must-Visits"
    },
    {
       title: "Best Places to Eat in Jaipur—Recommended by Locals!",
        mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_mWeb_155%20X%20157%20px.jpg', 
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Best%20Food%20Places%20in%20Jaipur_587%20X%20286%20px.jpg",
        imgAlt: "Best Places To Visit This Monsoon In India",
        desc: "Food and Jaipur have both been used in the same sentence since times immemorial. In the very place where kings and queens have dined since one could remember, it wouldn’t come as a surprise either. The locals of Jaipur have therefore developed an inner food critic within themselves which has tuned them to demand the finest quality of food wherever they go.",
        route: "/blog/best-food-places-in-jaipur",
        category: ["Weekend Trips", 'Heritage', 'Hidden Gems'],
        section: "Must-Visits"
    },
   
   
   
]


