import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import {  useSearchParams,useParams, Link , useLocation } from "react-router-dom"
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../components/Loader/Loader";
import { CustomToast } from "../../components/CustomToast/CustomToast"
import nuegoLogo from '../../assets/icons/nuegoLogoAnnouncement.svg'
// icons
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import "./SpecificNewsPage.scss"

function SpecificNewsPage() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const routeData = useLocation();
   


    return (
        <div className={"specific-news-page"}>
            <Helmet>
                <title>News | Nuego</title>
                <link rel="canonical" href="" hreflang="en-IN" />
                <meta name="description" content=""/>
                <meta name="keywords" content=""></meta>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}  routeData={routeData}  />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo News"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} routeData={routeData}  />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = (param) => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);
    const [mainNews , setMainNews] = useState([]);
    const [allNews , setAllNews] = useState([]);
    const navigate = useNavigate();


    const {id} = useParams();

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }


    const ApiCallFun = (Item_id) => {
        setLoading(true);
        fetch(
          `${process.env.REACT_APP_CMS_PROD_API}api/plan-trips/${Item_id}?populate=deep`
        )
          .then((res) => res.json())
          .then((res) => {
            if (res?.error) {
                setLoading(false);
                
            } else {
                setLoading(false);
                
                setAllNews(res?.data);
                
            }

            // if(res?.data === 'null') alert("no data found")
            
          })
          .catch((err) => {
            setLoading(false);
          });

          
      };
    //   useEffect(() => {
    //     ApiCallFun();
    //   }, []);
    


    useEffect(()=>{
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-news/${id}?populate=deep`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.json())
        .then((res) => {
            if(!res.data) {
                cogoToast.warn(
                    <CustomToast
                        type={"warn"}
                        message={
                            'No data found'
                        }
                        heading={"Warning"}
                    />,
                    { position: "top-center" }
                );
               navigate("/newsroom") 
            }
            setMainNews(res.data)
            
            })

        .catch((err) => {
            console.log("res",err);
            
        });
    },[id])

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-news?populate=deep,3`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.json())
        .then((res) => {
            setAllNews(res.data)
            })

        .catch((err) => {
            console.log("res",err);
        });
    },[])

    function shuffleArray(array) {
        // Fisher-Yates (Knuth) Shuffle Algorithm , it suffles better than inbuilt random.
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1)); 
          [array[i], array[j]] = [array[j], array[i]]; 
        }
        return array;
      }

    const TripCityFun = (items) => {
        navigate(`/newsroom/${items?.id}`, {
        })
    }

    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/newsroom"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>Newsroom</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>News</p>
                    </div>
                    }
                    <div className="main-container">
                        {
                            // mainNews.map((item, indx)=>{
                             <div key={''} className="main-news-section">
                                  <br />
                                    {/* <h1>{routeData.routeData?.state?.currentData?.attributes?.title}</h1>
                                    <br />
                                    <img src={routeData?.routeData?.state?.currentData?.attributes?.image} alt={''} />
                                    <br /> */}
                                    <div className="main-news-content">
                                    <ReactMarkdown>{mainNews?.attributes?.description}</ReactMarkdown>
                                    </div>
                            </div>
                            // })
                        } 
                        <div className="extra-news-section">
                            <div className="outer-main-card">
                                    <div className="inner-card">
                                        <div className="more-on-and-view-all">
                                            <h2 className="more-on">More on NueGo</h2>
                                            <p className="view-all" >View All &gt;</p>
                                        </div>
                                        <div className="extra-news-outer">
                                        {shuffleArray(allNews).slice(0, 4).map((item,indx)=>(<div className="extra-news-inner cursor-pointer" onClick={()=>TripCityFun(item)}>
                                                        <div className="image-div">
                                                            <img src={item?.attributes?.image} alt="" />
                                                        </div>
                                                        <div className="content-div">
                                                        <h2 className="head">{item?.attributes?.title}</h2>
                                                        <div className="sourece-date">
                                                            <div className="source-image">
                                                                <img src={nuegoLogo} alt="" />
                                                            </div>
                                                            <div className="dot"></div>
                                                            <div className="date">{item?.attributes?.date}</div>
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                                    ))
                                                }
                                            
                                        </div>
                                    </div>
                                
                            </div>
                        </div>                      
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default SpecificNewsPage