import { getNextThirtyDays, getValues } from "../../utils/utils";

// assets
import acIcon from "../../assets/icons/acIcon.svg";
import sleeperIcon from "../../assets/icons/sleeperIcon.svg";
import wifiIcon from "../../assets/icons/wifiIcon.svg";
import liveTracking from "../../assets/icons/liveTracking.svg";
import timeSlot from "../../assets/icons/timeSlot-icon.svg";
import filter from "../../assets/icons/filter-white-outilne-icon.svg";
import timeSlotActive from "../../assets/icons/timeSlot-active.svg";
import filterActive from "../../assets/icons/filter-green-outline.svg";

import waterBottleIcon from '../../assets/icons/AC.svg'
import readingLightIcon from '../../assets/icons/Reading-Light.svg'
import usbChargingPortIcon from '../../assets/icons/USB-Charging-port.svg'
import cctvIcon from '../../assets/icons/CCTV.svg'
import emergencyHotlineIcon from '../../assets/icons/Emergency-Hotline.svg'
import mTicketIcon from '../../assets/icons/m-Ticket.svg'
import trackBusLiveIcon from '../../assets/icons/Track-Bus-Live.svg'
import { ACIcon, CCTVIcon, EmergencyHotlineIcon, MTicketIcon, ReadingLightIcon, TrackBusListingIcon, UsbChargingPortIcon, WaterBottleIcon } from "../../utils/svgIcons";

const exterior1 = "https://cdn.nuego.co.in/greencell/static/Exterior_1.jpg";
const exterior2 = "https://cdn.nuego.co.in/greencell/static/Exterior_2.jpg";
const exterior3 = "https://cdn.nuego.co.in/greencell/static/Exterior_3.jpg";
const exterior4 = "https://cdn.nuego.co.in/greencell/static/Exterior_4.jpg";
const interior1 = "https://cdn.nuego.co.in/greencell/static/interior_1.jpg";
const interior2 = "https://cdn.nuego.co.in/greencell/static/interior_2.jpg";
const interior3 = "https://cdn.nuego.co.in/greencell/static/interior_3.jpg";
const interior4 = "https://cdn.nuego.co.in/greencell/static/interior_4.jpg";

export const filter_template = {
  seatType: {
    feildName: "seatType",
    currentValue: [],
    option: getValues("BookingSeatType"),
  },
  busType: {
    feildName: "busType",
    currentValue: [],
    option: getValues("BusTypeEnum"),
  },
  busTiming: {
    feildName: "busTiming",
    currentValue: [],
    option: getValues("BusTimingEnum"),
  },
  priceRange: {
    feildName: "priceRange",
    currentValue: {
      startPrice: 0.01,
      endPrice: 5000.0,
    },
  },
  droppingPoints: {
    feildName: "droppingPoints",
    currentValue: [],
    option: [],
  },
  boardingPoints: {
    feildName: "boardingPoints",
    currentValue: [],
    option: [],
  },
  amenities: {
    feildName: "amenities",
    currentValue: [],
  },
  sort: {
    feildName: "sort",
    currentValue: getValues("BusListingSortEnum")
      ? getValues("BusListingSortEnum")[1]
      : "FARE_HIGH_TO_LOW",
    option: getValues("BusListingSortEnum"),
  },
};

// export const filter_list_desktop = [
// 	{
// 		field: "seatPosition",
// 		currentValue: [],
// 		option: [{
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Window"
// 		}, {
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Aisle"
// 		}]
// 	},
// 	{
// 		field: "Time",
// 		currentValue: [],
// 		option: [
// 			{
// 				value: false,
// 				text: "Before 6:00 a.m."
// 			}, {
// 				value: false,
// 				text: "Between 6:00 AM - 12:00 PM"
// 			}
// 		]
// 	}, {
// 		field: "seatType",
// 		currentValue: [],
// 		option: [{
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Seater"
// 		}, {
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Sleeper"
// 		}, {
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Women Traveller"
// 		}]
// 	}, {
// 		field: "priceRange",
// 		currentValue: {
// 			start: "",
// 			end: ""
// 		}
// 	}, {
// 		field: "boarding",
// 		currentValue: ""
// 	}, {
// 		field: "dropping",
// 		currentValue: ""
// 	}, {
// 		field: "amenities",
// 		currentValue: [],
// 		option: [{
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Wifi"
// 		}, {
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Live tracking"
// 		}, {
// 			activeIcon: "",
// 			inactiveIcon: "",
// 			text: "Toilet"
// 		}]
// 	}
//
// ]

// export const DateList = getNextSevenDays()
export const DateList = getNextThirtyDays();

export const filterOptionList = [
  { id: "recommended", name: "Recommended" },
  { id: "a-z", name: "A-Z" },
  { id: "z-a", name: "Z-A" },
];

export const bus_list = [
  {
    startTime: "10:20",
    endTime: "1:20",
    priceForTwo: 2000,
    seatsleft: 2,
    travellingTime: 3.53,
    restStop: 2,
    discount: 10,
    amenities: [
      {
        icon: acIcon,
        name: "AC",
      },
      {
        icon: sleeperIcon,
        name: "Sleeper",
      },
      {
        icon: wifiIcon,
        name: "Wifi",
      },
      {
        icon: liveTracking,
        name: "Live Tracking",
      },
    ],
  },
  {
    startTime: "10:20",
    endTime: "1:20",
    priceForTwo: 2000,
    seatsleft: 2,
    travellingTime: 3.53,
    restStop: 2,
    discount: 10,
    amenities: [
      {
        icon: acIcon,
        name: "AC",
      },
      {
        icon: sleeperIcon,
        name: "Sleeper",
      },
      {
        icon: wifiIcon,
        name: "Wifi",
      },
      {
        icon: liveTracking,
        name: "Live Tracking",
      },
    ],
  },
];

export const footer_navgiation = [
  // {
  // 	icon: seating,
  // 	activeIcon: seatingActive,
  // 	name: "seating"
  // },
  {
    icon: timeSlot,
    activeIcon: timeSlotActive,
    name: "time slot",
  },
  // {
  // 	icon: settings,
  // 	activeIcon: settingsActive,
  // 	name: "Preference"
  // },
  {
    icon: filter,
    activeIcon: filterActive,
    name: "Filter",
  },
];

export const photosList = {
  exterior: [
    {
      image: exterior1,
    },
    {
      image: exterior2,
    },
    {
      image: exterior3,
    },
    {
      image: exterior4,
    },
  ],
  interior: [
    {
      image: interior1,
    },
    {
      image: interior2,
    },
    {
      image: interior3,
    },
    {
      image: interior4,
    },
  ],
};

export const amenitiesList = [
  /*
    AC
    Water Bottle
    Reading Light
    USB Charging port
    CCTV
    Emergency Hotline
    m-Ticket
    Track Bus Live
    
    */

    {
        icon: <ACIcon/>,
        activeIcon:<ACIcon active={true}/>,
        name: "AC"
    },
    {
        icon: <WaterBottleIcon/>,
        activeIcon:<WaterBottleIcon active={true}/>,
        name: "Water Bottle"
    },
    {
        icon: <ReadingLightIcon/>,
        activeIcon:<ReadingLightIcon active={true}/>,
        name: "Reading Light"
    },
    {
        icon: <UsbChargingPortIcon/>,
        activeIcon:<UsbChargingPortIcon active={true}/>,
        name: "USB Charging port"
    },
    {
        icon: <CCTVIcon/>,
        activeIcon:<CCTVIcon active={true}/>,
        name: "CCTV"
    },
    {
        icon: <EmergencyHotlineIcon/>,
        activeIcon:<EmergencyHotlineIcon active={true}/>,
        name: "Emergency Hotline"
    },
    {
        icon: <MTicketIcon/>,
        activeIcon:<MTicketIcon active={true}/>,
        name: "m-Ticket"
    },
    {
        icon: <TrackBusListingIcon />,
        activeIcon:<TrackBusListingIcon active={true}/>,
        name: "Track Bus Live"
    }
];


export const BusSeatTypeFE =  {
  1: "Seater",
  2: "Sleeper",
  3: "Sleeper-Seater",
  4: "Semi-Sleeper",
}

export const maxPassengerCount = 6;