import React, { useEffect, useState } from "react";
import "./footerStyle.scss";
import "../../assets/styles/common_styles.scss";
import uuid from "react-uuid";
import { connect } from "react-redux";
import { help, more, travel } from "./data";
import { setAuthType } from "../../store/Actions/AuthActions";
import {
  clearUserData,
  setTermsConditionsModalAction,
} from "../../store/Actions/CommonAction";
import { store } from "../../index";
import cogoToast from "cogo-toast";
import { useMediaQuery } from "react-responsive";
import { CustomToast } from "../CustomToast/CustomToast";
import { useNavigate, Link } from "react-router-dom";
import { clearBookingDataOnLogoutAction } from "../../store/Actions/BookingAction";
import { useAuthContext } from "../../hooks/context/auth-context";

//icons
import mail from "../../assets/icons/mail-icon.svg";
import twit from "../../assets/icons/twit.svg";
import fb from "../../assets/icons/fb.svg";
import insta from "../../assets/icons/insta.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import youtube from '../../assets/icons/yt-icon-foot.svg'

const Footer = (props) => {
  const { setTypeToStore, authTypeFromStore, isBorderRadiusNone } = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();

  const [count, setCount] = useState(1);
  const { setActiveTab } = useAuthContext();

  useEffect(() => {
    return () => {};
  }, [authTypeFromStore]);

  const authHandler = () => {
    if (localStorage.getItem("userToken")) {
      setCount(count + 1);
      localStorage.removeItem("userToken");
      store.dispatch(clearUserData());
      store.dispatch(clearBookingDataOnLogoutAction());
      // store.dispatch(clearOnCheckout());
      navigate('/')
      cogoToast.success(
        <CustomToast
          type={"success"}
          message={"Logged out Successfully"}
          heading={"Success"}
        />,
        { position: "top-center" }
      );
    } else {
      if (isDesktopOrLaptop) {
        setTypeToStore("d-signin");
        setActiveTab("signup");
      } else {
        setTypeToStore("m-signin");
      }
    }
  };

  const redirectHandler = (path) => {
    if (path) {
      navigate(path, { replace: false });
    }
  };

  const termsConditionsHandler = (path) => {
    if (isDesktopOrLaptop) {
      store.dispatch(setTermsConditionsModalAction(true));
    } else {
      if (path) {
        navigate(path, { replace: false });
      }
    }
  };

  return (
    <div className={"footer-component overflow-hidden"}>
      <div
        className={
          isDesktopOrLaptop && !isBorderRadiusNone
            ? "dark-side d-flex justify-content-between"
            : !isDesktopOrLaptop && !isBorderRadiusNone
            ? "dark-side "
            : isDesktopOrLaptop && isBorderRadiusNone
            ? "dark-side-border-radius-none d-flex justify-content-between"
            : "dark-side-border-radius-none"
        }
      >
        {/* TODO  font weight was not applying for mobile section only heading*/}
        {isDesktopOrLaptop ? (
          <div
            className={
              isDesktopOrLaptop ? "row flex-nowrap" : "d-flex flex-wrap"
            }
            style={{ columnGap: "54px" }}
          >
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap main-lable"
                }
              >
                NueGo Travel
              </p>
              <p
                className={
                  "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer"
                }
                onClick={authHandler}
              >
                {localStorage.getItem("userToken")
                  ? "Logout"
                  : "Sign In / Sign Up"}
              </p>
              {travel.map((item) => {
                return (
                  <p
                    key={uuid()}
                    // onClick={() => redirectHandler(item.route)}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer  "
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80  cursor-pointer " +
                          (item.route && "cursor-pointer")
                    }
                  >
                    {" "}
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                More on NueGo
              </p>
              {more.map((item) => {
                return (
                  <p
                    key={uuid()}
                    // onClick={() => redirectHandler(item.route)}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer "
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 cursor-pointer " +
                          (item.route && "cursor-pointer")
                    }
                  >
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                Help Center
              </p>
              {help.map((item) => {
                return (
                  <p
                    key={uuid()}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer"
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 cursor-pointer "
                    }
                    // onClick={() => redirectHandler(item.route)}
                  >
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
          </div>
        ) : (
          <div
            className={
              isDesktopOrLaptop ? "row flex-nowrap" : "d-flex flex-wrap"
            }
            style={{ columnGap: "70px" }}
          >
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap main-lable"
                }
              >
                NueGo Travel
              </p>
              <p
                className={
                  "open-400w-14s-22h white-color whitespace-nowrap opacity-80 cursor-pointer"
                }
                onClick={authHandler}
              >
                {localStorage.getItem("userToken")
                  ? "Logout"
                  : "Sign In / Sign Up"}
              </p>
              {travel.map((item) => {
                return (
                  <p
                    key={uuid()}
                    // onClick={() => redirectHandler(item.route)}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 "
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 " +
                          (item.route && "cursor-pointer")
                    }
                  >
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                More on NueGo
              </p>
              {more.map((item) => {
                return (
                  <p
                    key={uuid()}
                    // onClick={() => redirectHandler(item.route)}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 "
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 " +
                          (item.route && "cursor-pointer")
                    }
                  >
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className="col-4 p-0">
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                Help Center
              </p>
              {help.map((item) => {
                return (
                  <p
                    key={uuid()}
                    className={
                      isDesktopOrLaptop
                        ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer"
                        : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 cursor-pointer "
                    }
                    // onClick={() => redirectHandler(item.route)}
                  >
                    <Link className="footerLink" to={item.route}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </div>
            <div className={""}>
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                Follow Us On
              </p>
              <div className={`d-flex mb-5`}>
                <a
                  href={"https://twitter.com/nuegoindia"}
                  rel="nofollow"
                  target={"_blank"}
                >
                  <img
                    alt={"alt"}
                    src={twit}
                    className={`icon-24 cursor-pointer`}
                  />
                </a>
                <a
                  href={"https://www.facebook.com/BookNueGo"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={fb}
                    className={`icon-24 cursor-pointer mx-2`}
                  />
                </a>
                <a
                  href={"https://www.linkedin.com/company/nuego/"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={linkedin}
                    className={`icon-24 cursor-pointer`}
                  />
                </a>
                <a
                  href={"https://www.instagram.com/nuegoindia/"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={insta}
                    className={`icon-24 cursor-pointer ml-2`}
                  />
                </a>
                <a
                  href={"https://www.youtube.com/@nuegoindia"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={youtube}
                    className={`icon-24 cursor-pointer ml-2`}
                  />
                </a>
              </div>
            </div>
          </div>
        )}

        <div className={"d-flex justify-content-between"}>
          <div className={"mr-5"}>
            {/*<p className={'ubuntu-700w-18s-22h white-color mb-2'}*/}
            {/*>Sign Up</p>*/}
            {/*<p className={'open-400w-14s-24h white-color opacity-80 mb-3'}>To get exclusive offers</p>*/}
            {/*<div className={'subscription-input d-flex align-items-center mb-4'}>*/}
            {/*	<div className={'input-wrap'}>*/}
            {/*		<input className={'border-less-input pl-2'} placeholder={'Enter Email'}/>*/}
            {/*	</div>*/}
            {/*	<div className="button-wrap teal-22BBB0-bg p-2">*/}
            {/*		<img src={arrowRight} className={'icon-24'} alt={'alt'}/>*/}
            {/*	</div>*/}
            {/*</div>*/}
            <p
              className={
                isDesktopOrLaptop
                  ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                  : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
              }
            >
              Write to Us
            </p>
            <p
              className={
                isDesktopOrLaptop
                  ? "open-400w-16s-22h white-color whitespace-nowrap opacity-80 cursor-pointer"
                  : "open-400w-14s-22h white-color whitespace-nowrap opacity-80 cursor-pointer "
              }
            >
              Greencell Express Pvt Ltd
              <br />
              Unit-405, E-Wing,
              <br />
              Corporate Avenue
              <br />
              Chakala, Andheri East
              <br />
              Mumbai - 400093
            </p>

            <div className={"d-flex align-items-center "}>
              <img alt={"alt"} src={mail} className={`icon-24 mr-2`} />
              <a
                href="mailto: support@nuego.in"
                className={
                  isDesktopOrLaptop
                    ? "open-400w-16s-24h white-color mb-0 cursor-pointer"
                    : "open-400w-14s-22h white-color mb-0 cursor-pointer"
                }
              >
                support@nuego.in
              </a>
            </div>
          </div>
          {isDesktopOrLaptop ? (
            <div className={"pl-2"}>
              <p
                className={
                  isDesktopOrLaptop
                    ? "ubuntu-700w-18s-22h white-color whitespace-nowrap"
                    : "ubuntu-700w-16s-24h white-color whitespace-nowrap"
                }
              >
                Follow Us On
              </p>
              <div className={`d-flex mb-5`}>
                <a
                  href={"https://twitter.com/nuegoindia"}
                  rel="nofollow"
                  target={"_blank"}
                >
                  <img
                    alt={"alt"}
                    src={twit}
                    className={`icon-24 cursor-pointer`}
                  />
                </a>
                <a
                  href={"https://www.facebook.com/BookNueGo"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={fb}
                    className={`icon-24 cursor-pointer mx-2`}
                  />
                </a>
                <a
                  href={"https://www.linkedin.com/company/nuego/"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={linkedin}
                    className={`icon-24 cursor-pointer mx-2`}
                  />
                </a>
                <a
                  href={"https://www.instagram.com/nuegoindia/"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={insta}
                    className={`icon-24 cursor-pointer mx-2`}
                  />
                </a>
                <a
                  href={"https://www.youtube.com/@nuegoindia"}
                  target={"_blank"}
                  rel="nofollow"
                >
                  <img
                    alt={"alt"}
                    src={youtube}
                    className={`icon-24 cursor-pointer ml-2`}
                  />
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={"light-side row"}>
        {/* <img src={greencell} alt={'alt'} className={'cursor-pointer'}/> */}
        {/* <div className={'col-4'}/> */}
        <div className={isDesktopOrLaptop ? "col-6" : "col-8 p-0"}>
          <p
            className={
              isDesktopOrLaptop
                ? "open-600w-16s-24h white-color mb-0 "
                : "light-side-mobile-heading open-400w-12s-18h white-color mb-0"
            }
          >
            © GreenCell Express Pvt Ltd. 2022-24
          </p>
        </div>

        <div
          className={
            isDesktopOrLaptop
              ? "col-6 d-flex justify-content-end  "
              : "col-4 d-flex justify-content-end p-0"
          }
        >
          {isDesktopOrLaptop ? (
            <p
              className={
                "open-400w-16s-24h white-color mb-0 mr-3 cursor-pointer"
              }
              onClick={() => termsConditionsHandler("/terms-conditions")}
            >
              Terms & Conditions
            </p>
          ) : (
            <p
              className={
                "open-400w-12s-18h white-color mb-0 mr-3 cursor-pointer"
              }
              onClick={() => termsConditionsHandler("/terms-conditions")}
            >
              T&C
            </p>
          )}

          <p
            className={
              isDesktopOrLaptop
                ? "open-400w-16s-24h white-color mb-0 cursor-pointer"
                : "open-400w-12s-18h white-color mb-0 cursor-pointer"
            }
            // onClick={() => redirectHandler("/privacy-policy")}
          >
            <Link className="footerLink" to="/privacy-policy">
              Privacy Policy
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authTypeFromStore: state.AuthReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  setTypeToStore: (data) => dispatch(setAuthType(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
