import graphql from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import environment from '../../environment';

const mutation = graphql`
	  mutation DeleteChecklistMutation($id: ID!) {
	    deleteCheckList(id: $id) {
	        message
	        checkList {
			    name
			    id
			    checklistitemSet {
			        edges {
			            node {
			                itemName
			            }
			        }
			    }
			}
	    }
	  }`;

export default (data, callback, errCallback) => {
	const variables = {
		id: data.id
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.deleteCheckList !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}

