import graphql from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import environment from "../../environment";

const mutation = graphql`
	mutation CancelBookingMutation(
		$bookingId: ID
		$cancellationReason: String!
		$cancellationRemark: String
		$passengerIds: [String]
		$paymentType:PaymentTypeEnum
		$refundType: Int!
	) {
		confirmPartialBookingCancellation(
	    bookingId:$bookingId
	    cancellationReason:$cancellationReason
	    cancellationRemark:$cancellationRemark
	    passengerIds:$passengerIds
	    paymentType:$paymentType
	    refundType:$refundType
	) {
		    confirmPartialCancellation {
		        pnrNumber
		        newPnrNumber
		        cancelAmount
		        status
		        refundAmount
		        newBookingAmount
		        newServiceTax
		    }
		}
	}
	`

export default (data, callback, errCallback) => {
	const variables = {
		bookingId:data.bookingId,
		cancellationReason:data.cancellationReason,
		cancellationRemark:data.cancellationRemark,
		passengerIds:data.passengerIds,
		paymentType:data.paymentType,
		refundType:data.refundType
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.confirmPartialBookingCancellation !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}