import React, { useRef, useState, useCallback, useEffect}  from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import {useMediaQuery} from "react-responsive";
import { Link } from "react-router-dom";
import { data }from "../data"
// Import Swiper styles
import './RecommendedBlogs.scss'
import 'swiper/css';
import 'swiper/css/grid';
import "swiper/css/navigation";

// import required modules
import { Grid, Navigation } from 'swiper/modules';

const Vector1 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector1.jpg`
const Vector2 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector2.jpg`


function RecommendedBlogs() {
    const [ourRoutes , setOurRoutes] = useState([])
    const sliderRef = useRef(null);
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_CMS_PROD_API + `api/our-routes/?sort=rank:asc&populate=*`)
            .then((res) => res.json())
            .then((res) => {
                setOurRoutes(res?.error?.status === 500 ? [] : res?.data);
            })
            .catch((err) => {
                setOurRoutes([]);
            })

        return () => {
        };
    }, []);
  return (
    <div className='recommended-blogs'>
        <div className='recommended-desc-navigation'>
            <h2>Recommended For You: 
            Discover Destinations</h2>
            {isDesktopOrLaptop && data.length>10 &&(
            <div className='recommended-navigation'>
                        <button
                            onClick={handlePrev}
                           
                        >
                                <img src={Vector1} alt="Vector"/>
                        </button>
                        <button
                            onClick={handleNext}
                        >
                            <div className="right-icon">
                                <img src={Vector2} alt="Vector"/>
                            </div>
                        </button>
                        </div>)}
        </div>
         <div className='slider'>
        <Swiper
                lazy='true'
                slidesPerView={isDesktopOrLaptop? 3: 1.2}
                // breakpoints={
                // {
                //     0: {
                //         slidesPerView: 2.2,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                //         spaceBetween: 15,
                //     },
                //     640: {
                //         slidesPerView: 2.2,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                //         spaceBetween: 15,
                //     },
                //     768: {
                //         slidesPerView: 2.5,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                //         spaceBetween: 15,
                //     },
                //     1024: {
                //         slidesPerView: 3,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                //         spaceBetween: 35,
                //     },
                //     1280: {
                //         slidesPerView: 5,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                        
                //         spaceBetween: 35,
                //     },
                //     1440: {
                //         slidesPerView: 5,
                //         grid: {
                //             rows: 2,
                //              fill:'row'
                //         },
                //         spaceBetween: 35,
                //     },
                // }}
                navigation={true}
                grid={{
                rows: isDesktopOrLaptop ? 2 : 1,
                fill:'row'
                }}
                ref={sliderRef}
                 spaceBetween={isDesktopOrLaptop?35:20}
                modules={[Grid ,Navigation]}
                className="mySwiper"
        >
        <div className='swiper-wrapper'>

                        {
                            data.length > 0?
                            data.filter((el) => el.section=="recommended").map((el, index) => {
                                return (<SwiperSlide key={index}>
                               <div className="extra-blog-div">
                                            <img src={el?.img} alt="" />
                                            <div className="mweb">
                                                <h3>{el?.title}</h3>
                                            <Link to={el?.route}>Read More &gt;</Link>
                                            </div>
                                                </div>
                                           
                                </SwiperSlide>);
                            })
                            : null
                        }
       
        </div>
      </Swiper>
        </div>

    </div>
  )
}

export default RecommendedBlogs