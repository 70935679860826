import graphql from "babel-plugin-relay/macro";
import { commitMutation } from "react-relay";
import environment from "../../environment";

const mutation = graphql`
	mutation LoginMutation($mobileNumber:String!,$captchaToken:String){
		mobileSignUpOrLogin(mobileNumber:$mobileNumber,captchaToken:$captchaToken){
		    status
		    message
		
		    }
	}
	`
export default (data, callback, errCallback) => {
	const variables = {
		mobileNumber: data.mobileNumber,
		captchaToken:data.captchaToken
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.mobileSignUpOrLogin !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			}
		}
	)
}