import React from 'react'
import '../ProfileDetail.scss'
//icons
import arrowIcon from "../../../assets/icons/green-arrow-right.svg"
// assets
const busImage = process.env.REACT_APP_CDN_LINK + `assets/images/profile-mobile-bus.svg`

const ProfileOurVision = () => {
    return (
        <div className={'profile-our-vision d-flex flex-column justify-content-between'}>
            <div>
                <p className={'ubuntu-700w-18s-28h black-1E1E26-color mb-2'}>Our Vision</p>
                <p className={'open-400w-14s-22h black-1E1E26-color mb-2'}>Communities are growing and evolving, and
                    with
                    that, our transportation needs are changing. </p>
                <p className={'open-600w-16s-24h teal-2-00A095-color'}>know more
                    <span><img src={arrowIcon} alt={'alt'}
                               className={'icon-16'}/></span>
                </p>
            </div>
            <img src={busImage} alt={'alt'} className={'bus-image'}/>
        </div>
    )
}

export default ProfileOurVision
