import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";
import environment from "../../environment";

const mutation = graphql`
  mutation BookingCheckoutMutation(
    $busNo: String!
    $dateOfJourney: String!
    $destinationCityId: String!
    $destinationCityName: String!
    $dropId: String!
    $dropName: String!
    $dropTime: String!
    $duration: String!
    $isSameDay: String!
    $pickupId: String!
    $pickupName: String!
    $pickupTime: String!
    $referenceNo: String!
    $routeId: String!
    $routeTimeId: String!
    $seatNumber: [String]!
    $sourceCityId: String!
    $sourceCityName: String!
    $arrivalTime: String
    $departureTime: String
    $pickupImages: [String]
    $dropImages: [String]
    $seatType: [String]!
  ) {
    bookingCheckout(
      busNo: $busNo
      dateOfJourney: $dateOfJourney
      destinationCityId: $destinationCityId
      destinationCityName: $destinationCityName
      dropId: $dropId
      dropName: $dropName
      dropTime: $dropTime
      duration: $duration
      isSameDay: $isSameDay
      pickupId: $pickupId
      pickupName: $pickupName
      pickupTime: $pickupTime
      referenceNo: $referenceNo
      routeId: $routeId
      routeTimeId: $routeTimeId
      seatNumber: $seatNumber
      sourceCityId: $sourceCityId
      sourceCityName: $sourceCityName
      arrivalTime: $arrivalTime
      departureTime: $departureTime
      pickupImages: $pickupImages
      dropImages:$dropImages
      seatType:$seatType
    ) {
      booking {
        id
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
          mobileNumber
          email
        }
        contact {
          id
          bookingId
          phone
          email
        }
        sourceCityId
        sourceCityName
        destinationCityId
        destinationCityName
        dateOfJourney
        pickUpId
        pickupName
        pickupTime
        dropName
        dropTime
        dropId
        referenceNo
        routeId
        pnrNo
        busNumber
        seatNo
        seatAmount
        paidAmenitiesString
        totalPassengers
        seatingType
        status
        duration
        totalBaseFare
        walletAmount
        gstAmount
        amountAfterDiscount
        totalPayable
        getTotalPaidAmount
        discountAmount
        insuranceCharge
        expectedDropDatetime
        orderNetValue
        coupon
        greenCashPromoAmount
        pickupPointImages
        droppingPointImages
      }
    }
  }
`;

export default (data, callback, errCallback) => {
  const variables = {
    busNo: data.busNo,
    dateOfJourney: data.dateOfJourney,
    destinationCityId: data.destinationCityId,
    destinationCityName: data.destinationCityName,
    dropId: data.dropId,
    dropName: data.dropName,
    dropTime: data.dropTime,
    duration: data.duration,
    isSameDay: data.isSameDay,
    pickupId: data.pickupId,
    pickupName: data.pickupName,
    pickupTime: data.pickupTime,
    referenceNo: data.referenceNo,
    routeId: data.routeId,
    routeTimeId: data.routeTimeId,
    seatNumber: data.seatNumber,
    sourceCityId: data.sourceCityId,
    sourceCityName: data.sourceCityName,
    arrivalTime: data.arrivalTime,
    departureTime: data.departureTime,
    pickupPointImages: data.pickupPointImages,
    droppingPointImages: data.droppingPointImages,
    seatType: data.seatType
  };

  commitMutation(environment, {
    mutation,
    variables,

    onCompleted: (response, err) => {
      if (response.bookingCheckout !== null) {
        callback(response);
      } else {
        errCallback(err[0].message);
      }
    },
    onError: (err) => {
      errCallback(err);
    },
  });
};
