import React from 'react';
import CompletedTripDetails from './Component/CompletedTripDetails';

const CompletedTrip = () => {
    return (
        <div>
            <CompletedTripDetails/>
        </div>
    )
}

export default CompletedTrip
