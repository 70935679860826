import React, {useState} from "react";
import "../TicketDetailsPage.scss";
import {useMediaQuery} from "react-responsive";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../components/CustomToast/CustomToast";
import {ReviewFeedBackData} from "../../../services/api/DataUpdating";
import RateUsPlayStore from '../../../assets/icons/playstoreIcon.png'
import { useEffect } from "react";

// assets
const EmojiOne = process.env.REACT_APP_CDN_LINK + `assets/images/emojiOne.png`
const EmojiTwo = process.env.REACT_APP_CDN_LINK + `assets/images/emojiTwo.png`
const EmojiThree = process.env.REACT_APP_CDN_LINK + `assets/images/emojiThree.png`
const EmojiFour = process.env.REACT_APP_CDN_LINK + `assets/images/emojiFour.png`
const EmojiFive = process.env.REACT_APP_CDN_LINK + `assets/images/emojiFive.png`
const ActiveEmojiOne = process.env.REACT_APP_CDN_LINK + `assets/images/activeEmojiOne.png`
const RateUsAppStore = process.env.REACT_APP_CDN_LINK + `assets/images/rateUsAppStore.png`
const freePikStarImage = process.env.REACT_APP_CDN_LINK + `assets/images/freePikStarImage.webp`
const DragText = process.env.REACT_APP_CDN_LINK + `assets/images/dragText.png`
const feedbackSubmissioniIlustration = process.env.REACT_APP_CDN_LINK + `assets/images/feedback-submission-illustration.png`

const CompletedFeedBackTicketDetail = ({data, bookingId,isReviewed,setIsReviewed}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const [activeEmoji, setActiveEmoji] = useState({
        travelExperience: 0,
        hospitality: 0,
        cleanliness: 0,
    });
    const [image, setImage] = useState(null);
    const [content, setContent] = useState("");
    const [feedBack, setFeedBack] = useState(false);
    const [showAllFeedback, setShowAllFeedback] = useState(false);

    const RateUsEmoji = [
        {image: EmojiOne, id: 1, ActiveEmoji: ActiveEmojiOne},
        {image: EmojiTwo, id: 2, ActiveEmoji: EmojiTwo},
        {image: EmojiThree, id: 3, ActiveEmoji: EmojiThree},
        {image: EmojiFour, id: 4, ActiveEmoji: EmojiFour},
        {image: EmojiFive, id: 5, ActiveEmoji: EmojiFive},
    ];

    useEffect(() => {
        if (data.isReviewed === true) {
            setFeedBack(true);
        }else{
            setFeedBack(false);
        }
    }, [data.isReviewed])


    const setImageHandler = (e) => {
        setImage(e.target.files[0]);
    };

    const handleContent = (e) => {
        e.preventDefault();
        setContent(e.target.value);
    };

    const feedbackSubmissions = () => {
        //let tempEmojis = [];
        // for (let i in activeEmoji) {
        if (activeEmoji.travelExperience === 0 && activeEmoji.hospitality === 0 && activeEmoji.cleanliness === 0) {
            cogoToast.error(
                <CustomToast
                    type={"error"}
                    message={"Please select atleast one feedback"}
                    heading="Error"
                />,
                {
                    position: "top-center",
                }
            );
            return;
        }// else {
        //   tempEmojis.push(activeEmoji[i]);
        // }
        //}
        // if (image === null) {
        //   cogoToast.error(
        //     <CustomToast
        //       type={"error"}
        //       message={"Please upload a photo"}
        //       heading="Error"
        //     />,
        //     {
        //       position: "top-center",
        //     }
        //   );
        // }

        //if (tempEmojis.length === 1 || tempEmojis.length === 2 || tempEmojis.length === 3 ) {
        let variables = {
            travelExperience: activeEmoji.travelExperience,
            hospitality: activeEmoji.hospitality,
            cleanliness: activeEmoji.cleanliness,
            content: content,
            bookingId: bookingId,
            image: image,
        };

        if (image?.size > 2097152) {
            cogoToast.error(
                <CustomToast
                    type={"error"}
                    message={"Image size should be less than 2MB"}
                    heading="Error"
                />,
                {
                    position: "top-center",
                }
            );
            return;
        }

        //else {
        ReviewFeedBackData(
            variables,
            () => {
                cogoToast.success(
                    <CustomToast
                        type={"success"}
                        message={"Feedback submitted successfully"}
                        heading="Success"
                    />,
                    {
                        position: "top-center",
                    }
                );
                setFeedBack(true);
                setShowAllFeedback(false);
                setActiveEmoji({
                    travelExperience: 0,
                    hospitality: 0,
                    cleanliness: 0,
                });
                setContent("");
                setImage(null);
                setIsReviewed(true);
            },
            (err) => {
                cogoToast.error(
                    <CustomToast
                        type={"error"}
                        message={err}
                        heading="Error"
                    />,
                    {
                        position: "top-center",
                    }
                );
                setIsReviewed(false);
            }
        );
        //}

        //}
    };

    let userName, userEmail, userId, userNmber;
    userName = localStorage.getItem("userName");
    userEmail = localStorage.getItem("userEmail");
    userId = localStorage.getItem("userID");
    userNmber = localStorage.getItem("userNumber");

    const handleFeedBackButton = () => {
        if (showAllFeedback === false) {
            setShowAllFeedback(true);
        } else {
            feedbackSubmissions();
        }
        //Moengage Event for App rating
        window.Moengage.track_event("app_rating", {
            platform: "website",
            business: "bus",
            booking_id: `${bookingId}`,
        });

        //Moengage Event for journey_feedback
        window.Moengage.track_event("journey_feedback", {
            platform: "website",
            business: "bus",
            fullname: `${userName === null ? "" : userName}`,
            mobile_number: `${userNmber === null ? "" : userNmber}`,
            email: `${userEmail === null ? "" : userEmail}`,
            isloggedin: `${userId === null ? "no" : "yes"}`,
            booking_id: `${bookingId}`,
            cleanliness: `${activeEmoji.cleanliness === null ? "no" : "yes"}`,
            staff_behaviour: `${activeEmoji.hospitality === null ? "no" : "yes"}`,
        });
    };
    // console.log(activeEmoji.cleanliness, "iddidid");

    return (
        <div className={"completed-feedback-ticket-detail py-lg-0 py-4"}>
            {/* rate us */}
            {isReviewed === false && (
                <div className="">
                    <div className="d-flex opinion justify-content-between pb-0">
                        <div>
                            <p className="opinion-heading mb-3">Your Opinion matters to us</p>
                            <p className="opinion-help py-0 mb-4">Help us get better!</p>
                        </div>
                        <div className={"d-flex justify-content-center align-items-end"}>
                            <img alt="alt" src={freePikStarImage}/>
                        </div>
                    </div>
                    <div className="rate-us">
                        <p className="rate-us-heading">Rate us?</p>
                        <p className="rate-us-description">
                            How was your overall travel experience with us?
                        </p>
                        <div className="d-flex rate-us-emoji flex-wrap ">
                            {RateUsEmoji.map((data, index) => (
                                <img
                                    key={index}
                                    className={
                                        activeEmoji.travelExperience === data.id
                                            ? "active-emoji"
                                            : "inActive-emoji"
                                    }
                                    onClick={() =>
                                        setActiveEmoji({
                                            ...activeEmoji,
                                            travelExperience: data.id,
                                        })
                                    }
                                    alt="alt"
                                    src={
                                        activeEmoji.travelExperience === data.id
                                            ? data.ActiveEmoji
                                            : data.image
                                    }
                                />
                            ))}
                        </div>

                        {showAllFeedback && (
                            <div>
                                <p className="rate-us-heading">Staff’s hospitality</p>
                                <p className="rate-us-description">
                                    Were we able to help you out with everything?
                                </p>
                                <div className="d-flex rate-us-emoji flex-wrap ">
                                    {RateUsEmoji.map((data, index) => (
                                        <img
                                            key={index}
                                            className={
                                                activeEmoji.cleanliness === data.id
                                                    ? "active-emoji"
                                                    : "inActive-emoji"
                                            }
                                            onClick={() =>
                                                setActiveEmoji({...activeEmoji, cleanliness: data.id})
                                            }
                                            alt="alt"
                                            src={
                                                activeEmoji.cleanliness === data.id
                                                    ? data.ActiveEmoji
                                                    : data.image
                                            }
                                        />
                                    ))}
                                </div>
                                <p className="rate-us-heading">Cleanliness</p>
                                <p className="rate-us-description">
                                    Did you find everything clean?
                                </p>
                                <div className="d-flex rate-us-emoji flex-wrap ">
                                    {RateUsEmoji.map((data, index) => (
                                        <img
                                            key={index}
                                            className={
                                                activeEmoji.hospitality === data.id
                                                    ? "active-emoji"
                                                    : "inActive-emoji"
                                            }
                                            onClick={() =>
                                                setActiveEmoji({...activeEmoji, hospitality: data.id})
                                            }
                                            alt="alt"
                                            src={
                                                activeEmoji.hospitality === data.id
                                                    ? data.ActiveEmoji
                                                    : data.image
                                            }
                                        />
                                    ))}
                                </div>

                                <p className="rate-us-heading">Write a review</p>
                                <p className="rate-us-description">
                                    Add photos and let our dear customers know more about your
                                    experience with GreenCelll.
                                </p>

                                <textarea
                                    className="feed-back-text-box"
                                    onChange={(e) => handleContent(e)}
                                />

                                {/* Image upload */}
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input
                                            className="file-upload-input"
                                            type="file"
                                            onChange={(e) => setImageHandler(e)}
                                            accept="image/*"
                                        />
                                        {image !== null ? (
                                            <div className=" drag-text">
                                                <p className="drag-text-heading">{image.name}</p>
                                                <p className="drag-text-description">{image.size} KB</p>
                                            </div>
                                        ) : (
                                            <div className="d-flex align-items-center drag-text">
                                                <img src={DragText} alt="alt"/>
                                                <div>
                                                    <p className="drag-text-heading">Upload an image</p>
                                                    <p className="drag-text-description">
                                                        Supports .jpg or .png upto 3MB
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {/*  */}
                            </div>
                        )}

                        <button
                            onClick={() => handleFeedBackButton()}
                            className={
                                isDesktopOrLaptop
                                    ? " m-0 rate-us-btn d-flex justify-content-start"
                                    : "rate-us-btn d-flex justify-content-center"
                            }
                        >
                            Share Feedback
                        </button>
                    </div>
                </div>
            )}

            {/* feedBack */}
            {isReviewed && (
                <div className="">
                    <div className="d-flex opinion justify-content-between align-items-center pb-0">
                        <div>
                            <p className="opinion-heading text-nowrap">
                                Thank you for the feedback!
                            </p>
                            <p className="opinion-help">
                                It will help us continue to improve our services
                            </p>
                        </div>
                        <img alt="alt" src={feedbackSubmissioniIlustration}/>
                    </div>
                    <div className="rate-us">
                        <p className="rate-us-heading">Loved travelling with us?</p>
                        <p className="rate-us-description">
                            Tell everyone about your experience and rate us on the app store.
                        </p>
                    </div>
                    <button
                        className="d-flex align-items-center justify-content-center teal-2-00A095-color gap-12 px-4 green-outline-button">
                        <img src={RateUsPlayStore} alt="alt" className="icon-24"/>
                        Rate us on Play store
                    </button>
                </div>
            )}
        </div>
    );
};

export default CompletedFeedBackTicketDetail;
