//new --------------------------------------------------
import {store as reduxStore} from "./index"
import {setTokenData} from "./store/Actions/AuthActions";
import {Environment, RecordSource, Store,} from 'relay-runtime';
import {api} from "./utils/constants";

// for refresh token
import {
	authMiddleware,
	cacheMiddleware,
	progressMiddleware,
	RelayNetworkLayer,
	urlMiddleware
} from 'react-relay-network-modern';
import {clearUserData} from "./store/Actions/CommonAction";
import {jwtDecode} from "jwt-decode";
import {clearBookingDataOnLogoutAction} from "./store/Actions/BookingAction";
const oneMinute = 60 * 1000 * 10;
// const cache = new QueryResponseCache({size: 1024, ttl: oneMinute});
// const tokenDataFromStore =reduxStore.getState().AuthReducer.tokenData
// reduxStore.getState().AuthReducer.tokenData!== {} ? tokenDataFromStore.token : null
// 	let userToken = tokenDataFromStore !== {} ? tokenDataFromStore.token : null;
// let refreshToken = tokenDataFromStore !== {} ? tokenDataFromStore.refreshToken : null

let refreshToken_localStorage = localStorage.getItem("refreshToken");

// to avoid token as header in authentication operation such as login and signUp
// let notAn_authOperation = store.getState().AuthReducer.type === ""

const network = new RelayNetworkLayer(
	[
		cacheMiddleware({
			size: 1024, // max 100 requests
			ttl: oneMinute, // 15 minutes
		}),
		urlMiddleware({
			url: () => Promise.resolve(api),
			headers: {
				'Accept': 'application/json',
			}
		}),
		authMiddleware({

			token: () => {
				// console.log('auth middleware init')
				let token = localStorage.getItem("userToken") || null;
				const TokenVerify = () => {

					if (localStorage.getItem("userToken") && localStorage.getItem("userToken") !== "null") {
						// console.log('refresh token init init')
						let exp_time = jwtDecode(localStorage.getItem("userToken")).exp;
						let MS_PER_MINUTE = 60000;
						let CurrentDate = new Date()
						let ExpiryDate = new Date(exp_time * 1000)
						// let ExpiryDate = new Date()

						let FormattedExpiryDate = new Date(new Date(exp_time * 1000) - 2 * MS_PER_MINUTE)
						if (CurrentDate.getTime() < ExpiryDate.getTime() && CurrentDate.getTime() > FormattedExpiryDate.getTime()) {
							// console.log("refreshed")
							if (localStorage.getItem("refreshToken")) {
								let requestVariables = {
									method: 'POST',
									headers: {
										'Accept': 'application/json',
										'Content-Type': 'application/json'
									}
								}
								let variables = {
									"refreshToken": localStorage.getItem("refreshToken")
								}
								let body = JSON.stringify({
									query: "mutation RefreshTokenMutation($refreshToken:String!) {\n       refreshToken(refreshToken:$refreshToken){\n           token\n           refreshToken\n           payload\n       }\n  }",
									variables
								})
								return fetch(api, {...requestVariables, body})
									.then((res) => res.json())
									.then((json) => {
										if (json.data.refreshToken !== null && json.data.refreshToken !== "Token Expired") {
											localStorage.setItem('userToken', json.data.refreshToken.token);
											localStorage.setItem('refreshToken', json.data.refreshToken.refreshToken)

											reduxStore.dispatch(setTokenData({
												token: json.data.refreshToken.token,
												refreshToken: json.data.refreshToken.refreshToken,
											}))

											let user_data = {};
											user_data.token = json.data.refreshToken.token;
											user_data.refreshToken = json.data.refreshToken.refreshToken;
											// ReduxStore.dispatch(UserLoggedin(user_data));
											return json.data.refreshToken.token;
										} else {
											// ReduxStore.dispatch(UserLoggedout({}));
											reduxStore.dispatch(clearUserData());
											reduxStore.dispatch(clearBookingDataOnLogoutAction())
											localStorage.removeItem('userToken');
											localStorage.removeItem('refreshToken');
											localStorage.removeItem("moeID")
											return 'Authorization failed';
										}

									})
									.catch(() => {
										// ReduxStore.dispatch(UserLoggedout({}));
										reduxStore.dispatch(clearUserData());
										reduxStore.dispatch(clearBookingDataOnLogoutAction())
										localStorage.removeItem('userToken');
										localStorage.removeItem('refreshToken');
										localStorage.removeItem("moeID");
										return 'Authorization failed';
									});
							}
						} else if (CurrentDate.getTime() > ExpiryDate.getTime()) {
							// console.log("logout")
						
							
							
							localStorage.removeItem('userToken');
							localStorage.removeItem('refreshToken');
							localStorage.removeItem("moeID");
							localStorage.removeItem('userID');
							token = null;
							reduxStore.dispatch(clearUserData());
							reduxStore.dispatch(clearBookingDataOnLogoutAction())
							alert("Session Expired")
						}
					} 
				}

				if (token && token !== "") {
					// console.log('verifying token')
					TokenVerify();
					return token;
				}
			},
			tokenRefreshPromise: () => {

				// let user_details = reduxStore.getState().BookingReducer.setUserData;

				if (refreshToken_localStorage) {
					let requestVariables = {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						}
					}
					let variables = {
						"refreshToken": refreshToken_localStorage
					}
					let body = JSON.stringify({
						query: "mutation RefreshTokenMutation($refreshToken:String!) {\n       refreshToken(refreshToken:$refreshToken){\n           token\n           refreshToken\n           payload\n       }\n  }",
						variables
					})
					return fetch(api, {...requestVariables, body})
						.then((res) => res.json())
						.then((json) => {
							if (json.data.refreshToken !== null && json.data.refreshToken !== "Token Expired") {
								localStorage.setItem('userToken', json.data.refreshToken.token);
								localStorage.setItem('refreshToken', json.data.refreshToken.refreshToken)

								reduxStore.dispatch(setTokenData({
									token: json.data.refreshToken.token,
									refreshToken: json.data.refreshToken.refreshToken,
								}))

								let user_data = {};
								user_data.token = json.data.refreshToken.token;
								user_data.refreshToken = json.data.refreshToken.refreshToken;
								// ReduxStore.dispatch(UserLoggedin(user_data));
								return json.data.refreshToken.token;
							} else {
								// ReduxStore.dispatch(UserLoggedout({}));
								reduxStore.dispatch(clearUserData({}));
								reduxStore.dispatch(clearBookingDataOnLogoutAction())
								localStorage.removeItem('userToken');
								localStorage.removeItem('refreshToken');
								localStorage.removeItem("userEmail");
								localStorage.removeItem("userNumber");
								localStorage.removeItem("moeID");
								return 'Authorization failed';
							}

						})
						.catch(() => {
							// ReduxStore.dispatch(UserLoggedout({}));
							reduxStore.dispatch(clearUserData({}));
							reduxStore.dispatch(clearBookingDataOnLogoutAction())
							localStorage.removeItem('userToken');
							localStorage.removeItem('refreshToken');
							localStorage.removeItem("userEmail");
							localStorage.removeItem("userNumber");
							localStorage.removeItem("moeID");
							return 'Authorization failed';
						});
				}
			},
			prefix: "JWT ",
			allowEmptyToken: true
		}),
		progressMiddleware({
			onProgress: (current, total) => {
				// console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
			},
		}),
		// example of the custom inline middleware
		(next) => async (req) => {
			if(req.fetchOpts.headers.Authorization){
				let token = req.fetchOpts.headers.Authorization.split(" ")[1]
				let exp_time = jwtDecode(token).exp;
				let MS_PER_MINUTE = 60000;
				let CurrentDate = new Date()
				let ExpiryDate = new Date(exp_time * 1000)
				if (CurrentDate.getTime() > ExpiryDate.getTime()) {
					// console.log("logout")
				
					alert("Session Expired")
					
					localStorage.removeItem('userToken');
					localStorage.removeItem('refreshToken');
					localStorage.removeItem("userEmail");
					localStorage.removeItem("userNumber");
					localStorage.removeItem("moeID");
					localStorage.removeItem('userID');
					token = null;
					reduxStore.dispatch(clearUserData());
					reduxStore.dispatch(clearBookingDataOnLogoutAction())
					window.location.replace("/")
					
				}
			}
			return await next(req);
		},
	],
	{
		noThrow: true
	}
); // as second arg you may pass advanced options for RRNL

const source = new RecordSource();
const store = new Store(source);
const environment = new Environment({network, store});

export default environment;
//
//

// old ----------------------
// import {
// 	Environment,
// 	Network,
// 	RecordSource,
// 	Store,
// 	QueryResponseCache,
// } from 'relay-runtime';
// import {api} from "./utils/constants";
// import {store} from "./index";
//
// const oneMinute = 60 * 1000 * 10;
// const cache = new QueryResponseCache({size: 1024, ttl: oneMinute});
//
// function fetchQuery(
// 	operation,
// 	variables,
// 	cacheConfig,
// ) {
//
// 	const queryID = operation.text;
// 	const isMutation = operation.operationKind === 'mutation';
// 	const isQuery = operation.operationKind === 'query';
// 	const forceFetch = cacheConfig && cacheConfig.force;
//
// 	const fromCache = cache.get(queryID, variables);
// 	if (isQuery && fromCache !== null && !forceFetch) {
// 		return fromCache;
// 	}
//
// 	// let user = localStorage.getItem('token')

// 	let user = store.getState().AuthReducer.tokenData !== {} ? store.getState().AuthReducer.tokenData.token : null;
// 	let notAn_authOperation = store.getState().AuthReducer.type === ""
// 	let requestVariables;
//
// 	if (user && notAn_authOperation) {
// 		requestVariables = {
// 			method: 'POST',
// 			headers: {
// 				'Accept': 'application/json',
// 				'Authorization': 'JWT ' + user,
// 				'Content-Type': 'application/json'
// 			}
// 		}
// 	} else {
//
// 		requestVariables = {
// 			method: 'POST',
// 			headers: {
// 				'Accept': 'application/json',
// 				'Content-Type': 'application/json',
//
// 			}
// 		}
// 	}
// 	let body = JSON.stringify({
// 		query: operation.text,
// 		variables
// 	})
//
// 	return fetch(api, {
// 		...requestVariables,
// 		body
// 	}).then(response => {
// 		return response.json();
// 	}).then(json => {
// 		// Update cache on queries
// 		if (isQuery && json) {
// 			cache.set(queryID, variables, json);
// 		}
// 		// Clear cache on mutations
// 		if (isMutation) {
// 			cache.clear();
// 		}
//
// 		return json;
// 	});
// }
//
// const environment = new Environment({
// 	network: Network.create(fetchQuery),
// 	store: new Store(new RecordSource()),
// });
//
// export default environment;
