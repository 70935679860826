import {useMediaQuery} from "react-responsive";
import "./ChangeBooking.scss";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";

import {MDBModal, MDBModalBody} from "mdbreact";

//assets
const cancelImage = process.env.REACT_APP_CDN_LINK + `assets/images/cancel.png`
const rescduleImage = process.env.REACT_APP_CDN_LINK + `assets/images/cancel.png`
const modifyImage = process.env.REACT_APP_CDN_LINK + `assets/images/modify.png`
const cross = process.env.REACT_APP_CDN_LINK + `assets/images/crossImage.png`
const CannotRescheduleImage = process.env.REACT_APP_CDN_LINK + `assets/images/Can-not-reschedule-booking.png`

const ChangeBookingSection = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <div className={"change-booking-page"}>
            {isDesktopOrLaptop ? (
                <div>
                    <div
                        style={
                            isDesktopOrLaptop
                                ? {marginBottom: "100px"}
                                : {marginBottom: "78px"}
                        }
                    ></div>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                </div>
            ) : (
                <MobileSubScreenLayout
                    back={() => navigate(-1)}
                    title={"Change Booking"}
                >
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                </MobileSubScreenLayout>
            )}
        </div>
    );
};

export const MainContent = (props) => {
    const {typeFromStore} = props
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const [activeTrip, setActiveTrip] = useState(2);
    const [close, setclose] = useState(true)
    const BookingTripData = [
        {
            id: 1,
            //   image: "",
            //   name: "Uhoh!",
            //   description: "Looks like you do not have any trips",
            BtnName: "Cancel",
            Btnimage: cancelImage,
        },
        {
            id: 2,
            image: CannotRescheduleImage,
            name: "Oops! We can’t Reschedule Again",
            description:
                "As per the NueGo policy, you cannot reschedule  more than once",
            BtnName: "Reschedule",
            Btnimage: rescduleImage,
        },
        {
            id: 3,
            //   image: "",
            //   name: "Uhoh!",
            //   description: "You do not have any cancelled trips",
            BtnName: "Modify",
            Btnimage: modifyImage,
        },
    ];
    return (
        <div className={"main-content"}>
            {!isDesktopOrLaptop && (
                <div>
                    <div className=" bookig-trip-state ">
                        {BookingTripData.map((data, index) => (
                            <div
                                key={index}
                                onClick={() => setActiveTrip(data.id)}
                                className={
                                    activeTrip === data.id
                                        ? " text-center active-trip"
                                        : "text-center inactive-trip"
                                }
                            >

                                {data.BtnName}
                            </div>
                        ))}
                    </div>
                    {BookingTripData.map((data, index) => (
                        <div key={index}>
                            {activeTrip === data.id && (
                                <div className="px-3 text-center booking-trip-part">
                                    <img src={data.image} alt={"alt"}/>
                                    <h2>{data.name}</h2>
                                    <p>{data.description}</p>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="d-flex justify-content-center px-3">
                        <button>Go to Home</button>
                    </div>
                </div>
            )}
            {isDesktopOrLaptop && close === true && (
                <div style={{gap: "287px"}} className="d-flex">


                    {/* auth modal*/}
                    <MDBModal isOpen={typeFromStore !== ""} position={'center'} centered={true}
                              size={"fluid"}>
                        <MDBModalBody>
                            <div className="desktop-booking-trip-part">
                                <div className="d-flex justify-content-between">
                                    <h1>My Bookings</h1>
                                    <img src={cross} alt={'alt'} className='cross-image'
                                         onClick={() => setclose(false)}/>
                                </div>

                                <div className="pt-4 desktop-booking-trip">
                                    {BookingTripData.map((data, index) => (
                                        <div
                                            key={index}
                                            onClick={() => setActiveTrip(data.id)}
                                            className={
                                                activeTrip === data.id
                                                    ? " text-center active-trip"
                                                    : "text-center inactive-trip"
                                            }
                                        >
                                            <img alt={'alt'} src={data.Btnimage} className="btn-image"/>
                                            {data.BtnName}

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                {BookingTripData.map((data, index) => (
                                    <div key={index}>
                                        {activeTrip === data.id && (
                                            <div className="px-3  booking-trip-part">
                                                <div className="text-center">
                                                    <img src={data.image} alt={"alt"}/>
                                                </div>
                                                <h2>{data.name}</h2>
                                                <p>{data.description}</p>
                                            </div>
                                        )}
                                    </div>
                                ))}
                                <div className="d-flex justify-content-center ">
                                    <button className="text-nowrap">Go to Home</button>
                                </div>
                            </div>


                        </MDBModalBody>
                    </MDBModal>


                </div>
            )}


        </div>
    );
};
export default ChangeBookingSection;
