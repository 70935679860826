import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
	mutation DownloadMobileAppMutation($mobileNumber:String!){
		downloadMobileApp(mobileNumber:$mobileNumber){
		    message
		    }
	}
	
	
	`
export default (data, callback, errCallback) => {
	const variables = {
		mobileNumber: data.mobileNumber
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.downloadMobileApp !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			}
		}
	)
}