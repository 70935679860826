import React, { useEffect, useRef, useState } from "react";
import "./SubScreens.scss";
// imports
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import {
  checkRescheduleStatusData,
  postBookingData,
} from "../../../services/api/DataFetching";
import Calendar from "react-calendar";
import {
  addDaysToDate,
  convertDateOfJourneyAsCalendar,
  convertDateWithMonth,
  convertToDateFormat,
} from "../../../utils/utils";
import { store } from "../../../index";
import {
  setPassengerCountAction,
  setPassengerData,
  setSourceDestinationIdAction,
} from "../../../store/Actions/BookingAction";
import { clearOnCheckout } from "../../../store/Actions/CommonAction";

//icons
import calendarIcon from "../../../assets/icons/calendar.svg";
import downArrow from "../../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg";
import rightArrow from "../../../assets/icons/white-arrow-right.svg";
import rightTailedArrow from "../../../assets/icons/right-tailed-arrow.svg";
import { set } from "lodash";
import Loader from "../../Loader/Loader";

const CannotRescheduleImage =
  process.env.REACT_APP_CDN_LINK +
  `assets/images/Can-not-reschedule-booking.png`;

const RescheduleBooking = (props) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const navigate = useNavigate();
  const { bookingId, submitHandler } = props;
  const ref = useRef();

  const [rescheduleData, setRescheduleData] = useState({});
  const [calendarValue, setCalendarValue] = useState("");
  const [viewCalendar, setViewCalendar] = useState(false);
  const [actualDate, setActualDate] = useState("");
  const [canReschedule, setCanReschedule] = useState(true);
  const [loading, setLoading] = useState(false);

  let componentConstant;

  if (isDesktopOrLaptop) {
    componentConstant = {
      passengerListWrap_class:
        "padding-x-local margin-bottom-32 green-area py-4 mt-3",
      title_class: "open-600w-16s-24h",
      description_class: "open-600w-16s-24h",
      options_class: "open-600w-16s-24h",
      reasonWrap_class: "grey-area py-4",
      secondaryText_class: "open-400w-18s-28h mb-0 opacity-80",
      submitButtonWrap_class:
        "d-flex justify-content-end open-600w-18s-28h padding-top-side-local",
      submitButton_class: "submit-button",
    };
  } else {
    componentConstant = {
      passengerListWrap_class: "padding-x-local green-area py-4 mt-3",
      title_class: "open-600w-16s-24h",
      description_class: "open-600w-16s-24h",
      options_class: "open-600w-16s-24h",
      reasonWrap_class: "",
      secondaryText_class: "open-600w-16s-24h mb-0",
      submitButtonWrap_class: "d-flex w-fit-content open-600w-16s-24h ",
      submitButton_class: "submit-button w-fit-content",
    };
  }

  let userName, userEmail, userId, userNmber , moeId;
  userName = localStorage.getItem("userName");
  userEmail = localStorage.getItem("userEmail");
  userId = localStorage.getItem("userID");
  userNmber = localStorage.getItem("userNumber");
  moeId = localStorage.getItem("moeID");

  useEffect(() => {
    window.Moengage.track_event("srp_landing", {
      account_id: `${moeId === null ? "" : moeId}`,
      business: "bus",
      platform: "website",
      fullname: `${userName === null ? "" : userName}`,
      mobile_number: `${userNmber === null ? "" : userNmber}`,
      email: `${userEmail === null ? "" : userEmail}`,
      isloggedin: `${userNmber === null ? "no" : "yes"}`,
      date_changed: `${calendarValue === null ? "no" : "yes"}`,
    });

    let variable = {
      bookingId: bookingId,
    };
    setLoading(true);
    checkRescheduleStatusData(
      variable,
      (resData) => {
        setRescheduleData(resData.rescheduleStatus); // reschedule data
        postBookingData(variable, (data) => {
          let date = convertDateOfJourneyAsCalendar(data.dateOfJourney);
          setActualDate(convertDateWithMonth(date));
          setCalendarValue(date.toString());

          store.dispatch(clearOnCheckout());
          store.dispatch(
            setPassengerCountAction({
              passengersCount: data.passengerdetailSet.length,
            })
          );
          store.dispatch(setPassengerData(data.passengerdetailSet));
          store.dispatch(
            setSourceDestinationIdAction({
              sourceId: data.sourceCityId,
              destinationId: data.destinationCityId,
            })
          );
          setLoading(false);
          // setCalendarValue(new Date().toString())
        });

        if (!isDesktopOrLaptop) {
          setTimeout(() => {
            setViewCalendar(true);
          }, 2000);
        }
      },
      (data) => {
        setCanReschedule(false);
        setLoading(false);
      }
    );
  }, []);

  if (isDesktopOrLaptop) {
  } else {
  }

  return (
    <div className={"reschedule-booking-component"}>
      {loading ? (
        <div className={"loader-wrapper"}>
          <Loader />
        </div>
      ) : (
        <div>
          {rescheduleData?.allowReschedule ? (
            <div>
              <div className={"padding-x-local"}>
                <p className={componentConstant.title_class + " mb-3"}>
                  Please select the new date for your travel
                </p>
                <p className={componentConstant.secondaryText_class}>Date</p>

                {isDesktopOrLaptop && (
                  <div className={"position-relative"}>
                    <div
                      onClick={() => setViewCalendar(!viewCalendar)}
                      className={
                        "common-input-wrap d-flex justify-content-between date-input align-items-center mb-2 cursor-pointer"
                      }
                    >
                      <div className={"d-flex align-items-center"}>
                        <img
                          src={calendarIcon}
                          className={"icon-24 mr-3"}
                          alt={"calendar"}
                        />
                        <p className={"open-600w-18s-28h mb-0"}>
                          {convertDateWithMonth(calendarValue)}
                        </p>
                      </div>
                      <img src={downArrow} className={"icon-24"} alt={"alt"} />
                    </div>
                    {viewCalendar && (
                      <div className={"calendar-wrapper"}>
                        <Calendar
                          onChange={(e) => setCalendarValue(e.toString())}
                          defaultValue={convertToDateFormat(calendarValue)}
                          value={convertToDateFormat(calendarValue)}
                          minDate={new Date()}
                          inputRef={ref}
                          maxDate={addDaysToDate(new Date(), 30)}
                          onClickDay={(e) => {
                            // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                            //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                            setTimeout(() => {
                              // deliverySlotChecker(formik, convertDateReverse(e))
                              setViewCalendar(false);
                            }, 1000);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* mobile calendar */}
                {!isDesktopOrLaptop && (
                  <div className={"position-relative mobile-calendar-wrapper"}>
                    {viewCalendar && (
                      <div className={"calendar-wrapper"}>
                        <Calendar
                          onChange={(e) => setCalendarValue(e.toString())}
                          defaultValue={convertToDateFormat(actualDate)}
                          value={convertToDateFormat(calendarValue)}
                          minDate={new Date()}
                          inputRef={ref}
                          maxDate={addDaysToDate(new Date(), 30)}
                          onClickDay={(e) => {
                            // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                            //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                            // setTimeout(() => {
                            //     // deliverySlotChecker(formik, convertDateReverse(e))
                            //     setViewCalendar(false)
                            // }, 1000)
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                {isDesktopOrLaptop && (
                  <div>
                    {/*<p className={'open-400w-14s-22h opacity-80'}>Additional cost of ₹250 will be charged for*/}
                    {/*	rescheduling*/}
                    {/*	your ticket</p>*/}
                    <div className={componentConstant.submitButtonWrap_class}>
                      <button
                        className={
                          componentConstant.submitButton_class +
                          " d-flex justify-content-center"
                        }
                        onClick={() =>
                          submitHandler(convertDateWithMonth(calendarValue))
                        }
                      >
                        <p className={"mb-0"}>View Coaches</p>
                        <img
                          src={rightArrow}
                          className={"icon-24 ml-2"}
                          alt={"alt"}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
              {/* mobile reschedule date */}
              {!isDesktopOrLaptop && (
                <div>
                  <div
                    className={
                      "grey-area d-flex justify-content-between align-items-center p-3"
                    }
                  >
                    <div>
                      <p className={"open-400w-14s-22h opacity-60"}>
                        Current Date
                      </p>
                      <p className={componentConstant.secondaryText_class}>
                        {actualDate}
                      </p>
                    </div>
                    <img
                      src={rightTailedArrow}
                      alt={"alt"}
                      className={"icon-24"}
                    />
                    <div>
                      <p className={"open-400w-14s-22h opacity-60"}>
                        Reschedule to
                      </p>
                      <p className={componentConstant.secondaryText_class}>
                        {convertDateWithMonth(calendarValue)}
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      "bottom-dark-fold settle-at-bottom w-100 d-flex justify-content-between align-items-center p-3"
                    }
                  >
                    <div>
                      <p
                        className={
                          "open-400w-14s-22h white-color opacity-60 mb-0"
                        }
                      >
                        Reschedule Cost
                      </p>
                      {/* todo price hard coded */}
                      <p className={"open-700w-16s-24h white-color mb-0"}>
                        ₹{rescheduleData.rescheduleCharge}
                      </p>
                    </div>

                    <div className={componentConstant.submitButtonWrap_class}>
                      <button
                        className={
                          componentConstant.submitButton_class +
                          " d-flex justify-content-center p-3 "
                        }
                        style={{ maxWidth: "180px", minWidth: "180px" }}
                        onClick={() =>
                          submitHandler(convertDateWithMonth(calendarValue))
                        }
                      >
                        <p className={"mb-0"}>View Coaches</p>
                        <img
                          src={rightArrow}
                          className={"icon-24 ml-2"}
                          alt={"alt"}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className={"text-center w-100"}>
              <img
                src={CannotRescheduleImage}
                className={"cannot-reschuedule-illustration"}
                alt={"alt"}
              />
              <p className={"ubuntu-700w-24s-32h mb-3"}>
                Oops! We Can’t Reschedule Again
              </p>
              <p
                className={
                  "open-400w-16s-24h grey-278787d-color margin-bottom-32"
                }
              >
                {" "}
                As per the NueGo policy, you cannot reschedule more than once.
              </p>
              <button className="submit-button" onClick={() => navigate("/")}>
                Go Back To Home
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RescheduleBooking;
