import React, { useEffect, useRef, useState } from 'react';
import './style.scss'
import Welcome from './Component/Welcome';
import GreenVision from './Component/GreenVision';
import NueGoTrivia from './Component/NueGoTrivia';
import Navbar from "../../components/Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
import Footer from "../../components/Footer/Footer";
import { Helmet } from 'react-helmet';


//assets
import backArrowWhite from "../../assets/icons/back-white-arrow.svg"
import NuegoTeam from './Component/NuegoTeam';
import OurLeader from './Component/OurLeader';
import AuthModalLayout from '../../layout/AuthModalLayout/AuthModalLayout';
import { useNavigate } from 'react-router';

const tabs = [
    {
        id: 'our_story',
        title: "Our Story",
    }, {
        id: 'green_vision',
        title: "Green Vision",
    },
    {
        id: "what_we_do",
        title: "What We Do?",
    },
    {
        id: "our_leaders",
        title: "Board of Directors",
    },
    {
        id: "nuego_team",
        title: "NueGo Leadership",
    }
]

const AboutUsPage = () => {

    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    const backNavigation = () => {
        navigate(-1)
    }

    let pageConstant

    if (isDesktopOrLaptop) {
        pageConstant = {
            subTitle_class: "open-400w-18s-28h grey-3-adadad-color margin-bottom-100",
            tabText_class: "open-600w-18s-28h mb-0"
        }
    } else {
        pageConstant = {
            subTitle_class: "open-600w-14s-22h white-color text-center  mb-4",
            tabText_class: "open-700w-14s-22h mb-0"
        }
    }

    const [activeTab, setActiveTab] = useState("")

    const ourStoryRef = useRef(null);
    const greenVisionRef = useRef(null);
    const whatWeDoRef = useRef(null);
    const ourLeadersRef = useRef(null);
    const nuegoTeamRef = useRef(null);

    // create IntersectionObserver object
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setActiveTab(entry.target.id);
            }
        });
    }, { threshold: 0.5 });

    // observe each section when the component mounts
    useEffect(() => {
        observer.observe(ourStoryRef.current);
        observer.observe(greenVisionRef.current);
        observer.observe(whatWeDoRef.current);
        observer.observe(ourLeadersRef.current);
        observer.observe(nuegoTeamRef.current);
    }, []);

    const handleClick = (value) => {
        setActiveTab(value)
        document.getElementById(value).scrollIntoView({
            behavior: 'smooth',
            top: -100,
        });
        //anchorTarget.scrollIntoView({ behavior: 'smooth',  });
    };








    return (
        <AuthModalLayout>
                <Helmet>
                    <title>NueGo - Book Electric Buses, Check Prices & Routes | About Us</title>
                    <link rel="canonical" href="https://www.nuego.in/about-us" hreflang="en-IN" />
                    <meta name="description" content="Discover NueGo, your go-to platform for booking electric buses. Find NueGo electric bus prices, and make hassle-free bus bookings."/>
                    <meta name="keywords" content="NueGo, Electric buses, Bus bookings, Go Bus service, Bus prices, About NueGo, Booking platform, Transportation services,NueGo India, like Volvo AC Premium Buses"></meta>
                    <meta name="robots" content="index,follow"/>
                </Helmet>  
            <div className={'about-us-page'}>
              

                {isDesktopOrLaptop ?
                    <div>
                        <div style={{ marginBottom: '100px' }}>
                            <Navbar />
                        </div>

                    </div>

                    :
                    <div className={'global-container position-relative p-4'}>
                        <img src={backArrowWhite} alt={'back-arrow'} className={'back-arrow'} onClick={backNavigation} />
                        <h1 className={'open-600w-16s-24h mobile-page-title-text white-color mb-0'}>About NueGo</h1>
                    </div>
                }
                <div className={' px-3'}>
                    <div className={'row banner-section flex-wrap-reverse flex-lg-wrap'}>
                        <div className={'col-12 col-lg-6 d-flex flex-column justify-content-around'}>
                            {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                                <a href="/">
                                <p className={'open-600w-14s-22h grey-2-78787d-color mb-0 cursor-pointer'}>Home</p>
                                </a>
                                <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>About Us</p>
                            </div>}
                            <div className={pageConstant.content_class}>

                                {isDesktopOrLaptop &&
                                    <h1 className={'white-color ubuntu-700w-40s-64h mb-4'}>About NueGo</h1>}
                                <h2 className={pageConstant.subTitle_class}>India’s first Intercity Electric Premium
                                    AC
                                    Coach
                                    service.</h2>
                            </div>

                        </div>
                        <div className={'col-12 col-lg-6 banner-bg'}></div>
                    </div>
                </div>
                <div className={'white-fold  mt-0 ' + "mb-lg-0"}>
                    <div className={'tab-section d-flex justify-content-center align-items-center'}>
                        {
                            tabs.map((tab, index) => {
                                return <Link key={index}
                                    activeClass="active"
                                    to={tab.id}
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={500}
                                >
                                    <div
                                        className={activeTab === tab.id ? "active-tab" : "in-active-tab"}
                                        onClick={() => setActiveTab(tab.id)}
                                    >
                                        <p className={pageConstant.tabText_class}>{tab.title}</p>
                                    </div>

                                </Link>
                            })
                        }

                    </div>
                    <div id={"our_story"} ref={ourStoryRef}>
                        <Welcome />
                    </div>

                    <div id={"green_vision"} ref={greenVisionRef}>
                        <GreenVision />
                    </div>
                    <div id={"what_we_do"} ref={whatWeDoRef}>
                        <NueGoTrivia />
                    </div>
                    <div id={"our_leaders"} ref={ourLeadersRef}>
                        <OurLeader />
                    </div>
                    <div id={"nuego_team"} ref={nuegoTeamRef}>
                        <NuegoTeam />
                    </div>
                </div>
                {/* {isDesktopOrLaptop && <Footer/>} */}
                <Footer />
            </div>
      
        </AuthModalLayout>
    )
}

export default AboutUsPage;