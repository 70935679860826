import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {setDestinationPoint, setSourceAndDestination, setSourcePoint,} from "../../store/Actions/BookingAction";
import {useMediaQuery} from "react-responsive";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import { useNavigate} from "react-router";
import { useSearchParams , Link } from "react-router-dom"
import Loader from "../../components/Loader/Loader";
import {CustomToast} from "../../components/CustomToast/CustomToast"
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import "./NoRoute404.scss"
import norouteImg from "../../assets/images/NoRoute.png"
import InnerSearchbar from "../Home/component/SearchBarRevamp/InnerSearchbar";
import MobileTopBus from "../Home/component/MobileTopBus";


function NoRoute404(props) {


   

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

  return (
    <div className={"no-route"}>
                <Helmet>
                    <title>Page Not Found</title>
                    <link rel="canonical" href="https://www.nuego.in/" hreflang="en-IN" />
                    <meta name="description" content=""/>
                    <meta name="keywords" content=""></meta>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
            {

            isDesktopOrLaptop?<AuthModalLayout>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props}/>
            </AuthModalLayout>
            :
            <MobileSubScreenLayout back={() => navigate("/")} title={"Not Found"}>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props}/>
            </MobileSubScreenLayout>
            }
        </div>
  )
}


export const MainContent = ({isDesktopOrLaptop,props}) => {

    const {
        setSourceAndDestinationToStore,
        sourcePointListFromStore,
        destinationPointListFromStore,
        userDataFromStore,
        filterDataFromStore,
        filterFromStore
    } = props;

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedSearchFromRecent, setSelectedSearchFromRecent] = useState({});
    const [viewCalendar, setViewCalendar] = useState(false);
    const [
        viewCalendarActivatedBeforeScroll,
        setViewCalendarActivatedBeforeScroll,
    ] = useState(false);

   

    
    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-600w-16s-24h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color mt-2",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    const openCalendarForMobile = (args) => {
        document.querySelector(".MobileTopBus").style.position = "relative";
        document.querySelector(".MobileTopBus").style.top = "0";

        setViewCalendar(args);
        setViewCalendarActivatedBeforeScroll(true);
    };


    return (
        <>
            {isDesktopOrLaptop && <Navbar/>}
            {loading?<Loader/> :
            <div className={"main-content"}>
            {isDesktopOrLaptop && <div className="container bread-crumb d-flex">
                      <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                      <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                       <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Page Not Found</p>
                        </div>
            }
                <div className="no-route-container">

                    <div className="page-not-found-message">
                        <div className="page-not-found-message-data">
                            <h1 className="heading-teal">
                                This Page No Longer Exists
                            </h1>
                            <p className="desc">
                            The link you clicked could be broken, or the page may have been removed.
                            </p>
                        </div>
                        <img className="image-no-route" src={norouteImg}/>

                    </div>
                    { isDesktopOrLaptop ?
                    <div className="searchbar-div">
                        <InnerSearchbar
                            navigate={navigate}
                            sourcePointListFromStore={sourcePointListFromStore}
                            destinationPointListFromStore={destinationPointListFromStore}
                            setSourceAndDestinationToStore={(args) =>
                                setSourceAndDestinationToStore(args)
                            }
                            selectedSearchFromRecent={selectedSearchFromRecent}
                            
                            setSelectedSearchFromRecent={(args) =>
                                setSelectedSearchFromRecent(args)
                            }
                            userDataFromStore={userDataFromStore}
                            filterDataFromStore={filterDataFromStore}
                            filterFromStore={filterFromStore}
                            heading="But don’t let that stop you!"
                            />
                    </div> 
                    :
                    <div className="mweb404search">
                        <h2 className="mweb-head">But don’t let that stop you!</h2>
                        <p className="mweb-desc">Continue on your adventure with NueGo</p>
                        <MobileTopBus
                                navigate={navigate}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                                sourcePointListFromStore={sourcePointListFromStore}
                                destinationPointListFromStore={destinationPointListFromStore}
                                setSourceAndDestinationToStore={(args) =>
                                    setSourceAndDestinationToStore(args)
                                }
                               
                                selectedSearchFromRecent={selectedSearchFromRecent}
                               
                                setSelectedSearchFromRecent={(args) =>
                                    setSelectedSearchFromRecent(args)
                                }
                                viewCalendar={viewCalendar}
                                setViewCalendar={(args) => openCalendarForMobile(args)}
                                userDataFromStore={userDataFromStore}
                                filterDataFromStore={filterDataFromStore}
                                filterFromStore={filterFromStore}
                            />
                    </div>
                    }

                </div>

            </div>
            }
            <Footer/>
        </>

    );
};


const mapStateToProps = (state) => ({
    sourcePointFromStore: state.BookingReducer.sourcePoint,
    destinationPointFromStore: state.BookingReducer.destinationPoint,
    sourcePointListFromStore: state.BookingReducer.sourcePointList,
    destinationPointListFromStore: state.BookingReducer.destinationPointList,
    userDataFromStore: state.AuthReducer,
    filterDataFromStore: state.FilterReducer.filter,
    filterFromStore: state.FilterReducer.filter
});
const mapDispatchToProps = (dispatch) => ({
    setSourcePointToStore: (data) => dispatch(setSourcePoint(data)),
    setDestinationPointToStore: (data) => dispatch(setDestinationPoint(data)),
    setSourceAndDestinationToStore: (data) =>
        dispatch(setSourceAndDestination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoRoute404);