import "./Support.scss"
import Navbar from "../../components/Navbar/Navbar";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/Footer/Footer";
import {useEffect} from "react";
import {useNavigate} from "react-router";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";

//assets
import call from "../../assets/icons/call-icon.svg"
import whatsapp from "../../assets/icons/whatsapp.png"
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";


const Support = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    })
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {

        };
    }, []);

    return (
        <AuthModalLayout>
        <div className={'support-page'}>
            {
                isDesktopOrLaptop ?
                    <div>
                        <div style={isDesktopOrLaptop ? {marginBottom: '100px'} : {marginBottom: '78px'}}>
                            <Navbar/>
                        </div>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                        {isDesktopOrLaptop && <Footer/>}
                    </div>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"Support"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                    </MobileSubScreenLayout>
            }

        </div>
        </AuthModalLayout>
    )
}

export const MainContent = ({isDesktopOrLaptop}) => {
    return <div className={'main-content'}>
        {isDesktopOrLaptop && <div className={'title ubuntu-400w-16s-28h'}>
            Support
        </div>}
        <div className={'ubuntu-700w-18s-28h'}>
            <img src={call} alt={'call'} className={'call-icon mr-2'}/>
            Don't like waiting? Call us now
            <p className={'call-number'}>
                <a href={'tel:1800%20267%209001'}>1800 267 9001</a>
            </p>
            <p className={'call-text'}>(Available 24 x 7)</p>
            <p className={'whatsapp-parent'}>
                <img src={whatsapp} alt={'whatsapp'} className={'whatsapp'}/>
                <a href="https://wa.me/+919930000442" target="_blank" className="whatsapp">+91 9930000442</a>
            </p>
        </div>
    </div>
}
export default Support