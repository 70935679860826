import {SET_AMENITIES_FILTER, SET_BOARDING_POINT_FILTER, SET_DROPPING_POINT_FILTER, SET_FILTER_DATA} from "./index";

export const filterDataAction=(data)=>{
	return {
		type: SET_FILTER_DATA,
		payload: {data:data}
	}
}

export const boardingPointFilterAction=(data)=>{
	return {
		type: SET_BOARDING_POINT_FILTER,
		payload: {data:data}
	}
}

export const droppingPointFilterAction=(data)=>{
	return {
		type: SET_DROPPING_POINT_FILTER,
		payload: {data:data}
	}
}

export const amenitiesFilterAction=(data)=>{
	return {
		type: SET_AMENITIES_FILTER,
		payload: {data:data}
	}
}