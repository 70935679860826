import {combineReducers} from 'redux'
import AuthReducer from "./AuthReducer";
import BookingReducer from "./BookingReducer";
import CommonReducer from "./CommonReducer";
import FilterReducer from "./FilterReducer";

export const rootReducer =  combineReducers({
	AuthReducer,
	BookingReducer,
	FilterReducer,
	CommonReducer,
})
