import graphql from "babel-plugin-relay/macro";

const GetCancelPartialDetailsQuery = graphql`
  query GetCancelPartialDetailsQuery($bookingId: ID!, $passengerIds: [String]) {
    partialCancellationDetails(
      bookingId: $bookingId
      passengerIds: $passengerIds
    ) {
      refundAmount
      cancellationFee
      totalFare
      walletAmount
      paymentGatewayAmount
      cancellationCharges
      booking {
        passengerdetailSet {
          id
          name
          age
          gender
        }
      }
    }
  }
`;

export default GetCancelPartialDetailsQuery;
