import React, { useEffect, useState } from 'react'
import './Careers.scss'
import Navbar from "../../components/Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
import Benefits from './components/Benefits';
import Achievement from './components/Achievement';
import Opportunity from './components/Opportunity';
import JobApplication from "./components/JobApplication";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-scroll";

//icons
import backArrowWhite from "../../assets/icons/back-white-arrow.svg"
import { Helmet } from 'react-helmet';
import AuthModalLayout from '../../layout/AuthModalLayout/AuthModalLayout';
//assets
const careerBanner1 = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner1.png`
const careerBanner1Small = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner1-small.png`
const careerBanner2 = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner2.png`
const careerBanner2Small = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner2-small.png`
const careerBanner3 = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner3.png`
const careerBanner3Small = process.env.REACT_APP_CDN_LINK + `assets/images/careerBanner3-small.png`

const Careers = () => {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
    let pageConstants
    const [apply, setApply] = useState('');


    useEffect(() => {
        return () => {

        };
    }, [apply]);

    if (isDesktopOrLaptop) {
        pageConstants = {
            content_class: "ubuntu-700w-40s-64h white-color margin-bottom-32 ",
            subTitle: "open-400w-18s-28h grey-3-adadad-color mb-5",
        }
    } else {
        pageConstants = {
            content_class: "ubuntu-700w-24s-36h white-color mb-3 ",
            subTitle: "open-400w-14s-22h grey-3-adadad-color  margin-bottom-32",
        }
    }

    const backNavigation = () => {

    }

    return (
        <div>
            <Helmet>
                <title>Join the NueGo Team: Empowering Innovation and Creating a Sustainable Future</title>
                <link rel="canonical" href="https://www.nuego.in/careers" hreflang="en-IN" />
                <meta name="description" content="Discover exciting career opportunities at NueGo! Join our dynamic team dedicated to innovation, sustainability, and making a positive impact." />
                <meta name="keywords" content="NueGo Careers, Career opportunities, Join our team, Innovation, Sustainability, Positive impact, Dynamic team, Jobs at NueGo, Empowering careers,Join NueGo"></meta>
                <meta name="robots" content="index,follow"/>
            </Helmet>
            <AuthModalLayout>
        <div className={'career-page '}>
                {isDesktopOrLaptop ?
                    <div style={{ marginBottom: '100px' }}>
                        <Navbar />
                    </div>
                    :
                    <div className={'global-container position-relative p-4'}>
                        <img src={backArrowWhite} alt={'back-arrow'} className={'back-arrow'} onClick={backNavigation} />
                        <p className={'open-600w-16s-24h mobile-page-title-text white-color mb-0'}>Careers</p>
                    </div>
                }

                {/*	 banner section */}
                <div className={'banner-section mb-lg-4'}>
                    <div className={'d-lg-flex justify-content-lg-between'}>
                        <div className=" d-flex justify-content-center">
                            <div>
                                <div className="bread-crumb d-flex">
                                    <a href="/">
                                    <p className={'open-600w-14s-22h grey-2-78787d-color mb-0 cursor-pointer'}>Home</p>
                                    </a>
                                    <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                                    <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Careers</p>
                                </div>
                                <h1 className={pageConstants.content_class}>
                                    Start your Career <br />
                                    with us. Explore your next <br />
                                    Destination
                                </h1>
                                <h2 className={pageConstants.subTitle}>
                                    We are always hiring great talent at NueGo. Join our team to learn & grow with us.
                                </h2>
                                <Link
                                    activeClass="active"
                                    to={"job-application"}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    <button className={'submit-button white-color open-600w-18s-28h'}>Explore Jobs</button>
                                </Link>
                            </div>

                        </div>
                        <div className=" d-flex justify-content-center">
                            {
                                isDesktopOrLaptop ?
                                    <div className={'d-flex align-items-center gap-24 py-4'}>
                                        <div>
                                            <img src={careerBanner1} alt={'career-banner'} className={'career-banner-1'} />
                                        </div>
                                        <div className={'d-flex flex-column gap-24'}>
                                            <img src={careerBanner2} alt={'career-banner'} className={'career-banner-2'} />
                                            <img src={careerBanner3} alt={'career-banner'} className={'career-banner-3'} />
                                        </div>
                                    </div>
                                    :
                                    <div className={'my-4'}>
                                        <img src={careerBanner2Small} alt={'career-banner'}
                                            className={'career-banner-2 mb-3'} />
                                        <div className={'d-flex gap-12'}>
                                            <img src={careerBanner1Small} alt={'career-banner'}
                                                className={'career-banner-1'} />
                                            <img src={careerBanner3Small} alt={'career-banner'}
                                                className={'career-banner-3'} />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className={'white-fold ' + "mb-lg-0"}>
                    <Achievement/>
                    <Benefits />
                    <Opportunity setApply={args => setApply(args)} />
                    {apply && <JobApplication applyFor={apply} />}
                </div>

                {/* {isDesktopOrLaptop && <Footer/>} */}
                <Footer />
            </div>
            </AuthModalLayout>
        </div>
    )
}

export default Careers
