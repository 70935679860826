import React from 'react'
//assets
const LoadingAnimation = process.env.REACT_APP_CDN_LINK + `assets/images/Loading_Amination-white.gif`


const Loader = () => {
    return (
        <div style={loaderStyle} className={'d-flex justify-content-center align-items-center'}>
            {/* <p className={'ubuntu-700w-24s-32h'}>Loading ... </p> */}
            <img src={LoadingAnimation} alt="loading"/>
        </div>
    )
}

const loaderStyle = {
    height: '50vh',
    width: '100%'

}

export default Loader
