import React, {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import '../PartnerWithUs.scss'
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../components/CustomToast/CustomToast";
import {PartnerWithUsData} from "../../../services/api/DataUpdating";
// icons
import attachment from "../../../assets/icons/attachment.svg"
// assets
const getInTouchImage = process.env.REACT_APP_CDN_LINK + `assets/images/getInTouchImage.png`


//
const NationalityListSample = [
    "Indian",
    "American",
    "Bangladeshi",
    "Brazilian",
    "British",
    "Canadian",
    "Chinese",
    "French",
    "German",
    "Japanese",
]

const GetInTouch = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const inputBoxDataOne = [
        {
            name: "Organization Name *",
            placeholderName: "Enter organization name",
            type: "text",
        },
        {
            name: "Website URL",
            placeholderName: "Enter website name",
            type: "text",
        },
    ];
    const inputBoxDataTwo = [
        {
            name: "Your Name *",
            placeholderName: "Enter your name",
            type: "text",
        },
        {
            name: "Email",
            placeholderName: "Enter your email",
            type: "email",
        },
    ];

    let componentConstant
    if (isDesktopOrLaptop) {
        componentConstant = {
            heading: "ubuntu-700w-32s-48h black-1E1E26-color mb-3",
            subHeading: "open-400w-16s-24h grey-3-adadad-color mb-5",
            illustration: "partnerApplication-illustration margin-bottom-32",
            submitButton: "open-600w-18s-28h white-color submit-button",
            inputText_class: "open-600w-18s-28h grey-3-adadad-color "
        }
    } else {
        componentConstant = {
            heading: "ubuntu-500w-18s-28h black-1E1E26-color mb-3",
            subHeading: "open-400w-14s-22h grey-3-adadad-color mb-0",
            illustration: "partnerApplication-illustration mb-3",
            submitButton: "open-600w-16s-24h white-color submit-button",
            inputText_class: "open-400w-16s-22h grey-3-adadad-color "
        }
    }

    const [partnerDetailData_local, setpartnerDetailData_local] = useState({});
    const [error, setError] = useState({
        organizationName: false,
        website: false,
        name: false,
        email: false,
        mobileNumber: false
    });
    const [touched, setTouched] = useState([]);
    const [document, setDocument] = useState(null);
    const [countryCode, setCountryCode] = useState("+91")
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showNationality, setShowNationality] = useState(false);

    // useEffect
    useEffect(() => {

        let tempObj = {
            organizationName: "",
            website: "",
            name: "",
            email: "",
            Nationality: NationalityListSample[0],
            mobileNumber: "",

        }
        setpartnerDetailData_local(tempObj)
    }, [])

    // methods
    const onChangeHandler = (e, field) => {

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }

        if (field === 'name' || field === 'organizationName') {

            if (e.target.value === "") {
                setError({...error, [field]: 'error'})
                setpartnerDetailData_local({...partnerDetailData_local, [field]: e.target.value})
            } else {
                if (e.target.value.match(/\d+/g) === null) {

                    setError({...error, [field]: null})
                    setpartnerDetailData_local({...partnerDetailData_local, [field]: e.target.value})
                }
            }

        }

        if (field === "website") {
            setpartnerDetailData_local({...partnerDetailData_local, [field]: e.target.value})
        }

        if (field === "email") {
            setpartnerDetailData_local({...partnerDetailData_local, [field]: e.target.value})
            if (e.target.value.match(emailRegex) == null) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})

            }
        }

        if (field === "mobileNumber") {
            if (e.target.value.length < 11) {
                setpartnerDetailData_local({...partnerDetailData_local, [field]: e.target.value})
            }

        }

    }

    const onSubmitHandler = () => {
        setIsSubmitting(true)
        let errorLength = Object.values(error).filter((item) => item === 'error').length;

        if (touched.length === 0) {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please update any field to save changes"}
                heading={"Error"}
            />, {position: 'top-center'})
        } else if (errorLength !== 0) {
            cogoToast.error(<CustomToast
                type={"error"} message={"Please update the highlighted fields"}
                heading={"Error"}
            />, {position: 'top-center'})
        }

        if (touched.length !== 0 && errorLength === 0) {
            // debugger
            let variables = {
                name: partnerDetailData_local.name,
                email: partnerDetailData_local.email,
                mobileNumber: partnerDetailData_local.mobileNumber,
                organizationName: partnerDetailData_local.organizationName,
                website: partnerDetailData_local.website,
                Nationality: partnerDetailData_local.Nationality,
                document: document,
                countryCode: countryCode
            }

            if (document.size > 2097152) {
                cogoToast.error(<CustomToast
                    type={"error"} message={"File size should not be greater than 2 MB"}
                    heading={"Error"}
                />, {position: 'top-center'})
                setIsSubmitting(false)

            } else {
                PartnerWithUsData(variables, (res) => {

                    cogoToast.success(<CustomToast
                        type={"success"} message={"Your application has been submitted successfully"}
                        heading={"Success"}
                    />, {position: 'top-center'})

                    let tempObj = {
                        organizationName: "",
                        website: "",
                        name: "",
                        email: "",
                        Nationality: NationalityListSample[0],
                        mobileNumber: "",

                    }
                    setIsSubmitting(false)
                    setDocument(null)
                    setTouched([])
                    setpartnerDetailData_local(tempObj)

                }, (err) => {
                    setIsSubmitting(false)
                    cogoToast.error(<CustomToast
                        type={"error"} message={err}
                        heading={"Error"}
                    />, {position: 'top-center'})
                })
            }

        }

    }

    const handleTouch = (field) => {

        if (!touched.includes(field)) {
            setTouched([...touched, field])
        }

        if (field == "nationality") {

            setShowNationality(!showNationality)
        }
    }

    const handleBlur = (field) => {
        if (field === "mobileNumber") {
            if (partnerDetailData_local.mobileNumber.length !== 10) {
                setError({...error, [field]: 'error'})
            } else {
                setError({...error, [field]: null})
            }
        }
        if (touched.includes(field)) {
            if (partnerDetailData_local[field] === "") setError({...error, [field]: 'error'})
        }
    }

    const handleDocument = (e) => {
        setDocument(e.target.files[0])
    }

    const handleNationality = (item) => {
        setpartnerDetailData_local({...partnerDetailData_local, Nationality: item})
        setTimeout(() => {
                setShowNationality(false)
            }
            , 100)

    }

    return (
        <div className="get-in-touch px-3 py-5">
            <div className="d-flex  align-items-center flex-column">
                <img
                    className="get-in-touch-image"
                    src={getInTouchImage}
                    alt="alt"
                ></img>
                <p className="get-in-touch-title pt-3">
                    Thank you for your interest in us!
                </p>
                <p className="get-in-touch-description">
                    Please fill the below details to get in touch with us
                </p>
            </div>

            <div className={'partner-application-form mx-auto'} id={'partner-application-form'}>
                <div className={'d-lg-flex w-100 gap-24 mb-4'}>
                    <div className={'w-100'}>
                        <label>Organization Name *</label>
                        <div
                            className={'common-input-wrap input-name ' + (error.organizationName === "error" && "common-input-wrap-error")}
                            onClick={() => handleTouch("organizationName")}>
                            <input className="border-less-input w-100"
                                   value={partnerDetailData_local.organizationName}
                                   placeholder={'Enter your Organization Name'}
                                   onBlur={() => handleBlur("organizationName")}
                                   onChange={(e) => {
                                       // case 1 a
                                       onChangeHandler(e, "organizationName")
                                   }}
                                   type={"text"}/>
                        </div>
                        {error.organizationName === "error" && <div>
                            <p className={""}>Invalid Organization Name</p>
                        </div>}
                    </div>

                    <div className={'w-100'}>
                        <label>Website URL</label>
                        <div
                            className={'common-input-wrap input-name '}
                            // onClick={() => handleTouch("website")}
                        >
                            <input className="border-less-input w-100"
                                   value={partnerDetailData_local.website}
                                   placeholder={'Enter your Website URL'}
                                   onBlur={() => handleBlur("website")}
                                   onChange={(e) => {
                                       // case 1 a
                                       onChangeHandler(e, "website")
                                   }}
                                   type={"text"}/>
                        </div>

                    </div>

                </div>
                <div className={'d-lg-flex w-100 gap-24 mb-4'}>
                    <div className={'w-100'}>
                        <label>Name *</label>
                        <div
                            className={'common-input-wrap input-name ' + (error.name === "error" && "common-input-wrap-error")}
                            onClick={() => handleTouch("name")}>
                            <input className="border-less-input w-100"
                                   value={partnerDetailData_local.name}
                                   placeholder={'Enter your name'}
                                   onBlur={() => handleBlur("name")}
                                   onChange={(e) => {
                                       // case 1 a
                                       onChangeHandler(e, "name")
                                   }}
                                   type={"text"}/>
                        </div>
                        {error.name === "error" && <div>
                            <p className={""}>Invalid Name</p>
                        </div>}
                    </div>

                    <div className={'w-100'}>
                        <label>Email *</label>
                        <div onClick={() => handleTouch("email")}
                             className={'common-input-wrap ' + (error.email === "error" && 'common-input-wrap-error')}>
                            <input className="border-less-input w-100"
                                   type={"email"}
                                   onBlur={() => handleBlur("email")}
                                // value={personalDetailData_local.email}
                                   onChange={(e) => onChangeHandler(e, "email")}
                            />

                        </div>
                        {error.email === "error" && <div>
                            <p className={''}>Invalid Email ID</p>
                        </div>}
                    </div>

                </div>

                <div className={'d-lg-flex w-100 gap-24'}>
                    <div className={'w-100'}>
                        <label>Nationality</label>
                        <div
                            className={'common-input-wrap input-name position-relative overflow-visible ' + (error.nationality === "error" && "common-input-wrap-error")}
                            onClick={() => handleTouch("nationality")}>

                            {/*<select onChange={(e) => onChangeHandler(e, "nationality")} className={'w-100'}>*/}
                            {/*	{*/}
                            {/*		NationalityListSample.map((item, index) => {*/}
                            {/*			return <option key={index} value={item}>{item}</option>*/}
                            {/*		})*/}
                            {/*	}*/}
                            {/*</select>*/}

                            <input readOnly={true} value={partnerDetailData_local.Nationality}/>

                            {showNationality && <div className={'nationality-suggestions'}>
                                {
                                    NationalityListSample.map((item, index) => {
                                        return <p key={index} className={componentConstant.inputText_class
                                            + (partnerDetailData_local.Nationality === item &&
                                                "active-tab")}
                                                  onClick={() => handleNationality(item)}>{item}</p>
                                    })
                                }
                            </div>}

                        </div>

                    </div>

                    <div className={'w-100'}>
                        <label>Upload Document *</label>
                        <div
                            className={'d-flex justify-content-between align-items-center common-input-wrap position-relative mb-4 w-100 '}>
                            <input type={'file'} className={'input-box w-100 upload-button'}
                                   placeholder={'choose file'}
                                   onChange={e => handleDocument(e)}
                            />
                            <p className={'my-0 ' + (document?.name ? " black-1E1E26-color " : "")}>{document?.name ? document.name : "Choose File"}</p>
                            <img src={attachment} alt={'attachment'} className={'icon-24'}/>
                        </div>
                    </div>

                </div>
                <div className={'d-flex gap-24 w-lg-50'}>
                    <div>
                        <label>Code</label>
                        <div className={'d-flex common-input-wrap align-items-center mb-4 w-100 '}>
                            <select className={'input-box '}
                                    onChange={e => setCountryCode(e.target.value)}>
                                <option value={"+91"}>+91</option>
                                <option value={"+92"}>+92</option>
                            </select>
                        </div>
                    </div>
                    <div className={'w-100'}>
                        <label>Mobile Number *</label>
                        <div onClick={() => handleTouch("mobileNumber")}
                             className={'common-input-wrap ' + (error.mobileNumber === "error" && 'common-input-wrap-error')}>
                            <input
                                min="0"
                                className="border-less-input w-100 "
                                type={"text"}
                                // pattern="[0-9]{10}
                                value={partnerDetailData_local.mobileNumber}
                                onKeyPress={(e) => e.target.value.length === 10 ? e.preventDefault() : null}
                                onBlur={() => handleBlur("mobileNumber")}
                                onChange={(e) => onChangeHandler(e, "mobileNumber")}
                            />

                        </div>
                        {error.mobileNumber === "error" && <div>
                            <p className={''}>Invalid Mobile Number</p>
                        </div>}
                    </div>

                </div>

                {/*<div className={'text-center'}>*/}
                {/*	<button className={componentConstant.submitButton} onClick={onSubmitHandler}>Submit</button>*/}
                {/*</div>*/}

                <div className={isDesktopOrLaptop ? "text-center pt-5" : "pt-3"}>
                    <button className="submit-button " onClick={onSubmitHandler}
                            disabled={!document ||
                                Object.values(error).filter((item) => item === "error").length > 0 ||
                                !touched.includes("mobileNumber") ||
                                !touched.includes("email") || !touched.includes("organizationName") ||
                                !touched.includes("name") || isSubmitting}
                    >Partner with Us
                    </button>
                </div>
            </div>

        </div>
    );
};

export default GetInTouch;
