import {commitMutation} from 'react-relay';
import graphql from "babel-plugin-relay/macro";
import environment from '../../environment';

const mutation = graphql`
	mutation VerifyOtpMutation($otp:String!,$mobileNumber:String!){
		verifyOtp(otp:$otp,mobileNumber:$mobileNumber){
			status
			user {
			    id
			    lastLogin
			    mobileNumber
			    email
			    firstName
			    lastName
			    jti
			    username
			    profile{
			      id
			      profilePic
			      walletAmount
			      greenMilesAmount
			      profilePicUrl
			    }
			}
			token
			refreshToken
		}
	}
	`

export default (data, callback, errCallback) => {
	const variables = {
		otp: data.otp,
		mobileNumber: data.mobileNumber
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.verifyOtp !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			}
		}
	)
}