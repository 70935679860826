import React from 'react'
import { Link } from 'react-router-dom'
import './Banner.scss'

function Banner() {
  return (
    <div className="k2k-Banner">
                    <div className="main-container">
                    <div className="bread-crumb">
                                <Link to="/">
                                <span className="cursor-pointer">Home / </span>
                                </Link>
                                <span className="active">Kashmir to Kanyakumari</span>
                            </div>
                        <div className="left-part">
                            <h1>Kashmir to Kanyakumari with <br /> NueGo: Routes, Cities & Stories</h1>
                        </div>
                        <div className="right-part">
                        {/* {<img src={'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/bannerbg.png'} alt="All NueGo Destination Routes" className="w-100"/> } */}

                        </div>
                    </div>

                </div>
  )
}

export default Banner