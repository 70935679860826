import {useMediaQuery} from "react-responsive";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./BookingState.scss";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";

//assets
const completeTripImage = process.env.REACT_APP_CDN_LINK + `assets/images/completetrip.svg`
const upcomingTripImage = process.env.REACT_APP_CDN_LINK + `assets/images/upcoming.png`

const BookingState = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <AuthModalLayout>
        <div className={"booking-state-page"}>
            {isDesktopOrLaptop ? (
                <div>
                    <div
                        style={
                            isDesktopOrLaptop
                                ? {marginBottom: "100px"}
                                : {marginBottom: "78px"}
                        }
                    >
                        <Navbar/>
                    </div>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                    {isDesktopOrLaptop && <Footer/>}
                </div>
            ) : (
                <MobileSubScreenLayout back={() => navigate(-1)} title={"FAQs"}>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                </MobileSubScreenLayout>
            )}
        </div>
        </AuthModalLayout>
    );
};

export const MainContent = ({isDesktopOrLaptop}) => {
    const [activeTrip, setActiveTrip] = useState(1);
    const BookingTripData = [
        {
            id: 1,
            image: completeTripImage,
            name: "Uhoh!",
            description: "Looks like you do not have any trips",
            mobileHeadng: "Completed",
            desktopHeading: "Completed Trips",
        },
        {
            id: 2,
            image: upcomingTripImage,
            name: "Uhoh!",
            description: "You do not have any upcoming trips",
            mobileHeadng: "Upcoming",
            desktopHeading: "Upcoming Trips",
        },
        {
            id: 3,
            image: completeTripImage,
            name: "Uhoh!",
            description: "You do not have any cancelled trips",
            mobileHeadng: "Cancelled",
            desktopHeading: "Cancelled Trips",
        },
    ];
    return (
        <div className={"main-content"}>
            {!isDesktopOrLaptop && (
                <div>
                    <div className="row bookig-trip-state ">
                        {BookingTripData.map((data, index) => (
                            <div
                                key={index}
                                onClick={() => setActiveTrip(data.id)}
                                className={
                                    activeTrip === data.id
                                        ? "col-4 text-center active-trip"
                                        : "col-4 text-center inactive-trip"
                                }
                            >
                                {data.mobileHeadng}
                            </div>
                        ))}
                    </div>
                    {BookingTripData.map((data, index) => (
                        <div>
                            {activeTrip === data.id && (
                                <div className="px-3 text-center booking-trip-part">
                                    <img src={data.image} alt={"alt"}/>
                                    <h2>{data.name}</h2>
                                    <p>{data.description}</p>
                                </div>
                            )}
                        </div>
                    ))}
                    <div className="d-flex justify-content-center px-3">
                        <button>Book a Trip</button>
                    </div>
                </div>
            )}
            {isDesktopOrLaptop && (
                <div style={{gap: "287px"}} className="d-flex">
                    <div className="desktop-booking-trip">
                        <h1>My Bookings</h1>
                        <div className="pt-4">
                            {BookingTripData.map((data, index) => (
                                <div
                                    key={index}
                                    onClick={() => setActiveTrip(data.id)}
                                    className={
                                        activeTrip === data.id
                                            ? " active-trip text-nowrap"
                                            : " inactive-trip text-nowrap"
                                    }
                                >
                                    {data.desktopHeading}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        {BookingTripData.map((data, index) => (
                            <div key={index}>
                                {activeTrip === data.id && (
                                    <div className="px-3  booking-trip-part">
                                        <img src={data.image} alt={"alt"}/>
                                        <h2>{data.name}</h2>
                                        <p>{data.description}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div className="d-flex justify-content-center ">
                            <button className="text-nowrap">Book a Trip</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default BookingState;
