import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";
import environment from "../../environment";


const mutation = graphql`
	mutation GoogleAuthMutation($googleToken: String!) {
		googleAuth(accessToken:$googleToken) {
				token
				refreshToken
				user{
					firstName
					lastName
					email
					mobileNumber
				}
		}
	}
`;

export default (data, callback, errCallback) => {
	const variables = {
		accessToken: data.accessToken,
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.googleAuth !== null) {

					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},

			onError: err => {
				errCallback(err);
			}
		}
	);
}
