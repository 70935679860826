import React, {useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import { useNavigate} from "react-router";
import { useSearchParams , Link } from "react-router-dom"
import Loader from "../../components/Loader/Loader";
import {CustomToast} from "../../components/CustomToast/CustomToast"
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import "./Sitemap.scss"
import PopularSearches from "../Home/component/PopularSearches/PopularSearches";

function Sitemap() {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

  return (
    <div className={"sitemap"}>
                <Helmet>
                    <title>NueGo Sitemap</title>
                    <link rel="canonical" href="https://www.nuego.in/sitemap" hreflang="en-IN" />
                    <meta name="description" content="Explore NueGo's bus booking travel services with our comprehensive sitemap. Easily navigate through routes, booking options and travel information to plan your perfect journey with NueGo."/>
                    <meta name="keywords" content="NueGo sitemap,NueGo all routes,magazine,blog,popular searches,new routes"></meta>
                    <meta name="robots" content="index,follow"/>
                </Helmet>
            {

            isDesktopOrLaptop?<AuthModalLayout>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </AuthModalLayout>
            :
            <MobileSubScreenLayout back={() => navigate("/")} title={"NueGo Sitemap"}>
                <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
            </MobileSubScreenLayout>
            }
        </div>
  )
}

export const MainContent = ({isDesktopOrLaptop}) => {

    const [loading, setLoading] = useState(false);
   

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-600w-16s-24h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color mt-2",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    const NuegoTravel= [
          {
      
      'item': 'Home',
      'route': "/"
    }, 
    {
      
      'item': 'All Routes',
      'route': "/all-routes"
    }, 
    {
      "item": "Partner With Us",
      'route': "/partner-with-us"
    },
    {
      
      'item': 'Offers',
      'route': "/bus-booking-offers"
    }, 
    {
      "item": "Lounge",
      'route': "/travel-lounge"
    },
    
        
    ]
const MoreOnNuego= [
      {
  
  'item': 'About Us',
  'route': "/about-us"
}, 
{
  
  'item': 'Careers',
  'route': "/careers"
}, 
{
  "item": "Newsroom",
  'route': "/news"
},
{
  
  'item': 'Environment',
  'route': "/environment"
}, 
{
  "item": "Magazine",
  'route': "/travel-itineraries"

},
{
  "item": "Blog",
  'route': "/blog"
},

    
]


const HelpCenter = [

  {
  
    'item': 'Support',
    'route': "/call"
  }, 
  {
    
    'item': 'FAQs',
    'route': "/faq"
  }, 
 

]

const NueGoBlogs = [

  {
  
    'item': '10 Places to Visit in India in August To Enjoy Rainy Weather 2024',
    'route': "/blog/best-places-to-visit-in-August"

  }, 
  {
    
    'item': '25 Best Places To Visit This Summer Vacation In India',
    'route': "/blog/places-to-visit-in-summer"

  }, 
  {
    
    'item': ' Complete Guide To Celebrate Holi In Mathura-Vrindavan',
    'route': "/blog/guide-to-celebrate-holi-in-mathura-vrindavan"
  },
  {
    
    'item': 'March Long Weekend 2024: 7 Places You Must Visit',
    'route': "/blog/march-long-weekend-trip-ideas"
  },
  {
    
    'item': 'Top 20 Solo Travel Destinations For Women | NueGo',
    'route': "/blog/solo-travel-destinations-for-women"
  },
  {
    
    'item': 'Friendship Day Getaways: 30 Best Places to Visit with Your Friends',
    'route': "/blog/travel-places-to-visit-with-friends"
  },
]

const NewRoutes = [

  {
  
    'item': 'Vijayawada',
    'route': "/all-routes?Vijayawada"
  }, 
  {
    
    'item': 'Delhi',
    'route': "/all-routes?Delhi"
  }, 
  {
    
    'item': 'Ujjain',
    'route': "/all-routes?Ujjain"
  },
  {
    
    'item': 'Chennai',
    'route': "/all-routes?Chennai"
  },
  {
    
    'item': 'Shimla',
    'route': "/all-routes?Shimla"
  },
  {
    
    'item': 'Chandigarh',
    'route': "/all-routes?Chandigarh"
  },
  {
    
    'item': 'Tirupati',
    'route': "/all-routes?Tirupati"
  },
  {
    
    'item': 'Hyderabad',
    'route': "/all-routes?Hyderabad"
    
  },
  {
    
    'item': 'Jaipur',
    'route': "/all-routes?Jaipur"
  },
 

]
    










   

 

    return (
        <>
            {isDesktopOrLaptop && <Navbar/>}
            {loading?<Loader/> :
            <div className={"main-content"}>
            {isDesktopOrLaptop && <div className=" container bread-crumb d-flex">
                      <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                      <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                       <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Sitemap</p>
                        </div>
            }
                <divc className="sitemap-container">

                    <DisplayCard popularRoutes={NuegoTravel} titleCard={'Travel With NueGo'} />
                    <DisplayCard popularRoutes={MoreOnNuego} titleCard={'More On NueGo'} />
                    <DisplayCard popularRoutes={HelpCenter} titleCard={'Help Center'} />
                    <DisplayCard2 popularRoutes={NueGoBlogs} titleCard={'NueGo Blogs'} />
                    <PopularSearches2/>
                    <DisplayCard popularRoutes={NewRoutes} titleCard={'New Routes'} />

                    


                </divc>

            </div>
            }
            {/* <Footer/> */}
        </>

    );
};

export const DisplayCard = ({popularRoutes,titleCard})=>{

  const renderRoutes = () => {
    const columns = [[], [], []];
  
    popularRoutes.forEach((route, index) => {
      columns[index % 3].push(
        <div key={route.id} className="routeItem">
    {/* Wrap the destination text with Link */}
    <Link
              className="allBusLink available-routes"
              state={route}
               to={`${route?.route}`}
              rel='follow'
            >
              <p 
              //onClick={()=>handleCoachDetails(route)}
              >{`${route.item}`}</p>
            </Link>
        </div>
      );
    });
  
    return columns.map((column, index) => (
      <div key={index} className={`column${index + 1}`}>
        {column}
      </div>
    ));
  };

  return (
    <div className='popular-searc'>
                    <div className='cont'>
                        <div className='subcont'>
                        <div className='subcont1'>
                        <div className='svgicon'>
                        {/* <img src={Group} /> */}
                        </div>
                        <div className='mainbox1'>
                        <p>{titleCard}</p>
                        <div className='routs1'>
                        {renderRoutes()}
                        </div>
                        </div>
                    
                        </div>
                        </div>

                    </div>
                    </div>
  )

}

export const DisplayCard2 = ({popularRoutes,titleCard})=>{

  const renderRoutes = () => {
    const columns = [[], []];
  
    popularRoutes.forEach((route, index) => {
      columns[index % 2].push(
        <div key={route.id} className="routeItem">
    {/* Wrap the destination text with Link */}
    <Link
              className="allBusLink available-routes"
              state={route}
              to={`${route?.route}`}
              rel='follow'
            >
              <p 
              //onClick={()=>handleCoachDetails(route)}
              >{`${route.item}`}</p>
            </Link>
        </div>
      );
    });
  
    return columns.map((column, index) => (
      <div key={index} className={`column${index + 4}`}>
        {column}
      </div>
    ));
  };

  return (
    <div className='popular-searc'>
                    <div className='cont'>
                        <div className='subcont'>
                        <div className='subcont1'>
                        <div className='svgicon'>
                        {/* <img src={Group} /> */}
                        </div>
                        <div className='mainbox1'>
                        <p>{titleCard}</p>
                        <div className='routs2'>
                        {renderRoutes()}
                        </div>
                        </div>
                    
                        </div>
                        </div>

                    </div>
                    </div>
  )

}

export function PopularSearches2() {


  const popularRoutes= [
    {
        "id": 1,
        "source": {
            "id": 672,
            "cityName": "Delhi",
            "cmCityId": "2242"
        },
        "destination": {
          "id": 693,
          "cityName": "Chandigarh",
          "cmCityId": "3404"
      }
    },    {
      "id": 2,
      "source": {
          "id": 673,
          "cityName": "Bangalore",
          "cmCityId": "2318"
      },
      "destination": {
        "id": 693,
        "cityName": "Tirupati",
        "cmCityId": "235"
    }
  },    {
    "id": 3,
    "source": {
        "id": 674,
        "cityName": "Delhi",
        "cmCityId": "2242"
    },
    "destination": {
      "id": 694,
      "cityName": "Jaipur",
      "cmCityId": "983"
  }
},    {
  "id": 4,
  "source": {
      "id": 675,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 695,
    "cityName": "Rishikesh",
    "cmCityId": "1932"
}
},    {
  "id": 5,
  "source": {
      "id": 676,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 696,
    "cityName": "Dehradun",
    "cmCityId": "3400"
}
},    {
  "id": 6,
  "source": {
      "id": 677,
      "cityName": "Indore",
      "cmCityId": "2245"
  },
  "destination": {
    "id": 697,
    "cityName": "Bhopal",
    "cmCityId": "1373"
}
},  {
  "id": 7,
  "source": {
      "id": 678,
      "cityName": "Bangalore",
      "cmCityId": "2318"
  },
  "destination": {
    "id": 698,
    "cityName": "Chennai",
    "cmCityId": "1061"
}
},  {
  "id": 8,
  "source": {
      "id": 679,
      "cityName": "Delhi",
      "cmCityId": "2242"
  },
  "destination": {
    "id": 699,
    "cityName": "Agra",
    "cmCityId": "1458"
}
},  {
  "id": 9,
  "source": {
      "id": 680,
      "cityName": "Chennai",
      "cmCityId": "1061"
  },
  "destination": {
    "id": 700,
    "cityName": "Tirupati",
    "cmCityId": "235"
}
},  {
  "id": 10,
  "source": {
      "id": 681,
      "cityName": "Chennai",
      "cmCityId": "1061"
  },
  "destination": {
    "id": 701,
    "cityName": "Pondicherry",
    "cmCityId": "5366"
}
}, 
{
  "id": 11,
  "source": {
      "id": 682,
      "cityName": "Hyderabad",
      "cmCityId": "7298"
  },
  "destination": {
    "id": 702,
    "cityName": "Vijayawada",
    "cmCityId": "238"
}
}, 
{
  "id": 12,
  "source": {
      "id": 683,
      "cityName": "Jaipur",
      "cmCityId": "983"
  },
  "destination": {
    "id": 703,
    "cityName": "Agra",
    "cmCityId": "1458"
}
},

    
]



const renderRoutes = () => {
  const columns = [[], [], []];

  popularRoutes.forEach((route, index) => {
    columns[index % 3].push(
      <div key={route.id} className="routeItem">
  {/* Wrap the destination text with Link */}
  <Link
            className="allBusLink available-routes"
            state={route}
            to={`/all-routes/bus/${route?.source.cityName}-to-${route?.destination.cityName}`}
            rel='canonical'
          >
            <p 
            //onClick={()=>handleCoachDetails(route)}
            >{`${route.source.cityName} to ${route.destination.cityName} Bus`}</p>
          </Link>
      </div>
    );
  });

  return columns.map((column, index) => (
    <div key={index} className={`column${index + 1}`}>
      {column}
    </div>
  ));
};

  return (
    <div className='popular-search2'>
    <div className='cont'>
        <div className='subcont'>
         <div className='subcont1'>
         <div className='mainbox1'>
          <p className="m-auto">Popular Searches</p>
          <div className='routs1'>
          {renderRoutes()}
          </div>
         </div>
       
         </div>
        </div>

    </div>
    </div>
  )
}




export default Sitemap