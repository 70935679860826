import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import ReactMarkdown from "react-markdown";
import { Helmet } from 'react-helmet';
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import RecommendedBlogs from "../BlogLandingPage/Components/RecommendedBlogs/RecommendedBlogs";
import ExtraBlogPart from "../BlogLandingPage/Components/ExtraBlogPart";
// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import "../Blog.scss"

function WeekendendGetAwaysfromBlr() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();



    return (
        <div className={"specific-blog-Page"}>
            <Helmet>
                <title>Best Short Weekend Getaway from Bangalore in 2024 | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog/weekend-getaways-from-bangalore" hreflang="en-IN" />
                <meta name="description" content=" Explore the best weekend getaways near Bangalore in 2024. Discover short trips, perfect weekend outings, and scenic destinations around Bangalore. Plan your escape now!"/>
                <meta name="keywords" content=""></meta>
                <meta name="robots" content="index,follow" />
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>
    );
};

export const MainContent = () => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const [loading, setLoading] = useState(false);



    const dataContent=[
        {
         
        title:"Weekend Getaways from Bangalore: Short Getaway Places Near Bangalore in 2024",
        img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Banglore.jpg",
        imgAlt:"Best Places to Visit in India During August",
        desc: 

`Bangalore is a beautiful place and living there is a privilege despite what anyone will tell you about a bustling city being overcrowded. Bangalore has facilities that not many other places in India can boast of. However, we wouldn’t hold it against you if you wanted to step out of Bangalore on the weekend. Frankly, after you are done visiting the tourist hotspots of Bangalore, we would totally recommend that you visit its surroundings, because a lot of weekend destinations around Bangalore are bound to mesmerize you equally, if not, more outside the Silicon City of India as there are so many weekend getaways from Bangalore that are totally worth the visit.
\n\n
## Bangalore - A Summary
\n\n
### Top Attractions around Bangalore
Mysore, Badami, Gokarna, Coorg, Udupi, Belur.
### Most Interesting Things to do around Bangalore
Visit palace towns like Mysore, temple towns like Gokarna, and historical places like Belur and Halebidu. Also, go trekking on several trails.
### The Most Popular Thing About the surroundings of Bangalore
Mysore
### Best Time to Visit places around Bangalore
October to February
### Top Local Foods to Try around Bangalore
Davangere Benne Dose, Masala Dose, Bisi Bele Bath, Rava Idli, Mangalore Buns, Mysore Pak, Maddur Vada, Obbattu (Holige), Pongal.
\n\n
So, let us explore what mysteries and secrets lie around Bengaluru and where you can go for weekend getaways from Bangalore:
\n\n
## 1. Gokarna
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1220432527_Gokarna.jpg "Gokarna")
\n\n
Gokarna, set on the Konkan coast, is a quiet getaway by the seashore. Unlike its commercialized cousin, Goa, it still permeates the town with that unspoiled charm that lures travelers to solitude and natural beauty. It is known for its vibrant mix of temples, beaches, and laid-back cafes. The tranquil waters of Kudle Beach are the perfect site for sunbathing or participating in most of the watery adventures. Gokarna is probably the most interesting of the weekend getaways from Bangalore.
Om Beach offers a much calmer environment as its serene and spiritual atmosphere relates to the shape of the Om sign it takes. As night bids adieu to the blue horizon with streaks of gold, the beaches of Gokarna turn into tranquil wandering sites to release and relax. Gokarna is also home to the Mahabaleshwara Temple which is visited by several pilgrims all around the year. 

**Places to visit in Gokarna**: Om Beach, Kudle Beach, Half Moon Beach, Paradise Beach, Mirjan Fort, Mahabaleshwar Temple, and Bhadrakali Temple.       
**Foods to try in Gokarna**: Seafood dishes like prawns and fish curry, local dish - Banana Bun.        
**Distance from Bangalore**: Approximately 485 km.      
**Average budget per day**: ₹1,500 - ₹2,500.        
**Number of days required to explore Gokarna**: 2-3 days.       
\n\n
## 2. Badami
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_2467079541_Badami.jpg "Badami")
\n\n
Badami is etched in history for its architectural grandeur that mirrors the best works of ancient Indian craftsmanship. Badami was once a capital of the Chalukya dynasty and therefore famous for its rock-cut temples and caves dating back to the 6th century. The landscape here is characterized by brilliant red sandstone cliffs, upon which there are carvings and sculptures of detailed embellishments. The trip to Badami will be one of the most unique weekend trips from Bangalore.	
The Badami Cave Temples present four main caves, each major one dedicated to different deities. The first of them has been dedicated to Lord Shiva with a colossal 18-armed Nataraja embedded in it. The second one had been a place of worship for the Lord Vishnu and enshrined him in all his different forms. You won’t be disappointed by visiting one of the best weekend getaways around Bangalore known for its unique cave temples.	

**Places to visit in Badami**: Badami Cave Temples, Agastya Lake, Badami Fort, and Bhutanatha Temples.      
**Foods to try in Badami**: North Karnataka meals, _Jolada Rotti_ (sorghum flatbread), _Ennegayi_ (stuffed brinjal curry).      
**Distance from Bangalore**: Approximately 410 km.      
**Average budget per day**: ₹1,000 - ₹2,000.        
**Number of days required to explore Badami**: 1-2 days.        
\n\n
## 3. Belur
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_326363990_Chennakesava%20Temple%20Belur.jpg "Belur")
\n\n
Belur is called the 'Banaras of the South' and is an immense treasure of Hoysala architecture. Belur is perched on the banks of the Yagachi River, and plays host to the magnificent Chennakesava Temple—a dazzling feat of medieval Indian art and architecture. Built in the 12th century in commemoration of the victory of King Vishnuvardhana over the Cholas, the temple is decorated with intricate sculptures and friezes, illustrating scenes from Hindu mythology, everyday life, and celestial beings. Belur is one of the most important historical weekend trips from Bangalore.	
The pure detail and craftsmanship of the stone carvings simply suffuse because each single piece reflects a story in itself. This temple complex appears like an oasis of tranquility, and the sounds heard here are only soft hymns from the priests and the chirping of birds. A visit to Belur is time travel, an insight into the artistic genius of the Hoysala dynasty. Belur is one of the best weekend getaways from Bangalore.

**Places to visit in Belur**: Chennakesava Temple, Yagachi Dam.     
**Foods to try in Belur**: Traditional Karnataka cuisine including _Bisi Bele Bath_ (spicy rice dish), and _ragi mudde_ (finger millet balls).      
**Distance from Bangalore**: Approximately 220 km.      
**Average budget per day**: ₹1,000 - ₹1,500.        
**Number of days required to explore Belur**: 1 day.        
\n\n
## 4. Halebidu
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_2338211627_Halebidu.jpg "Halebidu")
\n\n
A short drive from Belur, and not very far from Bangalore, Halebidu is the next gem of Hoysala architecture. The meaning of Halebidu is the 'Old City,' and it was the capital city of the Hoysalas. It is home to the radiant Hoysaleswara Temple, dedicated to the majestic Lord Shiva and known for its sculptures and detailed carvings covering most of the outer walls. Two shrines called the Hoysaleswara and the Shantaleswara, named after the king and queen of the Hoysala dynasty stand in all their grandeur in the complex. Halebidu is one of the best historically religious places near Bangalore for weekend.	
Including episodes from some of the grand epics like Ramayana and Mahabharata, there are a host of mythological animals, creatures, and dancers included in the ensemble. The carvings are so fine and done with care that the intricacy is magnanimous, truly the cream of Hoysala art. For an art buff, Halebidu has to be one of the best weekend trips from Bangalore.	

**Places to visit in Halebidu**: Hoysaleswara Temple, Kedareswara Temple, Jain Basadi.      
**Foods to try in Halebidu**: Traditional South Indian thali, sweet dishes like Mysore Pak.     
**Distance from Bangalore**: Approximately 215 km.              
**Average budget per day**: ₹1,000 - ₹1,500.        
**Number of days required to explore Halebidu**: 1 day.     
\n\n
## 5. Jog Falls
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1196137867_Jog%20Falls.jpg "Jog Falls")
\n\n
Jog Falls is a must-visit destination for those in search of the raw power and beauty of nature. Located in the Western Ghats, this is one of the highest plunge waterfalls in India, falling from a height of over 253 meters. River Sharavathi, which the Jog comprises, falls into four distinct segments: Raja, Rani, Rover, and Rocket. Jog Falls is among the most visited getaways around Bangalore.	
The waterfall is enveloped by greenery and rugged landscapes, adding to its dramatic beauty. This is bound to provide a paradise for nature lovers as well as those who like hiking trails. Jog Falls is more than a treat to the eye; it is something that tingles with one's senses and revives the soul. And therefore, it goes on to be a perfect escapade from city life. Jog Falls is one of the most mesmerizing weekend trips from Bangalore during monsoon.	

**Places to visit in Jog Falls**: Jog Falls Viewpoints, Linganamakki Dam, Tunga Anicut Dam.     
**Foods to try in Jog Falls**: Malnad cuisine, including Neer dosa and Chicken Ghee roast.      
**Distance from Bangalore**: Approximately 400 km.      
**Average budget per day**: ₹1,500 - ₹2,500.        
**Number of days required to explore Jog Falls**: 1-2 days.     
\n\n

## 6. Murudeshwar
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1402912046_Murudeshwar.jpg "Murudeshwar")
\n\n
Murudeshwar is one such place where spirituality and natural beauty walk hand in hand with the towering statue of Lord Shiva overlooking the Arabian Sea. This town along the coast is famous for the Murudeshwar Temple. It also houses one of the tallest standing statues in the world, that of Lord Shiva, at 123 feet in height. This temple built on Kanduka Hill is surrounded by the sea on three sides. As one climbs the steps to the temple, the view of the imposing Shiva against the setting of the wide panoramic sea makes this climb an ecstatic experience. Murudeshwar is the best Bangalore weekend outing for religious beach enthusiasts.
Other than its religious importance, Murudeshwar is also famous for its beaches and adventure sports. For people who have extreme tastes, snorkeling and scuba diving to explore marine life and coral reefs is a great option. Murudeshwar is known for its divine experience and its connection to the sea, which makes it a unique combination worth witnessing. Murudeshwar tops many lists for the best weekend getaways from Bangalore.

**Places to visit in Murudeshwar**: Murudeshwar Temple, Murudeshwar Beach, Netrani Island.      
**Foods to try in Murudeshwar**: Fresh seafood, Udupi cuisine.      
**Distance from Bangalore**: Approximately 490 km.      
**Average budget per day**: ₹1,000 - ₹2,000.        
**Number of days required to explore Murudeshwar**: 1-2 days.       
\n\n
## 7. Dandeli
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_2464167919_Dandeli.jpg "Dandeli")
\n\n
Dandeli has evolved to be the country's paradise for thrill-seeking adventure enthusiasts and nature lovers for quite a while now. Not to mention, the dense forests, the wildlife, and the meandering Kali River make up for the beauty of the town. Dandeli possesses sufficient vegetation and rough terrain, making it a perfect location for sports like rafting, kayaking, and trekking. Dandeli presents one of the best weekend trips from Bangalore for water sports enthusiasts.	
The Dandeli Wildlife Sanctuary provides one of the very few chances to catch one sighting or another of the black panther, tigers, and many birds. A coracle ride on the Kali River is an easy and quiet way to be around the natural beauty of the area. The gentle swaying of the boat, the cool breeze, and the pristine surroundings make it an experience etched in memory. Dandeli is that place where adventure and tranquility stand side by side, perfectly holding the purpose of a weekend retreat. Dandeli is one of the ideal weekend getaways near Bangalore if you are looking to experience the northern side of Karnataka. 

**Places to visit in Dandeli**: Dandeli Wildlife Sanctuary, Kavala Caves, Supa Dam, Syntheri Rocks.     
**Foods to try in Dandeli**: North Karnataka meals, bamboo shoot curry, traditional sweets like _obbattu_.      
**Distance from Bangalore**: Approximately 460 km.      
**Average budget per day**: ₹1,500 - ₹2,500.        
**Number of days required to explore Dandeli**: 2-3 days.       
\n\n
## 8. Kudremukh
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1758287300_Kudremukh.jpg "Kudremukh")
\n\n
Kudremukh, meaning 'horse face' in Kannada, is named after the shape of its peak. This hill station is an ode to nature at its finest. Kudremukh belongs to the Chikmagalur district which itself is a beautiful tourist spot. It is a paradise for trekkers, with the existence of the Kudremukh National Park, which the UNESCO World Heritage has listed among places having rolling meadows, dense forests, and rich biodiversity. For a weekend outing near Bangalore,	 the journey amidst unparalleled beauty to Kudremukh Peak, with panoramic views of emerald green valleys, gurgling streams, and misty hills is very apt. Kudremukh is one of the best hill station-type weekend getaways from Bangalore.
For an ardent trekker, Kudremukh offers a perfect blend of thrills, while at the same time, it serves as a perfect place to de-stress amidst nature.

**Places to visit in Kudremukh**: Kudremukh Peak, Kudremukh National Park, Hanuman Gundi Falls, Kadambi Waterfalls.     
**Foods to try in Kudremukh**: Malnad cuisine, Akki roti (rice flatbread), koli saaru (chicken curry).      
**Distance from Bangalore**: Approximately 350 km.          
**Average budget per day**: ₹1,000 - ₹1,500.        
**Number of days required to explore Kudremukh**: 2 days.       
\n\n
## 9. Aihole
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1023913618_Durga%20Temple_Aihole.jpg "Aihole")
\n\n
Aihole is an ancient treasure trove for history lovers and lovers of architecture. Often referred to as the 'Cradle of Indian Architecture,' Aihole stands testimony to over a hundred 4th-century temples. The popular Durga Temple in Aihole is built like a fort and has a typical apsidal temple shape with rich and very minute carvings, a stylistic element that is characteristic of the creative geniuses that the Chalukyas were. Aihole is one of the best weekend trips from Bangalore for enthusiasts of ancient architecture.	
One of the oldest temples in Aihole is the Lad Khan Temple, which has a flat-roofed and pillar-supported section to it, both in its typical style and intricately carved. One could certainly blend the peaceful atmosphere with the classic beauty these temples hold, making Aihole one of the ideal places to discover Indian rich cultural heritage. The trip to Aihole is almost comparable to walking in time, showing a glimpse into the splendid ancient Indian civilization.

**Places to visit in Aihole**: Durga Temple, Lad Khan Temple, Ravana Phadi Cave Temples, Meguti Jain Temple.        
**Foods to try in Aihole**: North Karnataka cuisine, _Holige_ (sweet flatbread), _Jolada Rotti_ (sorghum flatbread).        
**Distance from Bangalore**: Approximately 450 km.          
**Average budget per day**: ₹1,000 - ₹1,500.        
**Number of days required to explore Aihole**: 1-2 days.        
\n\n
## 10. Shravanabelagola
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1953170917_Shravanabelagola.jpg "Shravanabelagola")
\n\n
Shravanabelagola is a place of reverence, one of the Jain pilgrimage centers where spirituality and history meet. The status of this town is on account of the colossal statue of Lord Bahubali on Vindhyagiri Hill, known as Gommateshwara. It stands 57 feet in the air, chiseled into a single granite rock, making it one of the most imposing freestanding statues in the world. It symbolizes the spirit of Jain asceticism and renunciation. Steep though it may be, it is quite a climb to the hill's top itself and panoramic views of the surrounding countryside unfold from here. Shravanabelagola is one of the most cited weekend getaways from Bangalore. It is also one of the weekend getaways close to Bangalore.	

Once every twelve years, the Mahamastakabhisheka festival marks the calendar, and thousands of devotees throng to anoint the statue with milk, saffron, and sandalwood. It's not just a spiritual destination, but also a place that has historical importance because of its monuments and inscriptions that narrate the saga of Jainism's rich heritage. Shravanabelagola is ideal for weekend travel from Bangalore.

**Places to visit in Shravanabelagola**: Gommateshwara Statue, Chandragiri Hill, Vindhyagiri Hill.      
**Foods to try in Shravanabelagola**: Traditional Jain cuisine, and local South Indian dishes.      
**Distance from Bangalore**: Approximately 145 km.      
**Average budget per day**: ₹1,000 - ₹1,500.        
**Number of days required to explore Shravanabelagola**: 1 day.     
\n\n
## 11. Udupi
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1334615522_St%20Mary%20Beach_Udupi.jpg "Udupi")
\n\n
Udupi is a coastal town in the state of Karnataka, India, best known for its temples, beaches, and cuisine. It is a place that combines spirituality and enjoyment in just the right doses. The town is known most for the Udupi Sri Krishna Mattha, where it is believed the saint Kanakadasa’s prayers made the deity’s statue turn around to present itself to him. Udupi is one of the most unique weekend getaways from Bangalore.
Udupi is also known for its cuisine, with its Udupi restaurants dishing out tasty dosas, idlis, and other South Indian delights. Offering a perfect ambiance for relaxation and water activities, the town is highlighted amidst the clean beaches, the well-known ones being Malpe Beach and Hoode Beach. The student town of Manipal is another must-visit place for anyone seeking an interesting nightlife. Manipal is in Udupi District and only 6 kilometers away from Udupi and has a thriving nightlife making it an ideal Bangalore weekend outing. St. Mary’s Island is also a beautiful touring spot for anyone excited to see more than just beaches.

**Places to visit in Udupi**: Udupi Sri Krishna Mattha, Malpe Beach, St. Mary’s Island, Manipal.        
**Foods to try in Udupi**: Udupi cuisine which includes dosas, idlis, and sambar.       
**Distance from Bangalore**: Approximately 400 km.      
**Average budget per day**: ₹1,000 - ₹2,000.        
**Number of days required to explore Udupi**: 2 days.       
\n\n
## 12. Nagarhole National Park
\n\n
![Alt text](https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Weekend%20Getaways%20from%20Bangalore%20Short%20Getaway%20Places%20Near%20Bangalore%20in%202024/shutterstock_1035049054_Nagarhole%20National%20Park.jpg "Nagarhole National Park")
\n\n
The Nagarhole National Park, another name for Rajiv Gandhi National Park, is an epitome of raw, untamed beauty and richness in flora and fauna. It serves as the best Bangalore weekend outing for bird watchers. Located in the districts of Kodagu and Mysore, it also forms part of the Nilgiri Biosphere Reserve, well known for its incredible biodiversity and pristine landscapes. Its dense forests, grassy marshes, and tranquil lakes offer shelter for the casuarinas that are home to quite a lot of species' lives including elephants, tigers, and leopards, not to mention numerous kinds of birds. 
The Kabini River flows through the park, amplifying its beautiful environment with perfect spots ideal for bird-watching and photography. Peaceful moments experienced at the park and the surrounding symphony of nature are an ideal release from the chaos of urban streets. From wildlife safari to the tranquilizing feel flowing down the Kabini River, Nagarhole National Park is one of the best natural weekend getaways from Bangalore.

**Places to visit in Nagarhole National Park**: Kabini River, Wildlife Safari, Nagarhole Lake.      
**Foods to try in Nagarhole National Park**: Local Kodava cuisine, Pandi curry (pork curry).        
**Distance from Bangalore**: Approximately 220 km.      
**Average budget per day**: ₹1,500 - ₹2,500.        
**Number of days required to explore Nagarhole National Park**: 2-3 days.     

So, these are some classic and mesmerizing spots around Bangalore to visit during your weekend. Now that you know, where to go, let the spirits flow, and pack your bags and chalte bano. Let these short getaways from Bangalore add in some new experiences for you to be happy about.`       
        }
        ]

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <script type="application/ld+json">
                            {JSON.stringify(
                            {
                            "@context": "https://schema.org/", 
                            "@type": "BreadcrumbList", 
                            "itemListElement": [{
                                "@type": "ListItem", 
                                "position": 1, 
                                "name": "Home",
                                "item": "https://www.nuego.in/"
                            
                            },{
                                "@type": "ListItem", 
                                "position": 2, 
                                "name": "blog",
                                "item": "https://www.nuego.in/blog/"
                            
                            },{
                                "@type": "ListItem", 
                                "position": 3, 
                                "name": "weekend getaways from bangalore",
                                "item": "https://www.nuego.in/blog/weekend-getaways-from-bangalore"
                            
                            }]
                            })}
                        </script>
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/blog"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>Blog</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>best-south-indian-tourist-places-to-visit</p>
                    </div>
                    }
                    <div className="containers d-flex flex-column">
                        <div className="main-blog-extra-blog">
                            {
                                dataContent.map((item, indx) => {
                                    return (
                                        <div key={indx} className="main-blog-card">
                                            <h1>{item.title}</h1>
                                            <br />
                                            <img src={item.img} alt={item.imgAlt} />
                                            <br />
                                            <div className="blog-content">
                                                <ReactMarkdown>{item.desc}</ReactMarkdown>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <ExtraBlogPart dataContentTitle={dataContent?.title} />
                        </div>
                        <RecommendedBlogs />
                    </div>
                </div>
            }
            <Footer />
        </>
    );
}

export default WeekendendGetAwaysfromBlr