import {useMediaQuery} from "react-responsive";
import React, {useEffect, useRef, useState} from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Faq.scss";
import {useNavigate} from "react-router";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {AnimatePresence, motion} from "framer-motion";
import {MDBModal, MDBModalBody} from "mdbreact";
import Calendar from "react-calendar";
import CreateCallRequest from "../../services/mutation/CreateCallRequestMutation";
import {addDaysToDate, convertDateWithMonth, convertToDateFormat,} from "../../utils/utils";
import {useLocation} from "react-router-dom";

import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
// icons
import calendarIcon from "../../assets/icons/calendar.svg"
import arrowDown from "../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg"
import cross from "../../assets/icons/cross-gray.svg"
import combinedShabe from "../../assets/icons/Combined-Shape.png"
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";
//assets
const callIcon = process.env.REACT_APP_CDN_LINK + `assets/images/call.png`
const LanguageImage = process.env.REACT_APP_CDN_LINK + `assets/images/Language.png`
const TimeImage = process.env.REACT_APP_CDN_LINK + `assets/images/Combined-Shape.png`
const contactImage = process.env.REACT_APP_CDN_LINK + `assets/images/contactImage.png`
const chatImage = process.env.REACT_APP_CDN_LINK + `assets/images/chatImage.png`
const callImage = process.env.REACT_APP_CDN_LINK + `assets/images/callImage.png`
const helpImage = process.env.REACT_APP_CDN_LINK + `assets/images/helpImage.png`
const rightArrow = process.env.REACT_APP_CDN_LINK + `assets/images/rightArrow.png`
const BookingImage = process.env.REACT_APP_CDN_LINK + `assets/images/Booking.png`
const rightArrowAccordion = process.env.REACT_APP_CDN_LINK + `assets/images/rightArrowAccordion.png`


const Faq = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const state = useLocation().state;

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);

    return (
        <div className={"faq-page"}>
                <Helmet>
                    <title>NueGo Bus Travel FAQs</title>
                    <link rel="canonical" href="https://www.nuego.in/faq" hreflang="en-IN" />
                    <meta name="description" content="Got a Question? Get answers to all your NueGo's bus related queries. Find answers to all your queries related to booking, discount, payment, cancellation and much more."/>
                    <meta name="keywords" content="Bus FAQ,NueGo FAQs,Bus Questions,NueGo Questions"></meta>
                    <meta name="robots" content="index,follow"/>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "WebPage",
                        "url": "https://www.nuego.in/faq",
                        "name": "NueGo FAQ",
                        "description": "Got a Question? Get answers to all your NueGo's bus related queries. Find answers to all your queries related to booking, discount, payment, cancellation and much more.",
                        "publisher": {
                            "@type": "Organization",
                            "name": "NueGo",
                            "logo": {
                            "@type": "ImageObject",
                            "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                            "width": 200,
                            "height": 100
                            }
                        }
                        })}
                    </script>
                </Helmet>

            {isDesktopOrLaptop ? (
                <AuthModalLayout>
                    <div
                        style={
                            isDesktopOrLaptop
                                ? {marginBottom: "100px"}
                                : {marginBottom: "78px"}
                        }
                    >
                        <div className={"navbar-wrapper margin-bottom-100"}>
                            <Navbar fixedNavbar={true}/>
                        </div>
                    </div>
                    <MainContent
                        isDesktopOrLaptop={isDesktopOrLaptop}
                        focusOnCustomerCare={state?.focusOnCustomerCare}
                        expansionIdFromParentProp={state?.faqDetailId}
                    />
                    {isDesktopOrLaptop && <Footer/>}
                </AuthModalLayout>
            ) : (
                <MobileSubScreenLayout back={() => navigate(-1)} title={"Support"}>
                    <MainContent
                        isDesktopOrLaptop={isDesktopOrLaptop}
                        focusOnCustomerCare={state?.focusOnCustomerCare}
                        expansionIdFromParentProp={state?.faqDetailId}
                    />
                </MobileSubScreenLayout>
            )}
        </div>
    );
};

export const MainContent = (props) => {
    const {typeFromStore, expansionIdFromParentProp, focusOnCustomerCare} =
        props;
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const ref = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [allFAQs, setAllFAQs] = useState([])
    const [recommendedFAQs, setRecommendedFAQs] = useState([])

    const contactUsData = [
        {
            id: 1,
            image: chatImage,
            name: "Chat",
            arrow: rightArrow,
            backgroundColor: "#29BDB5",
            description:
                "Want instant answers? Chat with our customer support executive",
        },
        {
            id: 2,
            image: callImage,
            name: "Call",
            arrow: rightArrow,
            backgroundColor: "#FCC5C5",
            description: "Want to talk to us? Get on a call our customer executive",
        },
        {
            id: 3,
            image: helpImage,
            name: "Help",
            arrow: rightArrow,
            backgroundColor: "#1E1E26",
            description:
                "Having a complex issue? Raise a request &  hear from us  within 24 hours",
        },
    ];


    const [expanded, setExpanded] = useState("1");
    const [CallDetails, setCallDetails] = useState("");
    const [personalDetailData_local, setPersonalDetailData_local] = useState({});
    const [error, setError] = useState({
        name: false,
        age: false,
    });
    const [touched, setTouched] = useState([]);
    const [calendarValue, setCalendarValue] = useState(new Date());
    const [viewCalendar, setViewCalendar] = useState(false);
    const [requestCall, setRequestCall] = useState(false);
    const [close, setClose] = useState(false);

    let userName, userEmail, userId, userNmber , moeId;
    userName = localStorage.getItem("userName");
    userEmail = localStorage.getItem("userEmail");
    userId = localStorage.getItem("userID");
    moeId = localStorage.getItem("moeID");
    userNmber = localStorage.getItem("userNumber");

    const getQuestionAndAnswerData = (data) => {

        window.Moengage.track_event("faq_ques_check", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: "mobile-website",
            page_viewed: "yes",
            fullname: `${userName === null ? "" : userName}`,
            mobile_number: `${userNmber === null ? "" : userNmber}`,
            email: `${userEmail === null ? "" : userEmail}`,
            isloggedin: `${userNmber === null ? "no" : "yes"}`,
            visit_date: convertDateWithMonth(new Date()),
            faq_Check: data?.question?data.question:"",
            faq_id:data?.id?data.id:"",
        });
        navigate("/faq/" + data.id);
    };

    // methods

    let today = new Date(calendarValue);
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let year = today.getFullYear();
    let yyyy = year.toString();
    let date = dd.concat("/", mm, "/", yyyy);

    const onChangeHandler = (e, field) => {
        if (field === "preferredLanguage") {
            const language = parseInt(e.target.value);
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: language,
            });
        }
        if (!touched.includes(field)) {
            setTouched([...touched, field]);
        }

        if (field === "name") {
            if (e.target.value === "") {
                setError({...error, [field]: "error"});
                setPersonalDetailData_local({
                    ...personalDetailData_local,
                    [field]: e.target.value,
                });
            } else {
                if (e.target.value.match(/\d+/g) === null) {
                    setError({...error, [field]: null});
                    setPersonalDetailData_local({
                        ...personalDetailData_local,
                        [field]: e.target.value,
                    });
                }
            }
        }
        var regPattern = /^\(?(\d{3})\)?[-]?(\d{3})[-]?(\d{4})$/;
        if (field === "mobileNumber") {
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: e.target.value,
                ["preferredDate"]: date,
            });

            if (e.target.value.match(regPattern) == null) {
                setError({...error, [field]: "error"});
            } else {
                setError({...error, [field]: null});
            }
        }
        const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d))$/;
        // const timeRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/;

        if (field === "preferredTime") {
            setPersonalDetailData_local({
                ...personalDetailData_local,
                [field]: e.target.value,
            });
            if (e.target.value.match(timeRegex) == null) {
                setError({...error, [field]: "error"});
            } else {
                setError({...error, [field]: null});
            }
        }
    };

    const onSubmitHandler = () => {
        if (
            Object.keys(personalDetailData_local).length === 5 &&
            personalDetailData_local.mobileNumber.length === 10
        ) {
            setRequestCall(true);
            let variable = {
                mobileNumber: personalDetailData_local.mobileNumber,
                name: personalDetailData_local.name,
                preferredDate: personalDetailData_local.preferredDate,
                preferredLanguage: personalDetailData_local.preferredLanguage,
                preferredTime: personalDetailData_local.preferredTime,
                requestedFrom: 1,
            };
            CreateCallRequest(
                variable,
                (response) => {
                },
                (err) => {
                    if(err){
                        cogoToast.error(
                            <CustomToast
                                heading={"Error"}
                                message={err[0]?.message || err.toString()}
                                type={"error"}
                            />,
                        );
                    }
                }
            );
        } else {
            alert("Please fill the all column");
        }
    };

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-700w-16s-24h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    useEffect(() => {
        if (expansionIdFromParentProp) {
            setExpanded(parseInt(expansionIdFromParentProp));
            navigate(location.pathname, {replace: true});
        }
        return () => {
        };
    }, [expansionIdFromParentProp]);

    const contactUsSection = document.querySelector(
        "#customer-service-contact-us"
    );


// nee to add in env
    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/faq-topics?populate=*`)
            .then(res => res.json())
            .then(res => {
                setAllFAQs(res.allFAQs)
                setRecommendedFAQs(res.recommendedFAQs)
            })
            .catch((err) =>{
                if(err){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={err[0]?.message || err.toString()}
                            type={"error"}
                        />,
                    );
                }
            })
    }, [])


    useEffect(() => {
        const triggerScrollingOnInterval = setInterval(() => {
            if (
                document.querySelector("#customer-service-contact-us") &&
                focusOnCustomerCare
            ) {
                document.querySelector("#customer-service-contact-us").scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                });
                navigate(location.pathname, {replace: true});
                clearInterval(triggerScrollingOnInterval);
            }
        }, [1000]);
        return () => {
        };
    }, [focusOnCustomerCare]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (viewCalendar && ref.current && !ref.current.contains(e.target)) {
                setViewCalendar(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [viewCalendar]);

    // call reqest data
    const callRequestData = [
        {
            name: "Mobile Number",
            data: personalDetailData_local?.mobileNumber,
            image: callIcon,
        },
        {
            name: "Preferred Date",
            data: personalDetailData_local?.preferredDate,
            image: calendarIcon,
        },
        {
            name: "Preferred Time",
            data: personalDetailData_local?.preferredTime,
            image: TimeImage,
        },
        {
            name: "Preferred Language",
            data: "English",
            image: LanguageImage,
        },
    ];

    const expandFaqTab = (data)=>{
        window.Moengage.track_event("faq_check", {
            account_id: `${moeId === null ? "" : moeId}`,
            platform: `${isDesktopOrLaptop ? "website" : "mobile website"}`,
            page_viewed: "yes",
            fullname: `${userName === null ? "" : userName}`,
            mobile_number: `${userNmber === null ? "" : userNmber}`,
            email: `${userEmail === null ? "" : userEmail}`,
            isloggedin: `${userNmber === null ? "no" : "yes"}`,
            visit_date: convertDateWithMonth(new Date()),
            faq_Category_Check: data?.name?data.name:"",
        });
        setExpanded(data.id === expanded ? "" : data.id)

    }

    const CallDetailsPart = (data) => {
        if (data === 2) navigate("/call")
        else if(data ===1  || data ===3){
            window.HaptikSDK.show()
        }
    };

    return (
        <div className={"main-content"}>
            {isDesktopOrLaptop && (
                <div className="px-3 pt-4 pb-1">
                    <Link to="/">
                    <span className="home">Home</span>
                    </Link>
                    <span className="px-2">/</span>
                    <span className="support">Support</span>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                            "@type": "ListItem",
                            "position": 1,
                            "name": "Home",
                            "item": "https://www.nuego.in"
                            },
                            {
                            "@type": "ListItem",
                            "position": 2,
                            "name": "FAQ",
                            "item": "https://www.nuego.in/faq"
                            }
                        ]
                        })}
                        </script>
                </div>
            )}
            {isDesktopOrLaptop && (
                <div className={"title ubuntu-400w-16s-28h"}>Support</div>
            )}
            <div>
                <div className="px-3">
                    <p className={"popular-queries"}>Based on popular queries </p>
                    <script type='application/ld+json'>
                        {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                            "@type": "Question",
                            "name": "What are the cancellation charges?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Cancellation charges are applicable as per the cancellation policy. For more details you can visit the terms and condition tab on the homepage."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "My amount got debited but I did not get a confirmed ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "In case your amount got debited but you have not got ticket confirmation, we request you to email us at support@nuego.in with all the relevant details of the transaction."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How to book a ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "On the Nuego App, enter the departure city, destination, select date of journey and search for all buses on that route. Select the appropriate time convenient for you, boarding and destination point and do the seat selection. Then proceed to book."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What are the documents required while travelling by NueGo?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You need to carry a govt authorized photo id along with SMS/Whatsapp/email of your ticket."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What is your baggage policy?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "1 Medium suitcase or two small suitcases per guest is permitted. Excess charges will be levied in case of additional baggage. You are requested to visit the terms and conditions tab on the homepage for more details on the policy."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "I missed my luggage/some contents of the luggage",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "We would request you to get in touch with us via email on support@nuego.in or our 24*7 contact centre number 1800 267 9001"
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How to reschedule a ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, you can reschedule the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be rescheduled 3) At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to reschedule the booking."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How to modify a ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, you can modify the ticket by following the steps mentioned 1) sign into the app and click on manage booking 2) Go to upcoming trips & select the PNR that needs to be modified 3) At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to modify. Currently, you can modify the pickup/drop point and the mobile number."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Are there any charges for rescheduling ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Rescheduling charges are Rs 50+ GST. For more details you can visit the terms and conditions tab on the homepage."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How to cancel a ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, you can cancel the ticket by following the steps: 1) Sign into the app and click on manage booking. 2) Go to upcoming trips & select the PNR that needs to be cancelled. 3) At the end of the PNR details, you will see an option of change booking. Click on it and you will get the option to cancel the booking."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "When do I get my refund post cancellation?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, please note that the refund amount will be credited back to the source in 7 working days. For bookings done via other sources, you need to get in touch with the respective operator."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What facilities are usually available in a lounge?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Lounge facility varies depending on the type and location of the lounge. However, common amenities include online booking, comfortable seating, Wi-Fi access, baggage counter, washrooms, staff helper, to name a few."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Are Lounges available in all your cities?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Not yet, but we are working to add NueGo lounges in more cities soon. Details of our currently operational lounges are available on this page."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "What are the lounge timings and address?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Please see the timings and addresses for all our Lounges on this page."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How can I download invoice for a booked ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps: 1) Sign into the app and click on manage booking. 2) Go to upcoming trips & select the PNR. 3) At the end of the PNR details, you will see an option of view invoice. Upon clicking that tab, the invoice will be available in PDF format."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "How can I download a booked ticket?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For tickets booked via Nuego website or app, you can download the booked ticket by following the steps: 1) Sign into the app and click on manage booking. 2) Go to upcoming trips & select the PNR. 3) At the end of the PNR details, you will see an option of download ticket."
                            }
                            },
                            {
                            "@type": "Question",
                            "name": "Do you need a separate ticket for children?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "For children of 5 years and above, a separate ticket would be required and a seat would be issued accordingly."
                            }
                            }
                        ]
                        })}
                    </script>
                    <div className={"custom-accordion"}>
                        <motion.div
                            initial={false}
                            className={
                                "1" === expanded ? "header-active" : "header cursor-pointer"
                            }
                            // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                            onClick={() => setExpanded("1" === expanded ? "" : "1")}
                        >
                            <div
                                style={{gap: "10px"}}
                                className="d-flex align-items-center "
                            >
                                {!isDesktopOrLaptop && <img src={BookingImage} alt="alt"/>}
                                <p>Recommended FAQs</p>
                            </div>
                            <motion.div animate={{rotate: expanded === "1" ? 180 : 0}}>
                                <img src={arrowDown} alt={"alt"} className={"icon-24"}/>
                            </motion.div>
                        </motion.div>
                        <AnimatePresence initial={false}>
                            {"1" === expanded && (
                                <motion.div
                                    key="content"
                                    className={"content"}
                                    initial="collapsed"
                                    animate="open"
                                    exit="collapsed"
                                    variants={{
                                        open: {opacity: 1, height: "auto"},
                                        collapsed: {opacity: 0, height: 0},
                                    }}
                                    transition={{duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98]}}
                                >
                                    {recommendedFAQs.map((data, index) => (
                                        <div
                                            key={index}
                                            onClick={
                                                isDesktopOrLaptop
                                                    ? ""
                                                    : () => getQuestionAndAnswerData(data)
                                            }
                                            className={
                                                isDesktopOrLaptop
                                                    ? "px-4"
                                                    : "d-flex justify-content-between align-items-center px-4 cursor-pointer"
                                            }
                                        >
                                            {isDesktopOrLaptop && (
                                                <li
                                                    className={
                                                        isDesktopOrLaptop
                                                            ? "open-600w-16s-24h mt-3"
                                                            : "open-600w-14s-22h "
                                                    }
                                                    style={{
                                                        color: isDesktopOrLaptop ? "#1E1E26" : "#4B4B51",
                                                    }}
                                                >
                                                    {data.question}
                                                </li>
                                            )}
                                            {!isDesktopOrLaptop && (
                                                <p
                                                    className={
                                                        isDesktopOrLaptop
                                                            ? "open-600w-16s-24h"
                                                            : "open-600w-14s-22h"
                                                    }
                                                    style={{
                                                        color: isDesktopOrLaptop ? "#1E1E26" : "#4B4B51",
                                                    }}
                                                >
                                                    {data.question}
                                                </p>
                                            )}
                                            {isDesktopOrLaptop && (
                                                <p className={"open-400w-16s-24h"}
                                                   dangerouslySetInnerHTML={{__html: data.answer}}></p>
                                            )}
                                            {!isDesktopOrLaptop && (
                                                <img
                                                    style={{width: "6px", height: "10px"}}
                                                    src={rightArrowAccordion}
                                                    alt={"alt"}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </div>

                <div className="all-faqs">
                    <h2 className="pb-3">All FAQ Topics</h2>

                    {allFAQs.map((data, index) => (
                        <div key={index} className={"custom-accordion"}>
                            <motion.div
                                initial={false}
                                className={
                                    data.id === expanded
                                        ? "header-active"
                                        : "header cursor-pointer"
                                }
                                // animate={{backgroundColor: item.id.toString() === expanded ? "#FF0088" : "#0055FF"}}
                                onClick={() => expandFaqTab(data)}
                            >
                                <div
                                    style={{gap: "10px"}}
                                    className="d-flex align-items-center "
                                >
                                    {expanded === data.id ? (
                                        ""
                                    ) : (
                                        <img src={data.icon} alt="alt"/>
                                    )}
                                    <p>{data.name}</p>
                                </div>
                                <motion.div
                                    animate={{rotate: expanded === data.id ? 180 : 0}}
                                >
                                    <img
                                        src={arrowDown}
                                        alt={"alt"}
                                        className={"icon-24"}
                                    />
                                </motion.div>
                            </motion.div>
                            <AnimatePresence initial={false}>
                                {data.id === expanded && (
                                    <motion.div
                                        key="content"
                                        className={"content"}
                                        initial="collapsed"
                                        animate="open"
                                        exit="collapsed"
                                        variants={{
                                            open: {opacity: 1, height: "auto"},
                                            collapsed: {opacity: 0, height: 0},
                                        }}
                                        transition={{
                                            duration: 0.8,
                                            ease: [0.04, 0.62, 0.23, 0.98],
                                        }}
                                    >
                                        {data.faqs.map((data, index) => (
                                            <div
                                                key={index}
                                                onClick={
                                                    isDesktopOrLaptop
                                                        ? ""
                                                        : () => getQuestionAndAnswerData(data)
                                                }
                                            >
                                                <div
                                                    className={
                                                        isDesktopOrLaptop
                                                            ? " px-4"
                                                            : "px-4 d-flex align-items-center justify-content-between cursor-pointer"
                                                    }
                                                >
                                                    {isDesktopOrLaptop && (
                                                        <li
                                                            className={
                                                                isDesktopOrLaptop
                                                                    ? "open-600w-16s-24h mt-3"
                                                                    : "open-600w-14s-22h"
                                                            }
                                                            style={{
                                                                color: isDesktopOrLaptop
                                                                    ? "#1E1E26"
                                                                    : "#4B4B51",
                                                            }}
                                                        >
                                                            {data.question}
                                                        </li>
                                                    )}
                                                    {!isDesktopOrLaptop && (
                                                        <p
                                                            className={
                                                                isDesktopOrLaptop
                                                                    ? "open-600w-16s-24h"
                                                                    : "open-600w-14s-22h"
                                                            }
                                                            style={{
                                                                color: isDesktopOrLaptop
                                                                    ? "#1E1E26"
                                                                    : "#4B4B51",
                                                            }}
                                                        >
                                                            {data.question}
                                                        </p>
                                                    )}
                                                    {isDesktopOrLaptop && (
                                                        <p className={"open-400w-16s-24h"}
                                                           dangerouslySetInnerHTML={{__html: data.answer}}></p>

                                                    )}

                                                    {!isDesktopOrLaptop && (
                                                        <img
                                                            style={{width: "6px", height: "10px"}}
                                                            src={rightArrowAccordion}
                                                            alt={"alt"}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                </div>
            </div>
            <div
                id={"customer-service-contact-us"}
                className="contact-us cursor-pointer"
            >
                <div className="contact-us-title-part">
                    {!isDesktopOrLaptop && <img alt="alt" src={contactImage}/>}
                    <h2 className="contact-us-title">Contact Us</h2>
                </div>
                <div className="d-flex contact-details">
                    {contactUsData.map((data, index) => (
                        <div
                            className="contact-details-box"
                            // onClick={() => setCallDetails(data.id)}
                            onClick={() => CallDetailsPart(data.id)}
                        >
                            <div
                                key={index}
                                className="contact-details-box-data"
                                style={{backgroundColor: data.backgroundColor}}
                            >
                                <img
                                    className="contact-details-img"
                                    src={data.image}
                                    alt="alt"
                                />
                                <div
                                    className={
                                        isDesktopOrLaptop
                                            ? "d-flex align-items-center justify-content-center contact-details-name"
                                            : "contact-details-name"
                                    }
                                >
                                    <p className="">{data.name}</p>
                                    {isDesktopOrLaptop && <img alt="alt" src={data.arrow}/>}
                                </div>
                            </div>
                            {isDesktopOrLaptop && (
                                <p className="contact-details-description">
                                    {data.description}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            {CallDetails === 2 && isDesktopOrLaptop && close === false && (
                <div>
                    <MDBModal
                        isOpen={typeFromStore !== ""}
                        position={"center"}
                        centered={true}
                        size={"fluid"}
                    >
                        <MDBModalBody>
                            <div className="d-flex px-5 justify-content-between">
                                <p className="req-call">Request a Call</p>
                                <img
                                    src={cross}
                                    alt={"alt"}
                                    className={"icon-2"}
                                    onClick={() => setClose(true)}
                                />
                            </div>

                            {/* FORM	*/}
                            {requestCall === false && (
                                <div className={"mb-4 px-5"}>
                                    <div
                                        className={
                                            "row " +
                                            (isDesktopOrLaptop
                                                ? componentData.inputMarginBottom_class
                                                : "")
                                        }
                                    >
                                        <div className=" col-12 mb-lg-0 mb-3">
                                            {/* name */}
                                            <p className={componentData.label_class}>Name </p>
                                            <div
                                                className={
                                                    "common-input-wrap input-name " +
                                                    (error.name === "error" && "common-input-wrap-error")
                                                }
                                            >
                                                <input
                                                    className="border-less-input w-100"
                                                    value={personalDetailData_local.name}
                                                    onChange={(e) => {
                                                        // case 1 a
                                                        onChangeHandler(e, "name");
                                                    }}
                                                    type={"text"}
                                                />
                                            </div>
                                            {error.name === "error" && (
                                                <div>
                                                    <p className={componentData.error_class}>
                                                        Invalid Name
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 ">
                                            {/* phone number */}
                                            {isDesktopOrLaptop && (
                                                <div className="d-flex mt-3">
                                                    <div className={"mr-3"}>
                                                        <p className={componentData.label_class}>Code</p>

                                                        <div className={"common-input-wrap "}>
                                                            <select className="border-less-input select-element">
                                                                <option>+91</option>
                                                                <option>+90</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className={"w-100"}>
                                                        <p className={componentData.label_class}>
                                                            Phone Number
                                                        </p>

                                                        <div className={"common-input-wrap "}>
                                                            <input
                                                                min="0"
                                                                max="10"
                                                                className="border-less-input w-100 "
                                                                type={"number"}
                                                                // readOnly={true}
                                                                value={personalDetailData_local.mobileNumber}
                                                                pattern="[0-9]{10}"
                                                                required
                                                                onKeyPress={(e) =>
                                                                    e.target.value.length === 10
                                                                        ? e.preventDefault()
                                                                        : null
                                                                }
                                                                onChange={(e) =>
                                                                    onChangeHandler(e, "mobileNumber")
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {error.mobileNumber === "error" && (
                                            <div>
                                                <p className={componentData.error_class}>
                                                    Invalid PhoneNumber
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <div className={"row"}>
                                        <div className="col-lg-6  mb-lg-0 mb-3">
                                            <p className={componentData.label_class}>
                                                Preferred Date
                                            </p>

                                            {isDesktopOrLaptop && (
                                                <div className={"position-relative"}>
                                                    <div
                                                        onClick={() => setViewCalendar(!viewCalendar)}
                                                        className={
                                                            "common-input-wrap d-flex justify-content-between date-input align-items-center mb-2 cursor-pointer"
                                                        }
                                                    >
                                                        <div className={"d-flex align-items-center"}>
                                                            <img
                                                                src={calendarIcon}
                                                                className={"icon-24 mr-3"}
                                                                alt={"calendar"}
                                                            />
                                                            <p className={"open-600w-18s-28h mb-0"}>
                                                                {convertDateWithMonth(calendarValue)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {viewCalendar && (
                                                        <div className={"calendar-wrapper"}>
                                                            <Calendar
                                                                onChange={(e) => setCalendarValue(e.toString())}
                                                                defaultValue={convertToDateFormat(
                                                                    calendarValue
                                                                )}
                                                                value={convertToDateFormat(calendarValue)}
                                                                minDate={new Date()}
                                                                inputRef={ref}
                                                                maxDate={addDaysToDate(new Date(), 30)}
                                                                onClickDay={(e) => {
                                                                    // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                                                                    //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                                                                    setTimeout(() => {
                                                                        // deliverySlotChecker(formik, convertDateReverse(e))
                                                                        setViewCalendar(false);
                                                                    }, 1000);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-lg-6  mb-lg-0 mb-3">
                                            {isDesktopOrLaptop && (
                                                <>
                                                    <p className={componentData.label_class}>
                                                        Preferred Time
                                                    </p>

                                                    <div
                                                        //  onClick={() => handleTouch("email")}
                                                        className={
                                                            "common-input-wrap " + "common-input-wrap"
                                                        }
                                                    >
                                                        <input
                                                            className="border-less-input w-100"
                                                            type={"text"}
                                                            required={true}
                                                            value={personalDetailData_local.preferredTime}
                                                            onChange={(e) =>
                                                                onChangeHandler(e, "preferredTime")
                                                            }
                                                            placeholder="HH:MM"
                                                        ></input>
                                                    </div>
                                                    {error.preferredTime === "error" && (
                                                        <div>
                                                            <p className={componentData.error_class}>
                                                                Invalid Time
                                                            </p>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="col-lg-6  mb-lg-0 mb-3 mt-3">
                                            {isDesktopOrLaptop && (
                                                <>
                                                    <p className={componentData.label_class}>Language</p>

                                                    <div
                                                        //  onClick={() => handleTouch("email")}
                                                    >
                                                        <div className={"common-input-wrap "}>
                                                            <select
                                                                className="border-less-input select-element"
                                                                onChange={(e) =>
                                                                    onChangeHandler(e, "preferredLanguage")
                                                                }
                                                            >
                                                                <option value={0}>Select</option>
                                                                <option value={1}>English</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div className="px-2 d-flex my-4 help-line">
                                        <img className="mt-1" src={combinedShabe} alt={"alt"}/>
                                        <p>
                                            Would you like to call us? Please reach out to us on
                                            <br></br>
                                            <span>1800 267 9001</span>
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button
                                            className={" call-req-cta "}
                                            onClick={onSubmitHandler}
                                        >
                                            Request
                                        </button>
                                    </div>
                                </div>
                            )}

                            {/* call requested */}
                            {requestCall && (
                                <div className="px-5">
                                    <div className="call-requesd">
                                        <p className="call-on-number">
                                            We will call you on this number
                                        </p>
                                        <div>
                                            {callRequestData.map((item, index) => (
                                                <div key={index} className="d-flex requested-details">
                                                    <img src={item.image} alt="alt"/>
                                                    <div>
                                                        <h1>{item.name}</h1>
                                                        <p>{item.data}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="px-2 d-flex my-4 help-line">
                                        <img className="mt-1" src={combinedShabe} alt={"alt"}/>
                                        <p>
                                            Would you like to call us? Please reach out to us on
                                            <br></br>
                                            <span>1800 267 9001</span>
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className={" call-req-disabled-cta "} disabled>
                                            Call Requested
                                        </button>
                                    </div>
                                </div>
                            )}
                        </MDBModalBody>
                    </MDBModal>
                </div>
            )}
        </div>
    );
};
export default Faq;
