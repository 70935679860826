import graphql from 'babel-plugin-relay/macro';

const GetChecklistQuery = graphql`
	  query GetChecklistQuery {
	    getChecklist {
		    id
			name
			isReminder
			isAadhaar
			isPrescription
			checklistitemSet{
				edges{
				node{
					id
					itemName
					isChecked
				}
				}
			}
			checklistreminderSet{
				edges{
				node{
					id
					reminderDate
					reminderTime
				}
				}
			}
		}
	}`;

export default GetChecklistQuery;
