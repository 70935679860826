import "./styles.scss";
import "swiper/css";

import React, { useRef, useState, useCallback, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";

import leftArrow from "../../../../../src/assets/icons/left-insta-arrow.svg";
import righttArrow from "../../../../../src/assets/icons/right-insta-arrow.svg";
import instaMobileOne from "../../../../../src/assets/images/insta-1.jpg";
import instaDsOne from "../../../../../src/assets/images/insta-11.jpg";
import instaMobileTwo from "../../../../../src/assets/images/insta-2.png";
import instaDsTwo from "../../../../../src/assets/images/insta-22.png";
import instaMobileThree from "../../../../../src/assets/images/insta-3.jpg";
import instaDsThree from "../../../../../src/assets/images/insta-33.jpg";
import instaMobileFour from "../../../../../src/assets/images/insta-4.jpg";
import instaDsFour from "../../../../../src/assets/images/insta-44.jpg";
import instaMobileFive from "../../../../../src/assets/images/insta-5.png";
import instaDsFive from "../../../../../src/assets/images/insta-55.png";

const InstagramUpdate = () => {
  const sliderRef = useRef(null);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const GalleryData = [
    {
      image: isDesktopOrLaptop ? instaDsOne : instaMobileOne,
      desc: "NueGo’s epic Kashmir to Kanyakumari (E-K2K) electric journey is officially on!",
      url: "https://www.instagram.com/reel/DA5hGh9oNZR/?igsh=MWdkbmN3NjJ2aHo2Yw==",
    },
    {
      image: isDesktopOrLaptop ? instaDsTwo : instaMobileTwo,
      desc: "Honored to have NITI Aayog Advisor Sh. Sudhendu Sinha share motivating words on our journey to a greener future.",
      url: "https://www.instagram.com/reel/DA_YsZdSQAY/?igsh=MWE2b21sMnBha2VzeQ==",
    },
    {
      image: isDesktopOrLaptop ? instaDsThree : instaMobileThree,
      desc: "Sh. Nitin Gadkari ji, Hon’ble Union Minister of Road Transport and Highways, GOI, flagging off NueGo’s E-K2K journey from Nagpur.",
      url: "https://www.instagram.com/reel/DBFu8gTynxt/?igsh=MTFmM2RjemM4Y2NyOA==",
    },
    {
      image: isDesktopOrLaptop ? instaDsFour : instaMobileFour,
      desc: "MP Sh. Vijay Vasanth receives us in style as NueGo's E-K2K journey reaches its final leg at Kanyakumari.",
      url: "https://www.instagram.com/reel/DBY6lF8oEZd/?igsh=Zjh6czdnNHJvaHFj",
    },
    {
      image: isDesktopOrLaptop ? instaDsFive : instaMobileFive,
      desc: "Electric Roads Ahead! Inspiring words from Mr. Abhijeet Sinha, NHEV Project Director, as he flags off NueGo's epic E-K2K journey from Delhi!",
      url: "https://www.instagram.com/reel/DCCEI5QSORX/?igsh=Z3BlaGJ1bGRwYnlv",
    },
  ];

  return (
    <div className="instagram-update">
      <div className="title-part">
        <h2> Get More Updates</h2>

        {isDesktopOrLaptop && (
          <div className="arrow">
            <div onClick={handlePrev}>
              <img src={leftArrow} alt={"left-arrow"} loading="lazy" />
            </div>
            <div onClick={handleNext}>
              <img src={righttArrow} alt={"right-arrow"} loading="lazy" />
            </div>
          </div>
        )}
      </div>
      <div className="slider">
        <Swiper
          lazy={true}
          loop={true}
          slidesPerView={isDesktopOrLaptop ? 4 : 1.7}
          ref={sliderRef}
          spaceBetween={isDesktopOrLaptop ? 32 : 20}
          className="mySwiper"
        >
          <div className="">
            {GalleryData?.map((item, indx) => (
              <SwiperSlide key={indx}>
                <div>
                  <img
                    src={item?.image}
                    alt={"insta-img"}
                    loading="lazy"
                    onClick={() => window.open(item.url, "_blank")}
                  />
                  <p className="desc">{item.desc}</p>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default InstagramUpdate;
