import React, {useEffect, useState} from 'react'
import './TermsConditionsModalContent.scss'
import TermsConditionsDetail from "./TermsConditionsDetail/TermsConditionsDetail";


//icons
import close from "../../assets/icons/cross-gray.svg" 
import FallBack from '../FallBack';
import Loader from '../Loader/Loader';


const TermsConditionsModalContent = ({closeModal}) => {

    const [activeHeading, setActiveHeading] = useState(0);
	const [LoadingSpiner, setLoadingSpiner] = useState({value: true, Response: "pending" })
	const [TermsAndConditionsData, setTermsAndConditionsData] = useState([])

    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }

	const TermsAndConditionsGetApi = () => {
		setLoadingSpiner(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/terms-and-conditions?sort=rank:asc`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => res.json())
        .then(
          (res) => {
            if (res?.error) {
              setLoadingSpiner(errorObject)
            } else {
              setLoadingSpiner(sucessObject)
              setTermsAndConditionsData(res?.data)
            }
            return res;
          })
        .catch((err) => {
          setLoadingSpiner(errorObject)
          return err;
        });
	}


	useEffect(() => {
		TermsAndConditionsGetApi()
	}, [])

    const headingClickHandler = (id) => {
        setActiveHeading(id)
        document.getElementById("terms-" + id.toString()).scrollIntoView()
    }

    return (
        <div className={'privacy-modal-content px-3'}>
          { LoadingSpiner?.value == true ? <Loader /> :   <div className={'row'}>
                <div className={"heading-list col-4 px-0 pb-5"}>
                    <p className={'ubuntu-700w-24s-36h black-1E1E26-color padding-top-side-40 mb-4'}>Terms &
                        Conditions</p>
                    <div className={''}>
                        {LoadingSpiner?.Response != "error" ? TermsAndConditionsData.map((item,id) => {
                            return <div key={id}
                                        onClick={() => headingClickHandler(id)}
                                        className={"heading-item cursor-pointer " + (activeHeading === id ? 'active-heading' : "")}>
                                <p className={'ubuntu-500w-20s-32h ' + (activeHeading === id ? "teal-2-00A095-color" : "grey-3-adadad-color")}>{id+1} {item?.attributes?.topic}</p>
                            </div>
                        })
                    :DataForTermsAndConditions?.map(item => {
                        return <div key={item.id}
                                    onClick={() => headingClickHandler(item.id)}
                                    className={"heading-item cursor-pointer " + (activeHeading === item.id ? 'active-heading' : "")}>
                            <p className={'ubuntu-500w-20s-32h ' + (activeHeading === item.id ? "teal-2-00A095-color" : "grey-3-adadad-color")}>{item.id} {item.title}</p>
                        </div>
                    })}      
                    </div>
                </div>
                <div className={"col-7 terms-condition-details pb-5 padding-top-side-40"}>
                    <TermsConditionsDetail />
                </div>
                <div className={'col-1 pt-5'}>
                    <img src={close} alt={'close'} className={'icon-24'} onClick={closeModal}/>
                </div>
            </div>}
        </div>
    )
}

export default TermsConditionsModalContent

const DataForTermsAndConditions = [
    {
        id: 1,
        title: "Website ",

    },
    {
        id: 2,
        title: "Booking And Boarding Of The Passengers",

    },
    {
        id: 3,
        title: "Boarding And Departure",

    },
    {
        id: 4,
        title: 'Baggage',

    }, {
        id: 5,
        title: 'Customer’s Responsibility And Express Undertaking',

    },
    {
        id: 6,
        title: 'Amounts, Fees, And Payment',

    },
    {
        id: 7,
        title: "Right To Refuse Service"
    },
    {
        id: 8,
        title: "Cancellation And Refunds"
    },
    {
        id: 9,
        title: "Breakdown Of The Vehicle Or Insufficient Passenger"
    }, {
        id: 10,
        title: "Force Majeure"
    },
    {
        id: 11,
        title: "Indemnification "
    },
    {
        id: 12,
        title: "Fake Calls And Other Similar Phishing, Spamming Or Fraudulent Activities"
    }, {
        id: 13,
        title: "Jurisdiction "
    }, {
        id: 14,
        title: " Amendment To The Terms And Conditions"
    }, {
        id: 15,
        title: "Confidentiality"
    },
    {
        id: 16,
        title: "Privacy Policy"
    }

]
