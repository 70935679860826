import {commitMutation} from 'react-relay';
import graphql from "babel-plugin-relay/macro";
import environment from '../../environment';

const mutation = graphql`
    mutation  UberHookRedirectViewMutation($bookingId:String!){
        uberHookRedirectView(bookingId:$bookingId){
            ok
            url
        }
    }
`


export default (data, callback, errCallback) => {
    const variables = {
        bookingId: data.bookingId,
    }
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.uberHookRedirectView !== null) {
                    callback(response)
                    window.open(response.uberHookRedirectView.url)
                } else {
                    errCallback(err[0].message);
                }

            },
            onError: err => {

                errCallback(err);
            }
        }
    )
}