import React, {useEffect, useState} from 'react'
import ProfileChecklist from "../ProfileDetail/component/ProfileChecklist";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate} from "react-router";
import {useMediaQuery} from "react-responsive";

const ChecklistPageMobile = () => {
    const navigate = useNavigate();
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [checklistName_modal, setChecklistName_modal] = useState(false);


    useEffect(() => {
        if (isDesktopOrLaptop) {
            navigate('/profile-detail')
        }
        return () => {

        };
    }, [isDesktopOrLaptop]);

    return (
        <div className={'checklist-page-mobile'}>
            <MobileSubScreenLayout title={'Checklist'} back={()=>navigate('/profile-detail')}
                                   isDropDown={false}
            >
                <ProfileChecklist checklistName_modal={checklistName_modal} setChecklistName_modal={setChecklistName_modal}/>
                {
                    !checklistName_modal &&
                <div className={'settle-at-bottom w-100 py-4 px-3'}>
                    <button className={'submit-button w-100'} onClick={() => setChecklistName_modal(!checklistName_modal)}>Create
                        Checklist
                    </button>
                </div>
            }
            </MobileSubScreenLayout>

        </div>
    )
}

export default ChecklistPageMobile
