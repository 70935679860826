import React from 'react'
import "../Careers.scss"

//assets
const benefits1 = process.env.REACT_APP_CDN_LINK + `assets/images/benefits1.png`
const benefits2 = process.env.REACT_APP_CDN_LINK + `assets/images/benefits2.png`
const benefits3 = process.env.REACT_APP_CDN_LINK + `assets/images/benefits3.png`
const benefits4 = process.env.REACT_APP_CDN_LINK + `assets/images/benefits4.png`

const Benefits = () => {
    return (
        <div className='Nuego-benefits'>
            <div className="head">NueGo Benefits</div>
            <div className="benefits-flex">
                <div className="plus">
                    <img src={benefits1} alt={"benefits1"} className={"benefits1"}/>
                    <div className="sub-head">Comprehensive <br/> Insurance</div>
                </div>
                <div className="plus">
                    <img src={benefits2} alt={"benefits2"} className={"benefits2"}/>
                    <div className="sub-head">Rewards & Recognition</div>
                </div>
                <div className="plus">
                    <img src={benefits3} alt={"benefits3"} className={"benefits3"}/>
                    <div className="sub-head">Sponsored Learning</div>
                </div>
                <div className="plus">
                    <img src={benefits4} alt={"benefits4"} className={"benefits4"}/>
                    <div className="sub-head">Flexible Timings</div>
                </div>
            </div>

        </div>
    )
}

export default Benefits