import React,{useRef} from 'react'
import '../TicketDetailsPage.scss'
import {useMediaQuery} from "react-responsive";

//assets
const ContributionImage = process.env.REACT_APP_CDN_LINK + `assets/images/contributionImage.png`
const MobileContributionImage = process.env.REACT_APP_CDN_LINK + `assets/images/mobileDeviceEcoContribution.png`
const GreenMilesMobileConfirmationImage = process.env.REACT_APP_CDN_LINK + `assets/images/completed-trip-mobile-image.png`
const GreenMilesDesktopConfirmationImage = process.env.REACT_APP_CDN_LINK + `assets/images/ticket-view-desktop-image.png`

const CompletedEcoContributionTicketDetail = (props) => {
    const {postBookingData_local} = props

	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1024px)",
	});

    const sliderRef = useRef(null);
    const EcoContributionData = [{
        name: "Woah!",
        description: "Your journey helped us save 120 trees",
        image: ContributionImage,
        mobileImage: MobileContributionImage,
    }, {
        name: "Woah!",
        description: "Your journey helped us save 120 trees",
        image: ContributionImage,
        mobileImage: MobileContributionImage,
    },];


    return (<div className={'completed-eco-contribution-ticket-detail'}>
        {/*{!isDesktopOrLaptop && <hr className="hr-line"></hr>}*/}
        {/*<div className="eco-contribution-desktop">*/}
        {/*    <div className="eco-contribution">*/}
        {/*	    <p className="eco-heading">Your Eco Contribution</p>*/}
        {/*    </div>*/}

        {/*    {!isDesktopOrLaptop && (*/}
        {/*	    <div className="eco-contribution-carousel">*/}
        {/*		    <div className="carousel-inner">*/}
        {/*			    <Swiper*/}
        {/*				    className={"py-2 px-1"}*/}
        {/*				    spaceBetween={isDesktopOrLaptop ? 32 : 16}*/}
        {/*				    ref={sliderRef}*/}
        {/*				    pagination={{*/}
        {/*					    clickable: true,*/}
        {/*				    }}*/}
        {/*				    navigation={true}*/}
        {/*				    modules={[Pagination, Navigation]}*/}
        {/*				    breakpoints={{*/}
        {/*					    768: {*/}
        {/*						    slidesPerView: 1.2,*/}
        {/*					    },*/}
        {/*					    1: {*/}
        {/*						    slidesPerView: 1.2,*/}
        {/*					    },*/}
        {/*				    }}*/}
        {/*			    >*/}
        {/*				    {EcoContributionData.map((data, index) => {*/}
        {/*					    return (*/}
        {/*						    <SwiperSlide key={index} className="">*/}
        {/*							    <div className="d-flex justify-content-between eco-contribution-box">*/}
        {/*								    <div>*/}
        {/*									    <p className="eco-contribution-box-heading">*/}
        {/*										    {data.name}*/}
        {/*									    </p>*/}
        {/*									    <p className="eco-contribution-box-description">*/}
        {/*										    {data.description}*/}
        {/*									    </p>*/}
        {/*								    </div>*/}
        {/*								    <img*/}
        {/*									    className="eco-contribution-box-image"*/}
        {/*									    src={data.image}*/}
        {/*									    alt="alt"*/}
        {/*								    />*/}
        {/*							    </div>*/}
        {/*						    </SwiperSlide>*/}
        {/*					    );*/}
        {/*				    })}*/}
        {/*			    </Swiper>*/}
        {/*		    </div>*/}
        {/*	    </div>*/}
        {/*    )}*/}
        {/*    <div className="d-flex eco-contrifution-boxes">*/}
        {/*	    {isDesktopOrLaptop &&*/}
        {/*		    EcoContributionData.map((data, index) => (*/}
        {/*			    <div*/}
        {/*				    key={index}*/}
        {/*				    className="d-flex justify-content-between eco-contribution-box"*/}
        {/*			    >*/}
        {/*				    <div>*/}
        {/*					    <p className="eco-contribution-box-heading">{data.name}</p>*/}
        {/*					    <p className="eco-contribution-box-description">*/}
        {/*						    {data.description}*/}
        {/*					    </p>*/}
        {/*				    </div>*/}
        {/*				    <img*/}
        {/*					    className="eco-contribution-box-image"*/}
        {/*					    src={data.mobileImage}*/}
        {/*					    alt="alt"*/}
        {/*				    />*/}
        {/*			    </div>*/}
        {/*		    ))}*/}
        {/*    </div>*/}
        {/*</div>*/}
        {!isDesktopOrLaptop && <hr/>}
        {!isDesktopOrLaptop && <div className={'green-miles-part'}>
            <img className={'green-miles-image'}
                 src={GreenMilesMobileConfirmationImage} alt={'alt'}/>
            <div className={"green-miles-data"}>
                <p className={'green-miles-success'}>Woah!</p>
                <div className={'green-miles-earning'}>
                    This journey,
                    {/* earned <span> {postBookingData_local?.greenMilesEarned} Green Miles</span> and */}
                    saved <span>{postBookingData_local?.savedEmissions}  </span>kgs of tailpipe Carbon Emissions, which is equivalent to planting <span>{Math.floor(postBookingData_local?.savedEmissions/73.84)} </span>fully grown trees.
                </div>
            </div>


        </div>}

        {isDesktopOrLaptop &&
            <div className={'d-flex align-items-center green-miles-confirmation justify-content-between'}>
                <div className={'green-miles-contribution-data'}>
                    <p className={'mb-0 make-impact'}>Making an Impact</p>
                    <p className={'mb-0 make-impact-sub'}>Woah!</p>
                    <p className={'mb-0 greens-data'}>This journey,
                        {/* earned <span>{postBookingData_local?.greenMilesEarned} Green Miles</span> and */}
                        saved <span>{postBookingData_local?.savedEmissions}  </span>kgs of tailpipe Carbon Emissions, which is equivalent to planting <span>{Math.floor(postBookingData_local?.savedEmissions/73.84)} </span>fully grown trees.
                    </p>

                </div>
                <div>
                    <img className={'green-miles-image'} alt={'alt'} src={GreenMilesDesktopConfirmationImage}/>
                </div>
            </div>}
    </div>)
}

export default CompletedEcoContributionTicketDetail
