import React, {useEffect, useState} from "react";
import "./TicketDetailsPage.scss";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import {useMediaQuery} from "react-responsive";
import {useNavigate} from "react-router";
import Navbar from "../../components/Navbar/Navbar";
import {useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {store} from "../../index";
import {DownloadInvoiceUrlData, getTicketUrlData, postBookingData,} from "../../services/api/DataFetching";
import {setRescheduleAction, setTermsConditionsModalAction} from "../../store/Actions/CommonAction";
import {setAuthType} from "../../store/Actions/AuthActions";
import ReviewCardV2 from "../../components/ReviewCards/ReviewCardV2";
import RefundStatusTicketDetail from "./components/RefundStatusTicketDetail";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import UpcomingAccordionTicketDetail from "./components/UpcomingAccordionTicketDetail";
import ChangeBookingModalComponent from "../../components/ChangeBookingModalComponent/ChangeBookingModalComponent";
import QRCodeModalComponent from "../../components/QRCodeModalComponent/QRCodeModalComponent";
import QrComponent from "../../components/QRComponent/QrComponent";
import CompletedFeedBackTicketDetail from "./components/CompletedFeedBackTicketDetail";
import CompletedEcoContributionTicketDetail from "./components/CompletedEcoContributionTicketDetail";
import Footer from "../../components/Footer/Footer";
import {bottomNavList} from "../Confirmation/constants";
import uuid from "react-uuid";
import {capitalizeFirstLetter} from "../../utils/utils";
import TermsConditionsDetail
	from "../../components/TermsConditionsModalContent/TermsConditionsDetail/TermsConditionsDetail";

// const ticketDetailPlaceholders = {
// 	title: '',
// 	image: null,
// 	subTitle: '',
// }
import backWhiteArrow from "../../assets/icons/back-white-arrow.svg"
import refundButtonIcon from "../../assets/icons/refund-policy-icon.svg"
import downloadInvoiceIcon from "../../assets/icons/downloadIcon.svg"
import shareIcon from "../../assets/icons/shareIcon.svg"
import editIcon from "../../assets/icons/edit-icon-grey.svg"
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";

//assets
const sandClockIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/sand-clock-illustration.svg`
const Thumbs = process.env.REACT_APP_CDN_LINK + `assets/images/thumbs.png`
const SupportIcon = process.env.REACT_APP_CDN_LINK + `assets/images/Support.png`
const FaqsIcon = process.env.REACT_APP_CDN_LINK + `assets/images/Faqs.png`
const TAndCIcon = process.env.REACT_APP_CDN_LINK + `assets/images/t&c.png`
const ChatIcon = process.env.REACT_APP_CDN_LINK + `assets/images/Chat.png`
const CallIcon = process.env.REACT_APP_CDN_LINK + `assets/images/call.png`

const LoadingAnimation = process.env.REACT_APP_CDN_LINK + `assets/images/Loading_Amination-white.gif`

const TicketDetailPage = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
	const {selectedBookingIdFromStore, postBookingDataFromStore} = props;
    let localStorageToken = localStorage.getItem("userToken");

	const navigate = useNavigate();
	let pageConstants;
	let pageState = useLocation().state;
	const [ticketDetailPlaceholders, setTicketDetailPlaceholders] = useState({
		title: "",
		image: null,
		subTitle: "",
	});
	const [ticketUrl, setTicketUrl] = useState("");
	const [postBookingData_local, setPostBookingData_local] = useState({});
	const [loading, setLoading] = useState(false);
	const [openRefundStatus, setOpenRefundStatus] = useState(false);
	const [changeBookingModal, setChangeBookingModal] = useState(false);
	const [qrModal, setQrModal] = useState(false);
	const [filterModal, setFilterModal] = useState("");
    const [isReviewed, setIsReviewed] = useState(false);

    let isToken;
	if (store) {
		isToken =
			(localStorageToken !== undefined && localStorageToken?.length > 0) ||
			(store &&
				Object.keys(store.getState().AuthReducer?.tokenData)?.length > 0);
	}

	const toolboxList = [
		{
			icon: shareIcon,
			text: "Share",
			onClick: () => {
                shareHandler();
            },
        },
        {
            icon: downloadInvoiceIcon,
            text: "Download Ticket",
            onClick: () => {
                downloadTicket("download");
            },
        },
        {
            icon: editIcon,
            text: "Manage",
            onClick: (value) => {
                navigationHandler(value);
            },
        },
    ];
    const termsConditionsHandler = (path) => {
        if (isDesktopOrLaptop) {
            store.dispatch(setTermsConditionsModalAction(true));
        } else {
            if (path) {
                navigate(path, {replace: false});
            }
        }
    };

    const toolboxListCompletedTrip = [
        {
            icon: shareIcon,
            text: "Share",
            onClick: () => {
                shareHandler();
            },
        },
        {
            icon: SupportIcon,
            text: "Support",
            onClick: () => {
                navigate("/call");
            },
        },
        {
            icon: FaqsIcon,
            text: "FAQs",
            onClick: () => {
                navigate("/faq");
            },
        },
        {
            icon: TAndCIcon,
            text: "Terms & Conditions",

            onClick: () => {
                termsConditionsHandler("/terms-conditions")
            }

        },
    ];

    const toolboxListCancelledTrip = [
        {
            icon: ChatIcon,
            text: "Chat with Us",
            onClick: () => {
                // eslint-disable-next-line no-undef
                openChat();
                // HaptikSDK.show()
            },
        },
        {
            icon: CallIcon,
            text: "Talk to Us",
            onClick: () => {
                navigate("/call");
            },
        },
        {
            icon: FaqsIcon,
            text: "FAQs",
            onClick: () => {
                navigate("/faq");
            },
        },
        {
            icon: TAndCIcon,
            text: "T & C",

            onClick: () => {
                termsConditionsHandler("/terms-conditions")
            }

        },
    ];


    const toolboxListCancelledTripMobile = [
        {
            icon: shareIcon,
            text: "Share",
            onClick: () => {
                shareHandler();
            },
        },
        {
            icon: FaqsIcon,
            text: "FAQS",
            onClick: () => {
                navigate("/faq");
            },
        },
        {
            icon: TAndCIcon,
            text: "Terms &\n" +
                "Conditions",
            onClick: () => {
                setFilterModal("Terms & Conditions");
            },
        },
        {
            icon: SupportIcon,
            text: "Customer\n" +
                "Support",

            onClick: () => {
                termsConditionsHandler("/call")
            }

        },
    ];



    useEffect(() => {
        if (pageState) {
            if (pageState === "CANCELLED") {
                let tempObj = {
                    title: "Cancelled Trip Details",
                    image: sandClockIllustration,
                    subTitle: "Your ticket has been cancelled",
                    isProcessed: false,
                };

                setTicketDetailPlaceholders(tempObj);
            } else if (pageState === "COMPLETED") {
                let tempObj = {
                    title: "Completed Trip Details",
                    image: Thumbs,
                    subTitle: " Great journey!",
                    isProcessed: false,
                };

                setTicketDetailPlaceholders(tempObj);
            }
        }
        return () => {
        };
    }, [pageState]);

    useEffect(() => {
        setLoading(true);
        let variable = {
            bookingId: selectedBookingIdFromStore,
        };

        postBookingData(variable, (response) => {
            setPostBookingData_local(response);
            setLoading(false);
            store.dispatch(setRescheduleAction(false));
            store.dispatch(setAuthType(""));
            setIsReviewed(response.isReviewed);
        });
        downloadTicket("share");
        return () => {
        };
    }, [isReviewed]);

    if (isDesktopOrLaptop) {
        pageConstants = {
            toolboxText_class: "open-600w-16s-24h white-color mb-0",
        };
    } else {
        pageConstants = {
            toolboxText_class: "open-600w-16s-24h white-color mb-0",
        };
    }

    //methods

    const back = () => {
        navigate(-1);
    };
    const shareHandler = () => {
	    navigator.share({
		    title: "NueGo Bus Ticket",
		    url: ticketUrl
	    })
    };
    const downloadInvoiceUrl = () => {
        let variable = {
            bookingId: store.getState().CommonReducer.selectedBookingId,
        };
        DownloadInvoiceUrlData(variable, (response) => {
            window.open(response.url);
        });
    };

	const downloadTicket = (value) => {

		let variable = {
			bookingId: store.getState().CommonReducer.selectedBookingId
		}

		getTicketUrlData(variable, (data) => {
			if (value === "download") {
				window.open(data.url)
                console.log("url opened")
			} else if (value === "share") {
				setTicketUrl(data.url)
			}

		}, (err) => {
            if(err){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={err[0]?.message || err.toString()}
                        type={"error"}
                    />,
                );
            }
        })
    }

    const navigationHandler = (value) => {
        if (isDesktopOrLaptop) {
            if (value === "Manage" || value === "change") {
                setChangeBookingModal(true);
            }
            if (value === "download") {
	            downloadTicket("download");
            }
        } else {
            if (value === "Manage" || value === "change") {
                navigate("/change-booking", {
                    replace: false,
                    state: {
                        bookingId: store.getState().CommonReducer.selectedBookingId,
                        referenceNo: postBookingDataFromStore.referenceNo,
                    },
                });
            }
            if (value === "download") {
                downloadTicket("download");
            }
        }
    };


    const filterModalHandler = (value) => {
        setFilterModal(value)
    }

    return (
        <AuthModalLayout>
            {loading ? (
                <div
                    style={{height: "50vh", width: "100%"}}
                    className={"d-flex justify-content-center align-items-center"}
                >
                    <img src={LoadingAnimation} alt="loading"/>
                </div>
            ) : (
                <div className={"ticket-details-page black-1E1E26-bg"}>
                    {/* dark fold */}
                    {isDesktopOrLaptop ? (
                        <div className={"navbar-wrapper mb-0"}>
                            <Navbar fixedNavbar={true}/>
                            {pageState === "CANCELLED" && (
                                <div className={" text-center pt-5 mt-5 pb-4"}>
                                    <img
                                        src={ticketDetailPlaceholders.image}
                                        alt={"ticketDetailImage"}
                                        className={"mb-3"}
                                    />
                                    <p className={"open-600w-14s-22h white-color mb-4"}>
                                        {ticketDetailPlaceholders.subTitle}
                                    </p>
                                </div>
                            )}
                            {pageState === "COMPLETED" && (
                                <div className={" text-center pt-5 mt-5 pb-4"}>
                                    <img
                                        src={ticketDetailPlaceholders.image}
                                        alt={"ticketDetailImage"}
                                        className={"mb-3"}
                                    />
                                    <p className={"open-600w-14s-22h white-color mb-4"}>
                    <span style={{fontWeight: 400, color: "#FFFFFFCC"}}>
                      We hope you had a
                    </span>
                                        {ticketDetailPlaceholders.subTitle}
                                    </p>
                                </div>
                            )}
                            {pageState === "UPCOMING" && (
                                <div className={" text-center margin-bottom-100"}></div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {pageState === "CANCELLED" && (
                                <div>
                                    <div
                                        className={
                                            "d-flex justify-content-center position-relative p-4"
                                        }
                                    >
                                        <p className={"open-600w-16s-24h white-color mb-0"}>
                                            {ticketDetailPlaceholders.title}
                                        </p>
                                        <img
                                            src={backWhiteArrow}
                                            className={"icon-24 back-arrow"}
                                            alt={"alt"}
                                            onClick={back}
                                        />
                                    </div>
                                    <div className={" text-center pb-4"}>
                                        <img
                                            src={ticketDetailPlaceholders.image}
                                            alt={"ticketDetailImage"}
                                            className={"mb-3"}
                                        />
                                        <p className={"open-600w-14s-22h white-color mb-4"}>
                                            {ticketDetailPlaceholders.subTitle}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {pageState === "COMPLETED" && (
                                <div>
                                    <div
                                        className={
                                            "d-flex justify-content-center position-relative p-4"
                                        }
                                    >
                                        <p className={"open-600w-16s-24h white-color mb-0"}>
                                            {ticketDetailPlaceholders.title}
                                        </p>
                                        <img
                                            src={backWhiteArrow}
                                            className={"icon-24 back-arrow"}
                                            alt={"alt"}
                                            onClick={back}
                                        />
                                    </div>
                                    <div className={" text-center pb-4"}>
                                        <img
                                            src={ticketDetailPlaceholders.image}
                                            alt={"ticketDetailImage"}
                                            className={"mb-3"}
                                        />
                                        <p className={"open-600w-14s-22h white-color mb-4"}>
                      <span style={{fontWeight: 400, color: "#FFFFFFCC"}}>
                        We hope you had a
                      </span>
                                            {ticketDetailPlaceholders.subTitle}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {/* white fold */}
                    <div className={"white-fold  p-4 "}>
                        <p className={"ubuntu-700w-24s-36h black-1E1E26-color mb-4"}>
                            {ticketDetailPlaceholders.title}
                        </p>
                        {loading ? (
                            <div
                                className={"d-flex justify-content-center align-items-center"}
                            >
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <div className={"px-lg-4 px-lg-3 px-0"}>
                                <div className={"row"}>
                                    <div className={"col-lg-6 col-12"}>
                                        <ReviewCardV2
                                            data={postBookingData_local}
                                            ticketType={pageState}
                                        />

                                        {isDesktopOrLaptop && (
                                            <div
                                                className={
                                                    "toolbox-list-box py-3 px-4 my-3 black-1E1E26-bg d-flex justify-content-around cursor-pointer"
                                                }
                                            >
                                                {pageState === "UPCOMING" && toolboxList.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={"d-flex align-items-center gap-12"}
                                                            onClick={() => item.onClick(item.text)}
                                                        >
                                                            <img
                                                                src={item.icon}
                                                                alt={"alt"}
                                                                className={"icon-24"}
                                                            />
                                                            <p className={pageConstants.toolboxText_class}>
                                                                {item.text}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                                {pageState === "COMPLETED" && toolboxListCompletedTrip.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={"d-flex align-items-center gap-12"}
                                                            onClick={() => item.onClick(item.text)}
                                                        >
                                                            <img
                                                                src={item.icon}
                                                                alt={"alt"}
                                                                className={"icon-24"}
                                                            />
                                                            <p className={pageConstants.toolboxText_class}>
                                                                {item.text}
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                                {pageState === "CANCELLED" && toolboxListCancelledTrip.map((item, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={"d-flex align-items-center gap-12"}
                                                            onClick={() => item.onClick(item.text)}
                                                        >
                                                            <img
                                                                src={item.icon}
                                                                alt={"alt"}
                                                                className={"icon-24"}
                                                            />
                                                            <p className={pageConstants.toolboxText_class}>
                                                                {item.text}
                                                            </p>
                                                        </div>
                                                    );
                                                })}

                                            </div>
                                        )}
                                    </div>
                                    <div className={"col-lg-6 col-12"}>
                                        {pageState === "CANCELLED" ? (
                                            <div>
                                                {isDesktopOrLaptop ? (
                                                    <RefundStatusTicketDetail
                                                        data={postBookingData_local}
                                                    />
                                                ) : (
                                                    <div style={{marginBottom: '85px'}}
                                                         className={"d-flex gap-14 py-4"}>
                                                        <div
                                                            className={"outline-button d-flex gap-12 w-100"}
                                                            onClick={() => setOpenRefundStatus(true)}
                                                        >
                                                            <img
                                                                src={refundButtonIcon}
                                                                alt={"refundButtonIcon"}
                                                                className={"icon-24"}
                                                            />
                                                            <p
                                                                className={
                                                                    "open-600w-16s-24h teal-2-00A095-color whitespace-nowrap mb-0"
                                                                }
                                                            >
                                                                Refund Status
                                                            </p>
                                                        </div>
                                                        <div
                                                            className={"outline-button d-flex gap-12 w-100"}
                                                        >
                                                            <img
                                                                src={downloadInvoiceIcon}
                                                                alt={"downloadInvoiceIcon"}
                                                                className={"icon-24"}
                                                            />
                                                            <p
                                                                className={
                                                                    "open-600w-16s-24h teal-2-00A095-color whitespace-nowrap mb-0"
                                                                }
                                                            >
                                                                Invoice
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : pageState === "UPCOMING" ? (
                                            <div>
                                                <UpcomingAccordionTicketDetail
                                                    qrModal={qrModal}
                                                    setQrModal={(args) => setQrModal(args)}
                                                    data={postBookingData_local}
                                                />
                                            </div>
                                        ) : pageState === "COMPLETED" ? (
                                            <div>
                                                <CompletedFeedBackTicketDetail
                                                    setIsReviewed={setIsReviewed}
                                                    isReviewed={isReviewed}
                                                    bookingId={selectedBookingIdFromStore}
                                                    data={postBookingData_local}
                                                />
                                            </div>
                                        ) : (
                                            <div/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {pageState === "COMPLETED" ? (
                        <CompletedEcoContributionTicketDetail postBookingData_local={postBookingData_local}/>
                    ) : (
                        <div/>
                    )}
                </div>
            )}

            <ChangeBookingModalComponent
                bookingId={store.getState().CommonReducer.selectedBookingId}
                referenceNo={postBookingDataFromStore?.referenceNo}
                activeModal={changeBookingModal}
                setActiveModal={setChangeBookingModal}
            />

            <QRCodeModalComponent
                isOpen={qrModal}
                closeModal={() => setQrModal(false)}
            >
                <QrComponent
                    closeModal={() => setQrModal(false)}
                    link={ticketUrl}
                    qrCode={postBookingDataFromStore.qrCode}
                    customerBookingId={postBookingDataFromStore.pnrNo}
                />
            </QRCodeModalComponent>

            <BottomTrayComponent
                hideClearAll={true}
                isOpen={openRefundStatus}
                closeHandler={() => setOpenRefundStatus(false)}
            >
                <RefundStatusTicketDetail data={postBookingData_local}/>
            </BottomTrayComponent>

            {isDesktopOrLaptop ?
                <Footer/>
                :
                // <div className={"margin-bottom-100"}>
                // 	<MobileBottomNav/>
                // </div>
                <div className={'w-100 settle-at-bottom'}>
                    <div className={'bottom-nav d-flex justify-content-around py-3 '}>
                        {pageState === "UPCOMING" && bottomNavList.map(item => {
                            return <div key={uuid()} className={'text-center'}
                                        onClick={() => navigationHandler(item.id)}>
                                <img className={'icon-24 mb-1'} alt={'alt'} src={item.icon}/>
                                <p className={' text-capitalize white-color opacity-60 mb-0 open-600w-12s-18h'}>{capitalizeFirstLetter(item.name)}</p>
                            </div>
                        })}
                        {pageState === "UPCOMING" && !isToken && bottomNavList.map(item => {
                            return item.id !== "change" && <div key={uuid()} className={'text-center'}
                                        onClick={() => navigationHandler(item.id)}>
                                <img className={'icon-24 mb-1'} alt={'alt'} src={item.icon}/>
                                <p className={' text-capitalize white-color opacity-60 mb-0 open-600w-12s-18h'}>{capitalizeFirstLetter(item.name)}</p>
                            </div>
                        })}
                        {pageState === "COMPLETED" && toolboxListCancelledTripMobile.map((item) => {
                            return (
                                <div key={uuid()} className={'text-center'} onClick={() => item.onClick(item.text)}
                                >
                                    <img className={'icon-24 mb-1'} alt={'alt'} src={item.icon}/>
                                    <p style={{maxWidth: "63px", width: "100%"}}
                                       className={' text-capitalize white-color opacity-60 mb-0 open-600w-12s-18h'}>{(item.text)}</p>
                                </div>
                            );
                        })}
                        {pageState === "CANCELLED" && toolboxListCancelledTripMobile.map((item) => {
                            return (
                                <div key={uuid()} className={'text-center'}
                                     onClick={() => item.onClick(item.text)}
                                >
                                    <img className={'icon-24 mb-1'} alt={'alt'} src={item.icon}/>
                                    <p style={{maxWidth: "63px", width: "100%"}}
                                       className={' text-capitalize white-color opacity-60 mb-0 open-600w-12s-18h'}>{capitalizeFirstLetter(item.text)}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            <BottomTrayComponent
                isOpen={filterModal !== ""}
                hideClearAll={true}
                title={filterModal}
                closeHandler={() => filterModalHandler("")}
            >
                {filterModal === "Terms & Conditions" && (
                    <div className={"terms-condition-details"}>
                        <TermsConditionsDetail/>
                    </div>
                )}
            </BottomTrayComponent>
        </AuthModalLayout>
    );
};

const mapStateToProps = (state) => ({
    selectedBookingIdFromStore: state.CommonReducer.selectedBookingId,
    postBookingDataFromStore: state.CommonReducer.postBookingData,
});

export default connect(mapStateToProps)(TicketDetailPage);
