import React from 'react'
import './QrComponent.scss'
import {useMediaQuery} from "react-responsive";
import cogoToast from "cogo-toast";
import {CustomToast} from "../CustomToast/CustomToast";

//icons
import scanQrIcon from "../../assets/icons/scan-icon.svg"


const QrComponent = (props) => {
    const {qrCode, customerBookingId, link, closeModal} = props
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    let responsiveConstants;
    if (isDesktopOrLaptop) {
        responsiveConstants = {
            title_class: "ubuntu-700w-18s-22h",
            description_classL: "open-600w-14s-22h mb-0",
            bookingId_class: "",
            copyShareClass: "open-400w-16s-22h opacity-60 "
        }
    } else {
        responsiveConstants = {
            title_class: "ubuntu-700w-18s-22h",
            description_classL: "open-600w-14s-22h mb-0",
            bookingId_class: "open-700w-16s-24h mb-0",
            copyShareClass: "open-400w-16s-22h opacity-60 "
        }
    }

    const copyLinkToClipboard = () => {
        navigator.clipboard.writeText(link);
        closeModal()
        cogoToast.success(<CustomToast type={"success"}
                                       message={"Link Copied Successfully"}/>, {position: "top-center"})
    }

    return (
        <div className={'qr-component text-center'}>
            <p className={responsiveConstants.title_class}>Show QR code to scan your ticket</p>
            <div className={'orange-wrapper w-fit-content d-flex mb-4 mx-auto'}>
                <img src={scanQrIcon} alt={'alt'} className={'icon-24 mr-2'}/>
                <p className={responsiveConstants.description_classL}>Scanning will start automatically</p>
            </div>
            {/* qr wrapper */}
            <div className={'wrapper'}>
                <div className={'text-center'}>
                    {qrCode && <img src={qrCode} alt={'alt'} className={'margin-bottom-32 mx-auto'}/>}
                </div>
                {
                    !isDesktopOrLaptop &&
                    <div className={'px-4 pt-3 pb-4 '}>
                        <div className={'booking-id-wrapper d-flex justify-content-center'}>
                            <p className={responsiveConstants.bookingId_class}>Booking Id #{customerBookingId}</p>
                        </div>
                    </div>
                }
            </div>

            {/* copy to share	*/}
            <div className={'text-lg-center text-left'}>
                <p className={responsiveConstants.title_class + " mb-3 "}>Copy link to Share</p>
                <div className={'common-input-wrap p-lg-2 d-flex align-items-center'}>
                    <input className={responsiveConstants.copyShareClass + " border-less-input mb-0 w-100 "}
                           value={link} readOnly={true}/>
                    <button className={'submit-button'} onClick={copyLinkToClipboard}>
                        Copy
                    </button>
                </div>
            </div>
        </div>
    )
}

export default QrComponent
