import React , {useEffect} from 'react'
import './F&BConfirmation.scss'
import Navbar from "../../components/Navbar/Navbar";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import {useMediaQuery} from "react-responsive";
import { useNavigate , Link } from "react-router-dom";
import Footer from '../../components/Footer/Footer';


// assets
const LoadingAnimation = process.env.REACT_APP_CDN_LINK + `assets/images/Loading_Amination-white.gif`

const  FnBConfirmation= (props) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();
    let pageConstants

    if (isDesktopOrLaptop) {
        pageConstants = {
            pageTitle_text: "ubuntu-700w-32s-48h",
            description_text: "ubuntu-700w-24s-32h"
        }
    } else {
        pageConstants = {
            pageTitle_text: "open-700w-24s-36h",
            description_text: "ubuntu-700w-14s-22h"

        }
    }

    useEffect(() => {
        setTimeout(() => {
         navigate("/")
        }, 5000);
      }, []);

    return (

        <AuthModalLayout>
            <div className={'fnb black-1E1E26-bg'}>
                <div className={'navbar-wrapper margin-bottom-100'}>
                    <Navbar />
                </div>

                <div className={'white-fold white-fold-padding'}>
                    <div className={'inner-div'}>
                        <p className={'mb-5 ' + pageConstants.pageTitle_text}>Meal Confirmation</p>
                        <div className={'d-flex justify-content-center text-center align-items-center'}>
                            <div>
                                <img src={LoadingAnimation} alt="NueGo Mealbox"
                                     className={'fnb-illustration'}/>
                                <p className={'' + pageConstants.description_text}>Yum! Your NueGo SuperMeal has been successfully booked</p>
                                <p className={"ubuntu-400w-14s-20h"}>Redirecting you to Home page in 5 seconds...</p>
                                <Link to="/"><button className={'submit-button-fnb'}>
                                    Go To Home
                                </button>
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </AuthModalLayout>
    )
}

export default FnBConfirmation
