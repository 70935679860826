import graphql from "babel-plugin-relay/macro";

const GetSeatArrangmentQuery = graphql`
	   query GetSeatArrangmentQuery($referenceNo: String!) {
	   seatArrangementDetails(referenceNo: $referenceNo) {
	    SeatNo
		Row
		Column
		IsLadiesSeat
		Available
		SeatType
		UpLowBerth
		BlockType
		RowSpan
		ColumnSpan
		SeatCategory
		SeatRate
		IsLowPrice
		OriginalSeatRate
	    }
	   }
	`

export default GetSeatArrangmentQuery;