import React, {useState} from 'react'
import './style.scss'

const ToggleButtonElement = ({toggleButton, setToggleButton}) => {

	return (
		<button type="button" onClick={() => setToggleButton(!toggleButton)}
		        className={"btn btn-lg btn-toggle " + (toggleButton && "active")} data-toggle="button" aria-pressed="false"
		        autoComplete="off">
			<div className="handle"></div>
		</button>
	)
}

export default ToggleButtonElement
