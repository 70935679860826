import React from "react";
import "./CustomToast.scss";

//assets
import InfoIcon from "../../assets/icons/warning-toast-icon.svg"
import ErrorIcon from "../../assets/icons/error-toast-icon.svg"
import SuccessIcon from "../../assets/icons/success-toast-icon.svg"
import closeIcon from "../../assets/icons/cross-gray.svg"

export const CustomToast = (props) => {
    const {type, message, heading} = props;
    return (
        <div className={"custom-toast-component d-flex align-items-center"}>

            {
                type === "warn" &&
                <img src={InfoIcon} alt="icon" className={'illustration-icon icon-40'}/>
            }
            {
                type === "error" &&
                <img src={ErrorIcon} alt="icon" className={'illustration-icon icon-40'}/>
            }
            {
                type === "success" &&
                <img src={SuccessIcon} alt="icon" className={'illustration-icon icon-40'}/>
            }

            <div className={'mx-3'}>
                <p className={'heading ubuntu-500w-18s-28h mb-1'}>{heading}</p>
                <p className={"message open-400w-14s-22h mb-0"}>{message}</p>
            </div>
            <img src={closeIcon} alt="icon" className={'icon-24'}/>
        </div>
    );
}

