import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
    mutation DeleteRemindersInChecklistMutation(
        $ids:[ID]!,
    ){
        deleteReminders(
            ids:$ids,
        ){
            status
            message
            
        }
    }
    `;

export default (variables, callback, errCallback) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.deleteReminders !== null) {
                    callback(response);
                } else {
                    errCallback(err);
                }
            },
            onError: err => {
                errCallback(err);
            }
        }
    )
}