import environment from '../../environment';
import graphql from "babel-plugin-relay/macro";
import {commitMutation} from "react-relay";

const mutation = graphql`
	mutation BookingRescheduleCompleteMutation(
	$bookingId: ID!,
    $paymentMode: Int!,
	) {
		completeRescheduleBooking(
			bookingId:$bookingId,
		    paymentMode:$paymentMode,
			){
				payment {
				    order{
				        id
				    }
				    itemOrderId
				    paymentId
				    jsonData
				    paymentUrl
				}
				isRescheduleCompleted
			}

}`

export default (data, callback, errCallback) => {
	const variables = {
		bookingId: data.bookingId,
		paymentMode: data.paymentMode,
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.completeRescheduleBooking !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			},
			onError: err => {
				errCallback(err);
			},
		},
	)
}

