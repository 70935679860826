import {useMediaQuery} from "react-responsive";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./OfferAvailable.scss";
import {useEffect} from "react";
import {useNavigate} from "react-router";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";

// assets
const NoOfferAvailablemage = process.env.REACT_APP_CDN_LINK + `assets/images/no-offer-image.png`


const OfferAvailable = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
        };
    }, []);
    return (
        <div className={"offer-available-page"}>
            {isDesktopOrLaptop ? (
                <AuthModalLayout>
                    <div
                        style={
                            isDesktopOrLaptop
                                ? {marginBottom: "100px"}
                                : {marginBottom: "78px"}
                        }
                    >
                        <div className={"navbar-wrapper margin-bottom-100"}>
                            <Navbar fixedNavbar={true}/>
                        </div>
                    </div>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                    {isDesktopOrLaptop && <Footer/>}
                </AuthModalLayout>
            ) : (
                <MobileSubScreenLayout back={() => navigate(-1)} title={"Offers"}>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop}/>
                </MobileSubScreenLayout>
            )}
        </div>
    );
};

export const MainContent = ({isDesktopOrLaptop}) => {
    return (
        <div className={"main-content"}>
            {!isDesktopOrLaptop && (
                <div>
                    <div className="px-3 text-center booking-trip-part">
                        <img src={NoOfferAvailablemage} alt={"alt"}/>
                        <h2>No Active Offers</h2>
                        <p>Coming soon with some exciting offers for you</p>
                    </div>
                    <div className="d-flex justify-content-center px-3">
                        <button>Go To Home</button>
                    </div>
                </div>
            )}
            {isDesktopOrLaptop && (
                <div style={{gap: "287px"}} className="d-flex">

                    <div className="desktop-booking-trip">
                        <div>
                            <span className="home">Home</span>
                            <span className="middle">/</span>
                            <span className="offer">Offers</span>
                        </div>
                        <h1>Offers</h1>
                    </div>
                    <div>
                        <div>
                            <div className="px-3  booking-trip-part d-flex flex-column justify-content-center">
                                <img src={NoOfferAvailablemage} alt={"alt"}/>
                                <h2>No Active Offers</h2>
                                <p>Coming soon with some exciting offers for you</p>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center ">
                            <button className="text-nowrap">Go To Home</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OfferAvailable;
