import {commitMutation} from 'react-relay';
import graphql from "babel-plugin-relay/macro";
import environment from '../../environment';

const mutation = graphql`
		mutation SignupMutation($fullName:String,$mobileNumber:String!,$captchaToken:String){
		mobileSignUpOrLogin(fullName:$fullName,mobileNumber:$mobileNumber,captchaToken:$captchaToken){
		    status
		    message
		   
		    }
		   }
	`

export default (data, callback, errCallback) => {
	const variables = {
		fullName: data.fullName,
		mobileNumber: data.mobileNumber,
		captchaToken:data.captchaToken
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.mobileSignUpOrLogin !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}

			},
			onError: err => {

				errCallback(err);
			}
		}
	)
}