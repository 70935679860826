import React, {useEffect, useState} from 'react'
import './PaymentDeductedSeatNotBooked.scss'
import Navbar from "../../components/Navbar/Navbar";
import AuthModalLayout from "../../layout/AuthModalLayout/AuthModalLayout";
import {useMediaQuery} from "react-responsive";
import Footer from "../../components/Footer/Footer";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import {useNavigate} from "react-router";
import {postBookingData} from "../../services/api/DataFetching";
import {capitalizeFirstLetter, getValues} from "../../utils/utils";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";

// assets
const paymentDeductedBookingNotConfirmDesktopImage = process.env.REACT_APP_CDN_LINK + `assets/images/payment-deducted-booking-not-confirm-desktop.png`
const paymentDeductedBookingNotConfirmMobileImage = process.env.REACT_APP_CDN_LINK + `assets/images/payment-deducted-booking-not-confirm-mobile.png`
const RefundStatusImage = process.env.REACT_APP_CDN_LINK + `assets/images/refund-status-image.svg`

const PaymentDeductedSeatNotBooked = () => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    const [bookingData, setBookingData] = useState("")

    // Get the value of the 'id' parameter
    const TicketNumber = params.get('id');

    const navigate = useNavigate()


    useEffect(() => {
        fetchTransactionListData()
    }, [])


    const fetchTransactionListData = () => {
        let variable = {
            bookingId: TicketNumber
        }
        postBookingData(variable, response => {
            setBookingData(response)
        }, err => {
            if(err){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={err[0]?.message || err.toString()}
                        type={"error"}
                    />,
                );
            }
            setBookingData([])
        })
    }


    return (
        <>
            {
                isDesktopOrLaptop ?
                    <AuthModalLayout>
                        <div className={'page-not-found black-1E1E26-bg'}>
                            <div className={'navbar-wrapper margin-bottom-100'}>
                                <Navbar removeFixed={false}/>
                            </div>
                            <PaymentIssue TicketNumber={TicketNumber} bookingData={bookingData}/>
                            <Footer/>
                        </div>
                    </AuthModalLayout> : <>
                        <MobileSubScreenLayout back={() => navigate(-1)} title={"Booking Confirmation"}>
                            <PaymentIssue TicketNumber={TicketNumber} bookingData={bookingData}/>
                        </MobileSubScreenLayout>
                    </>
            }
        </>
    )
}

export default PaymentDeductedSeatNotBooked


export const PaymentIssue = (props) => {
    const {TicketNumber, bookingData} = props
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    // const [paymentMode, setPaymentMode] = useState((getValues("PaymentModeEnum")[2]))
    const [paymentMode, setPaymentMode] = useState()

    let pageConstants
    if (isDesktopOrLaptop) {
        pageConstants = {
            pageTitle_text: "ubuntu-700w-32s-48h",
            description_text: "ubuntu-700w-24s-32h"
        }
    } else {
        pageConstants = {
            pageTitle_text: "ubuntu-700w-24s-36h",
            description_text: "ubuntu-700w-14s-22h"

        }
    }
    const navigate = useNavigate()
    // Todo - this need to be changed dynamicly - paymentmode - schema
    useEffect(() => {
	    if (getValues("PaymentModeEnumV2").includes(bookingData?.paymentType?.toString())) {
		    if (bookingData?.paymentType?.toString() === "PINELABS") {
			    setPaymentMode("PineLabs")
		    } else if (bookingData?.paymentType?.toString() === "JUSPAY") {
			    setPaymentMode("JusPay")
		    } else {
			    setPaymentMode(capitalizeFirstLetter(bookingData?.paymentType?.toString()))
		    }
	    } else if (bookingData?.paymentType === null) {
		    setPaymentMode("Wallet")
	    }
    }, [bookingData])


    return (
        <div className={'page-not-found'}>
            <div className={'white-fold white-fold-padding'}>
                <div className={'inner-div'}>
                    {
                        isDesktopOrLaptop &&
                        <p className={'mb-5 booking-confirmation ' + pageConstants.pageTitle_text}>Booking
                            Confirmation</p>
                    }
                    <div className={isDesktopOrLaptop ? "d-flex gap-37 justify-content-center align-items-center" : ""}>
                        <div
                            className={'d-flex justify-content-center text-center align-items-center refund-status-image-part'}>
                            <div>
                                <img
                                    src={isDesktopOrLaptop ? paymentDeductedBookingNotConfirmDesktopImage : paymentDeductedBookingNotConfirmMobileImage}
                                    alt="Page not found"
                                    className={'page-not-found-illustration'}/>
                                <p className={'ticket-no ' + pageConstants.description_text}>Oops! Your seat is not
                                    booked for
                                    ticket <span>  {TicketNumber ? "#: " + TicketNumber : ""}</span></p>
                                <p className={'ticket-issue'}>This seat is already booked. We regret the inconvenience
                                    caused.</p>
                            </div>
                        </div>
                        <div className={'refund-status'}>
                            <p className={'refund-status-title'}>
                                Refund Status
                            </p>
                            <div className={"refund-status-process d-flex align-items-center"}>
                                <div>
                                    <img src={RefundStatusImage} alt={'alt'}/>
                                </div>
                                <div>
                                    <p className={'refund-status-process-title'}>Your refund has been processed</p>
                                    <p className={'refund-status-process-description'}><span>Refund </span>is processed
                                        instantly from our side. However, it may take up to
                                        7
                                        working days with the Provider for the same to reflect in your account</p>
                                </div>
                            </div>
                            <div>
                                {bookingData?.cancellationTransactionId &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Transaction ID</p>
                                        <p className={'payment-data'}>{bookingData?.cancellationTransactionId}</p>
                                    </div>}
                                {paymentMode &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Payment Gateway</p>
                                        <p className={'payment-data'}>{paymentMode}</p>
                                    </div>}

                                {bookingData?.initialPaymentDate &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Payment Date</p>
                                        <p className={'payment-data'}>{bookingData?.initialPaymentDate}, {bookingData?.initialPaymentTime && bookingData?.initialPaymentTime}</p>
                                    </div>}

                                {bookingData?.getTotalPaidAmount &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Total Fare</p>
                                        <p className={'payment-data'}>₹{bookingData?.getTotalPaidAmount}</p>
                                    </div>}

                                {bookingData?.cancellationCharges &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Cancellation Charges</p>
                                        <p className={'payment-data'}>₹{bookingData?.cancellationCharges}</p>
                                    </div>}
                                {bookingData?.totalRefundedAmount &&
                                    <div className={'d-flex justify-content-between payment-details'}>
                                        <p className={'payment-description'}>Refund Amount</p>
                                        <p className={'payment-data'}>₹{bookingData?.totalRefundedAmount}</p>
                                    </div>}
                            </div>

                        </div>
                    </div>
                    <div className={'d-flex align-items-center justify-content-center submit-btn-section'}>

                        <button onClick={() => navigate("/")} className={'submit-button w-fit-content '}>
                            Try Booking Again
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )
}


