import React, {useState} from 'react'
import {useMediaQuery} from "react-responsive";


const WorldImage = process.env.REACT_APP_CDN_LINK + `assets/images/world-image.png`
const CombinedImageWhite = process.env.REACT_APP_CDN_LINK + `assets/images/Combined-Shape-white.png`
const CombinedShapeImage = process.env.REACT_APP_CDN_LINK + `assets/images/Combined-Shape-green.png`


const ProfileEcoContribution = (props) => {
    const {Toggle, setToggle, profileDataLocal} = props

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [greenMilesDataShow, setGreenMilesDataShow] = useState(false)


    return (<div className={'profile-eco-contribution-card'}>
        <div className={''}>
            <div className={'d-flex justify-content-between align-items-center'}>
                <p className={'eco-contribution-title  mb-0'}>Your Eco-Contribution</p>
                {/*<p className={' eco-contribution-share mb-0'}>*/}
                {/*    <img className={'share-image'} src={ShareIcon} alt={'alt'}/>*/}
                {/*    Share</p>*/}
            </div>

            <div className={isDesktopOrLaptop ? "d-flex  gap-14" : ""}>
                <div className={'saved-population'}>
                    <div
                        className={isDesktopOrLaptop ? "d-flex gap-24 align-items-center" : 'd-flex justify-content-between'}>
                        <p className={'mb-0  saved-population-title'}>
                            Total Saved Pollution
                        </p>
                        <img className={'combined-shape cursor-pointer'}
                             onMouseOver={() => setGreenMilesDataShow(!greenMilesDataShow)}
                             onClick={() => !isDesktopOrLaptop && setToggle(true)}
                             src={isDesktopOrLaptop ? CombinedImageWhite : CombinedShapeImage} alt={'alt'}/>
                    </div>
                    <div className={'d-flex  align-items-center gap-14 mt-1'}>
                        <img
                            className={'combined-shape '} src={WorldImage} alt={'alt'}/>
                        <p className={'mb-0 population-count'}>{profileDataLocal?.savedEmissions}kg</p>
                    </div>
                    {greenMilesDataShow && isDesktopOrLaptop && <div className={'green-miles-text'}>
                        You have saved {profileDataLocal?.savedEmissions}kg of carbon emissions since December, 2022.
                    </div>}

                </div>
                {/*<div className={'saved-population'}>*/}
                {/*    <div*/}
                {/*        className={isDesktopOrLaptop ? "d-flex gap-24 align-items-center" : 'd-flex justify-content-between'}>*/}
                {/*        <p className={'mb-0  saved-population-title'}>*/}
                {/*            Monthly Earned Green Miles*/}
                {/*        </p>*/}
                {/*        <img className={'combined-shape'}*/}
                {/*             src={isDesktopOrLaptop ? CombinedImageWhite : CombinedShapeImage} alt={'alt'}/>*/}
                {/*    </div>*/}
                {/*    <div className={'d-flex  align-items-center gap-14 mt-1'}>*/}
                {/*        <img className={'combined-shape'} src={GreenMilesImage} alt={'alt'}/>*/}
                {/*        <p className={'mb-0 population-count'}>60</p>*/}
                {/*    </div>*/}

                {/*    <div className={'earned-world-image'}>*/}
                {/*        <img src={EarnedBmMobileImage} alt={'alt'}/>*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>


        </div>

        {/*bottom tray*/}


    </div>)
}

export default ProfileEcoContribution