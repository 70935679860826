import React, {useEffect, useState} from 'react';
import '../ProfileDetail.scss';
import {useMediaQuery} from "react-responsive";
import {getCoPassengerListData} from "../../../services/api/DataFetching";
import {MDBModal, MDBModalBody} from "mdbreact";
import CoPassengerDetailForm from "../../../components/CoPassengerDetailForm/CoPassengerDetailForm";
import BottomTrayComponent from "../../../components/BottomTrayComponent/BottomTrayComponent";
import {DeleteCoPassengerData} from "../../../services/api/DataUpdating";

//assets
import mobileIcon from "../../../assets/icons/mobile-icon-gray.svg"
import genderIcon from "../../../assets/icons/gender-icon-gray.svg"
import personIcon from "../../../assets/icons/profile-icon-outline.svg"
import deleteIcon from "../../../assets/icons/red-delete-icon.svg"
import editIcon from "../../../assets/icons/green-penciledit.svg"
import addIcon from "../../../assets/icons/add-icon-teal.svg"
import mobileIcon_mobile from "../../../assets/icons/mobile-icon-green.svg"
import genderIcon_mobile from "../../../assets/icons/mobile-icon-green.svg"
import personIcon_mobile from "../../../assets/icons/profile-icon-outline-green.svg"
import closeIcon from "../../../assets/icons/cross-gray.svg"
import checkedIcon from "../../../assets/icons/checked-green.svg"
import uncheckedIcon from "../../../assets/icons/unchecked.svg"

const ProfileCopassengerDetail = () => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    const [coPassengerList_local, setCoPassengerList_local] = useState([]);
    const [modal, setModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedCoPassengerData, setSelectedCoPassengerData] = useState({});
    const [componentUpdation, setComponentUpdation] = useState(0);

    let componentData
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color mb-1",
            subTitle_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer whitespace-nowrap",
            secondary_text_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
            name_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
            mobileIcon: mobileIcon,
            genderIcon: genderIcon,
            personIcon: personIcon,
            icon_size: "icon-20"
        }
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color mb-1",
            subTitle_class: "open-400w-12s-18h grey-2-78787d-color",
            outlineCta_class: "d-flex align-items-center " +
                " open-400w-12s-18h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            secondary_text_class: "open-400w-14s-22h grey-2-78787d-color mb-0",
            name_class: "open-700w-16s-24h black-1E1E26-color mb-0",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
            mobileIcon: mobileIcon_mobile,
            genderIcon: genderIcon_mobile,
            personIcon: personIcon_mobile,
            icon_size: "icon-16"
        }
    }

    useEffect(() => {
        getCoPassengerListData((data) => {
            if(data.length > 0 && data[0] !== null){
                setCoPassengerList_local(data)
            } else{
                setCoPassengerList_local([])
            }
        }, () => {
            setCoPassengerList_local([])
        })
        return () => {

        };
    }, [modal, componentUpdation]);

    const addNewHandler = () => {
        setModal(true)
        setEditMode(false)
        setSelectedCoPassengerData({})
    }

    const editHandler = (data) => {
        setSelectedCoPassengerData(data)
        setEditMode(true)
        setModal(true)
    }

    const closeModalHandler = () => {
        setEditMode(false)
        setModal(false)
        setSelectedCoPassengerData({})
    }

    const deleteHandler = (id) => {
        let variables = {
            id: id
        }

        DeleteCoPassengerData(variables, () => {
            setComponentUpdation(componentUpdation + 1)
        }, () => {
        })
    }


    const filteredLocalData = coPassengerList_local.length > 0 ? coPassengerList_local.filter((data, index, array) => {
        return (
        array.findIndex((item) => item?.name?.toUpperCase() === data?.name?.toUpperCase() && item?.mobileNumber === data?.mobileNumber) === index
        );
        }) : []
        
    return (
        <div>
            <div className={'profile-copassenger-detail-component  px-3'}>
                {/* heading */}
                <div className={'d-flex justify-content-between align-items-center'}>
                    {/*left */}
                    <div className={'d-flex w-100'}>

                        <div className={'ml-3 ml-lg-0 w-100'}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                                <p className={componentData.title_class}>Co-Passengers</p>
                                {!isDesktopOrLaptop &&
                                    <div className={componentData.outlineCta_class} onClick={addNewHandler}>
                                        <p className={'mb-0 '}>Add New</p>
                                        <img src={addIcon} className={'icon-16 ml-2'} alt={'add'}/>
                                    </div>}
                            </div>
                            <p className={componentData.subTitle_class}>Add your travel buddies. Long press a card to
                                remove
                                an
                                existing entry</p>
                        </div>
                    </div>

                    {/* right */}
                    {isDesktopOrLaptop && <div className={componentData.outlineCta_class} onClick={addNewHandler}>
                        <p className={'mb-0 '}>Add New</p>
                    </div>}
                </div>

                {/*	 passenger list */}

                {
                    filteredLocalData?.length > 0 && <div className={'passenger-list-wrapper '}>
                        {
                            filteredLocalData.map((item, index) => {
                                return <div key={index}
                                            className={'passenger-item-wrap d-flex justify-content-between align-items-center'}>

                                    <div className={'w-100'}>
                                        <div className={'d-flex justify-content-between align-items-center pb-2 pb-lg-0'}>

                                            <p className={componentData.name_class}>{item.name}</p>


                                            {!isDesktopOrLaptop && <div className={'d-flex gap-12'}>
                                                <img src={deleteIcon} alt={'alt'} className={'delete-icon-wrap icon-20'}
                                                     onClick={() => deleteHandler(item.id)}
                                                />
                                                <div className={'edit-icon-wrap d-flex align-items-center gap-12'}
                                                     onClick={() => editHandler(item)}
                                                >
                                                    <img src={editIcon} alt={'alt'} className={'icon-20'}/>
                                                    {isDesktopOrLaptop &&
                                                        <p className={'teal-2-00A095-color open-600w-16s-24h mb-0'}>Edit</p>}
                                                </div>
                                            </div>}
                                        </div>

                                        <div className={'d-flex justify-content-between align-items-center pb-2 pb-lg-0'}>
                                             <div className="d-flex align-items-center gap-2">
                                             <img src={item?.isTrusted ? checkedIcon : uncheckedIcon} alt={'trusted'}
                                                    className='icon-18'/>
								            <p className={`${componentData.subTitle_class} mb-0`}>{item?.isTrusted ? 'Trusted Contact' : 'Mark as Trusted Contact'}</p>
                                            </div>


                                        </div>

                                        {!isDesktopOrLaptop && <hr className={'horizontal-divider'}/>}

                                        <div className={'d-flex align-items-center justify-content-lg-start ' +
                                            'justify-content-between ' +
                                            '  gap-12 pt-2 '}>
                                            <div className={'d-flex align-items-center'}>
                                                <img src={componentData.mobileIcon} alt={'alt'}
                                                     className={componentData.icon_size + ' mr-1'}/>
                                                <p className={componentData.secondary_text_class}>{item.mobileNumber}</p>
                                            </div>
                                            <div className={'dot-seperator opacity-40'}/>
                                            <div className={'d-flex align-items-center '}>
                                                <img src={componentData.genderIcon} alt={'alt'}
                                                     className={componentData.icon_size + ' mr-1'}/>
                                                <p className={componentData.secondary_text_class}>{item.gender}</p>
                                            </div>
                                            <div className={'dot-seperator opacity-40'}/>
                                            <div className={'d-flex align-items-center '}>
                                                <img src={componentData.personIcon} alt={'alt'}
                                                     className={componentData.icon_size + ' mr-1'}/>
                                                <p className={componentData.secondary_text_class}>{item.age} y</p>
                                            </div>
                                        </div>
                                    </div>
                                    {isDesktopOrLaptop && <div className={'d-flex gap-12'}>

                                        <img src={deleteIcon} alt={'alt'} className={'delete-icon-wrap icon-20'}
                                             onClick={() => deleteHandler(item.id)}
                                        />
                                        <div className={'edit-icon-wrap d-flex align-items-center gap-12'}
                                             onClick={() => editHandler(item)}
                                        >
                                            <img src={editIcon} alt={'alt'} className={'icon-20'}/>
                                            {isDesktopOrLaptop &&
                                                <p className={'teal-2-00A095-color open-600w-16s-24h mb-0'}>Edit</p>}
                                        </div>
                                    </div>}
                                </div>
                            })
                        }
                    </div>
                }

            </div>

            {/*	 modal */}
            {
                isDesktopOrLaptop ?
                    <div className={'desktop-modal'}>
                        <MDBModal isOpen={modal} position={'center'} centered={true}
                                  size={"fluid"}>
                            <MDBModalBody>
                                <div className={'p-4 co-passenger-modal-wrapper'}>
                                    <img src={closeIcon} alt={'alt'}
                                         onClick={() => setModal(false)}
                                         className={'close-icon-wrap icon-20'}/>
                                    <CoPassengerDetailForm
                                        editMode={editMode}
                                        setEditMode={setEditMode}
                                        closeModal={closeModalHandler}
                                        coPassengerData={selectedCoPassengerData}
                                        coPassengerList_local={coPassengerList_local}

                                    />
                                </div>
                            </MDBModalBody>
                        </MDBModal>
                    </div>
                    :
                    <div className={'mobile-modal'}>
                        <BottomTrayComponent
                            isOpen={modal} closeHandler={() => setModal(false)}
                            hideClearAll={true}
                        >
                            <CoPassengerDetailForm
                                editMode={editMode}
                                setEditMode={setEditMode}
                                closeModal={closeModalHandler}
                                coPassengerData={selectedCoPassengerData}
                                coPassengerList_local={coPassengerList_local}
                            />
                        </BottomTrayComponent>
                    </div>
            }

        </div>

    )
}

export default ProfileCopassengerDetail
