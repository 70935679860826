import React, {useEffect, useState} from "react";
import "./SubScreens.scss";
import {useMediaQuery} from "react-responsive";
import {setBoardingAndDroppingPoint} from "../../../services/api/DataFetching";
import {store} from "../../../index";
import ModifyResultComponent from "./ModifyResultComponent";


//icons
import activeRadioButtonIcon from "../../../assets/icons/active-green-radio-button.svg"
import inactiveRadioButtonIcon from "../../../assets/icons/inactive-radio-icon.svg"
import callIcon from "../../../assets/icons/call-icon.svg"
import downGrayArrow from "../../../assets/icons/keyboard_arrow_downdown-facing-cavet-grey.svg"
import cogoToast from "cogo-toast";
import {CustomToast} from "../../CustomToast/CustomToast";

const ModifyBooking = (props) => {
    const {submitHandler, referenceNo, bookingId, modifyStatus, errorMessage} =
        props;
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [activeTab, setActiveTab] = useState("drop");
    const [activePoint, setActivePoint] = useState({
        pickup: "",
        pickupName: "",
        drop: "",
        dropName: "",
    });
    const [points, setPoints] = useState({});
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    let componentConstant;
    const PostBooingDataFromStore =
        store.getState().CommonReducer.postBookingData;

    const [mobileNumber, setMobileNumber] = useState(
        PostBooingDataFromStore?.contact?.bookingContactDetails[0]?.contact?.phone
    );
    const defaultMobile = PostBooingDataFromStore?.contact?.bookingContactDetails[0]?.contact?.phone

    //use effects
    useEffect(() => {
        let variable = {
            referenceNo: referenceNo,
        };
        setBoardingAndDroppingPoint(
            variable,
            (response) => {
                setPoints(response);
            },
            (error) => {
                if(error){
                    cogoToast.error(
                        <CustomToast
                            heading={"Error"}
                            message={error[0]?.message || error.toString()}
                            type={"error"}
                        />,
                    );
                }
            }
        );
        return () => {
            // console.log('useEffect return', data)
            // setPoints(data)
        };
    }, [activeTab]);

    if (isDesktopOrLaptop) {
        componentConstant = {
            title_class: "open-600w-16s-24h",
            tabtext_class: "open-600w-16s-24h mb-0",
            description_class: "open-400w-16s-24h opacity-60",
            activeDescription_class: "open-600w-16s-24h opacity-100",
            largerTitle: "open-600w-18s-24h",
            submitButtonWrap_class:
                "d-flex justify-content-end open-600w-18s-28h padding-top-side-local",
            submitButton_class: "submit-button",
        };
    } else {
        componentConstant = {
            title_class: "open-600w-16s-24h",
            tabtext_class: "open-600w-16s-24h mb-0",
            description_class: "open-400w-16s-24h opacity-60",
            activeDescription_class: "open-600w-16s-24h opacity-100",
            largerTitle: "open-600w-18s-24h",
            submitButtonWrap_class:
                "d-flex w-100 open-600w-16s-24h padding-top-side-local",
            submitButton_class: "submit-button w-100",
        };
    }

    //methods
    const handlePhoneNumber = (value) => {
        setMobileNumber(value);
    };

    const phoneNumberValidation = () => {
        if (mobileNumber.length === 10 && mobileNumber[0] >= 6) {
            setPhoneNumberError(false);
        } else {
            setPhoneNumberError(true);
        }
    };

    const localSubmitHandler = () => {
        let data = {
            bookingId: bookingId,
            pickupCityId: activePoint.pickup,
            pickupCityName: activePoint.pickupName,
            dropCityId: activePoint.drop,
            dropCityName: activePoint.dropName,
            mobileNumber: mobileNumber,
        };
        submitHandler(data);
    };

    const pickupPointHandler = (value, name) => {
        setActivePoint({
            ...activePoint,
            pickup: value,
            pickupName: name,
        });
    };

    const dropPointHandler = (value, name) => {
        setActivePoint({
            ...activePoint,
            drop: value,
            dropName: name,
        });
    };

    return (
        <>
            {modifyStatus === "" && (
                <div className={"modify-booking-component"}>
                    <div className={"grey-area padding-x-local py-lg-4 py-3"}>
                        <p className={componentConstant.title_class + " mb-3"}>
                            What would you like to modify?
                        </p>

                        <div className={"tab-wrap d-flex margin-bottom-32"}>
                            <div
                                className={
                                    activeTab === "drop" ? " active-tab " : " in-active-tab "
                                }
                                onClick={() => setActiveTab("drop")}
                            >
                                <p className={componentConstant.tabtext_class}>Drop</p>
                            </div>
                            <div
                                className={
                                    activeTab === "pickup" ? " active-tab " : " in-active-tab "
                                }
                                onClick={() => setActiveTab("pickup")}
                            >
                                <p className={componentConstant.tabtext_class}>Pick Up</p>
                            </div>
                        </div>

                        {/* pick up and drop points listing */}
                        <p className={componentConstant.description_class}>
                            Please let us know the {activeTab} point
                        </p>
                        {activeTab === "pickup" ? (
                            <div className={"points-wrap"}>
                                {points?.pickupPoints?.length > 0 &&
                                    points?.pickupPoints.map((point, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={"d-flex"}
                                                onClick={() =>
                                                    pickupPointHandler(point.PickupID, point.PickupName)
                                                }
                                            >
                                                <img
                                                    src={
                                                        activePoint.pickup === point.PickupID
                                                            ? activeRadioButtonIcon
                                                            : inactiveRadioButtonIcon
                                                    }
                                                    alt={"radio button"}
                                                    className={"icon-24 mr-2"}
                                                />
                                                <p
                                                    className={
                                                        " mb-0 " + activePoint.pickup === point.PickupID
                                                            ? componentConstant.activeDescription_class
                                                            : componentConstant.description_class
                                                    }
                                                >
                                                    {point.PickupName}
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                        ) : (
                            <div className={"points-wrap"}>
                                {points?.dropPoints?.length > 0 &&
                                    points?.dropPoints.map((point, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={"d-flex"}
                                                onClick={() =>
                                                    dropPointHandler(point.DropID, point.DropName)
                                                }
                                            >
                                                <img
                                                    src={
                                                        activePoint.drop === point.DropID
                                                            ? activeRadioButtonIcon
                                                            : inactiveRadioButtonIcon
                                                    }
                                                    alt={"radio button"}
                                                    className={"icon-24 mr-2"}
                                                />
                                                <p
                                                    className={
                                                        " mb-0 " + activePoint.drop === point.DropID
                                                            ? componentConstant.activeDescription_class
                                                            : componentConstant.description_class
                                                    }
                                                >
                                                    {point.DropName}
                                                </p>
                                            </div>
                                        );
                                    })}
                            </div>
                        )}

                        <hr className={"horizontal-divider"}/>

                        <div className={"d-flex"}>
                            <img
                                src={callIcon}
                                alt={"call icon"}
                                className={"icon-24 mr-3"}
                            />
                            <p className={componentConstant.largerTitle}>
                                Update Contact Number
                            </p>
                        </div>
                        <div className={"d-flex gap-12"}>
                            <div>
                                <p className={componentConstant.description_class}>Code</p>
                                <div className={"common-input-wrap white-bg d-flex"}>
                                    <p className={componentConstant.title_class + " mb-0 "}>
                                        +91
                                    </p>
                                    <img
                                        src={downGrayArrow}
                                        alt={"down arrow"}
                                        className={"icon-24"}
                                    />
                                </div>
                            </div>
                            <div>
                                <p className={componentConstant.description_class}>
                                    Mobile Number
                                </p>
                                <div
                                    className={
                                        "common-input-wrap white-bg " +
                                        (phoneNumberError && " common-input-wrap-error ")
                                    }
                                >
                                    <input
                                        type={"number"}
                                        onChange={(e) => handlePhoneNumber(e.target.value)}
                                        min="0"
                                        pattern="/^-?\d+\.?\d*$/"
                                        onKeyPress={(e) =>
                                            e.target.value.length === 10 ? e.preventDefault() : null
                                        }
                                        onBlur={phoneNumberValidation}
                                        value={mobileNumber}

                                    />
                                </div>
                                {phoneNumberError && (
                                    <div>
                                        <p className={""}>Invalid phone Number</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={componentConstant.submitButtonWrap_class}>
                        <button
                            className={componentConstant.submitButton_class}
                            disabled={
                                ((mobileNumber === defaultMobile) && (activePoint.pickup === "" && activePoint.drop === "")) || (phoneNumberError)
                            }
                            onClick={localSubmitHandler}
                        >
                            Submit Modification
                        </button>
                    </div>
                </div>
            )}
            {modifyStatus === "success" && (
                <ModifyResultComponent
                    success={true}
                    message={"We will update the Coach Host with new details "}
                />
            )}
            {modifyStatus === "fail" && (
                <ModifyResultComponent success={false} message={errorMessage}/>
            )}
        </>
    );
};

export default ModifyBooking;
