import React, { useEffect, useState } from "react";
import "./GuestUserMobile.scss";
import { useMediaQuery } from "react-responsive";
import MobileSubScreenLayout from "../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import { useNavigate } from "react-router";
import { setAuthType } from "../../store/Actions/AuthActions";
import { connect } from "react-redux";
import { CustomToast } from "../../components/CustomToast/CustomToast";
import cogoToast from "cogo-toast";

// assets
import guestUserFailureIllustration from "../../assets/images/guest-user-failure-illustration.png";
import { store } from "../..";
import { GetBookingDetailsByPnrAndNameData } from "../../services/api/DataUpdating";

const ErrorImage =
  process.env.REACT_APP_CDN_LINK + `assets/images/Bold-Outline.png`;
const GuestUserBookingMobileImage =
  process.env.REACT_APP_CDN_LINK + `assets/images/guest-user-booking-state.png`;

export const GuestUserBookingMobile = (props) => {
  const { setTypeToStore } = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [touch, setTouch] = useState({ name: false, pnr: false });
  const [name, setName] = useState("");
  const [pnrNumber, setPnrNumber] = useState("");
  const [guestUserFailureScreen, setGuestUserFailureScreen] = useState(false);
  const [guestUserManageBookingError, setGuestUserManageBookingError] =
    useState({
      pnr: "",
      name: "",
    });

  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktopOrLaptop) {
      store.dispatch(setAuthType("guest"));
      navigate("/", { replace: true });
    }
  }, [isDesktopOrLaptop]);

  const onChangeInputHandler = (e, type) => {
    if (type === "name") {
      setName(e.target.value);
    } else if (type === "pnr") {
      setPnrNumber(e.target.value);
    }
  };

  const blurFunction = (e, type) => {
    if (type === "pnr" && pnrNumber === "") {
      setGuestUserManageBookingError({ pnr: "error", name: "" });
    } else if (type === "name" && name === "") {
      setGuestUserManageBookingError({ pnr: "", name: "error" });
    } else {
      setGuestUserManageBookingError({ pnr: "", name: "" });
    }
  };
  // const guestUserSubmission = () => {
  //	setGuestUserFailureScreen(true);
  // };

  const guestUserSubmission = () => {
    if (pnrNumber === "") {
      setGuestUserManageBookingError({ pnr: "error", name: "" });
    } else if (name === "") {
      setGuestUserManageBookingError({ pnr: "", name: "error" });
    } else {
      let variable = {
        pnrNo: pnrNumber,
        name: name,
      };
      GetBookingDetailsByPnrAndNameData(
        variable,
        (response) => {
          navigate("/confirmation", { replace: false });
          // if(response){
          // 	console.log(response[0]?.message ,'res')
          // }
        },
        (error) => {
          if (error[0].message.toLowerCase().includes("booking not found")) {
            setGuestUserFailureScreen(true);
          } else {
            cogoToast.error(
              <CustomToast
                message={error[0].message}
                type={"error"}
                heading={"Error"}
              />,
              { position: "top-center" }
            );
          }
        }
      );
      setGuestUserManageBookingError({ pnr: "", name: "" });
    }
  };

  const handleTouch = (field) => {
    setTouch({
      ...touch,
      [field]: true,
    });
  };

  return (
    <>
      {
        <>
          <MobileSubScreenLayout
            back={() => navigate(-1)}
            title={"Manage Booking"}
          >
            <div className={"guest-user-booking"}>
              <div className={"white-fold white-fold-padding"}>
                <div className={"inner-div"}>
                  <div
                    className={
                      "d-flex justify-content-center  align-items-center"
                    }
                  >
                    {guestUserFailureScreen ? (
                      <div className="guest-user-failure my-auto text-center d-flex flex-column justify-content-between">
                        <img
                          src={guestUserFailureIllustration}
                          alt="failure illustration"
                          className="guest-user-failuer-image mx-auto"
                        />
                        <p className="ubuntu-500w-20s-32h black-1E1E26-color mb-3">
                          Sorry! You can’t manage your booking from NueGo
                        </p>
                        {/* <p className='open-400w-14s-22h margin-botom-32 grey-2-78787d-color'>
													Your ticket has been booked via Redbus. Click below to
													manage your booking.
												</p> */}
                        {/*<button*/}
                        {/*    className={"submit-button settle-at-bottom text-center "}*/}
                        {/*>*/}
                        {/*    Go To RedBus*/}
                        {/*</button>*/}
                      </div>
                    ) : (
                      <div className="guest-user-form">
                        <img
                          src={GuestUserBookingMobileImage}
                          alt="Page not found"
                          className={
                            "page-not-found-illustration d-flex mx-auto"
                          }
                        />
                        <p className={"ticket-issue"}>
                          Enter the details below and help us retrieve your
                          ticket for you.
                        </p>

                        <div className={"guest-user-input-searches"}>
                          <>
                            <label className="open-400w-14s-22h grey-2-78787d-color">
                              PNR
                            </label>
                            <br />
                            <input
                              autoFocus
                              className={
                                "guest-user-guest-booking  " +
                                (guestUserManageBookingError.pnr !== "" &&
                                  "error-state-border")
                              }
                              placeholder={"Enter your PNR Number"}
                              value={pnrNumber}
                              onBlur={(e) => blurFunction(e, "pnr")}
                              onClick={(e) => handleTouch("pnr")}
                              onChange={(e) => onChangeInputHandler(e, "pnr")}
                            />
                            {guestUserManageBookingError.pnr !== "" && (
                              <div
                                className={
                                  "d-flex  align-items-center validation-part"
                                }
                              >
                                <img alt={"error-image"} src={ErrorImage} />
                                <p className={"error-msg"}>
                                  Please enter a valid PNR
                                </p>
                              </div>
                            )}
                          </>
                          <>
                            <label className=" name-label open-400w-14s-22h grey-2-78787d-color">
                              First Name
                            </label>
                            <br />
                            <input
                              onChange={(e) => onChangeInputHandler(e, "name")}
                              autoFocus
                              className={
                                "guest-user-guest-booking " +
                                (guestUserManageBookingError.name !== "" &&
                                  "error-state-border")
                              }
                              placeholder={"Enter your First Name"}
                              value={name}
                              onClick={(e) => handleTouch("name")}
                              onBlur={(e) => blurFunction(e, "name")}
                            />
                            {guestUserManageBookingError.name !== "" && (
                              <div
                                className={
                                  "d-flex  align-items-center validation-part"
                                }
                              >
                                <img alt={"error-image"} src={ErrorImage} />
                                <p className={"error-msg"}>
                                  Please enter your name
                                </p>
                              </div>
                            )}
                          </>
                        </div>

                        <button
                          onClick={guestUserSubmission}
                          className={
                            "submit-button  w-fit-content text-center "
                          }
                          disabled={name === "" && pnrNumber === ""}
                        >
                          Get Booking
                        </button>
                      </div>
                    )}
                  </div>
                  {!guestUserFailureScreen && (
                    <div className={"text-center"}>
                      <p className={"sign-in text-center"}>
                        Already have an account?{" "}
                        <span onClick={() => setTypeToStore("m-signin")}>
                          Log In
                        </span>{" "}
                        to continue.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </MobileSubScreenLayout>
        </>
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  authTypeFromStore: state.AuthReducer.type,
});

const mapDispatchToProps = (dispatch) => ({
  setTypeToStore: (data) => dispatch(setAuthType(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestUserBookingMobile);
