import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
    mutation DeleteChecklistItemMutation($ids:[ID]!){
        deleteCheckListItems(ids:$ids){
            status
            message
        }
    }
    `;

export default (variables, callback, errCallback) => {
    commitMutation(
        environment,
        {
            mutation,
            variables,

            onCompleted: (response, err) => {

                if (response.deleteCheckListItem !== null) {
                    callback(response)
                } else {
                    errCallback(err[0]);
                }

            },
            onError: err => {

                errCallback(err);
            },
        },
    )
}