import graphql from "babel-plugin-relay/macro";

const GetFailureBookingByIdQuery = graphql`
	  query GetFailureBookingByIdQuery($bookingId: String!) {
	  getFailureBookingById(bookingId: $bookingId) {
			id
			passengerdetailSet{
				name
				age
				gender
				seatNo
			}
			sourceCityName
			sourceCityId
			destinationCityName
			destinationCityId
			dateOfJourney
			pickupName
			pickupTime
			dropTime
			dropName
			referenceNo
			routeId
			pnrNo
			busNumber
			seatNo
			totalAmount
			seatType
			paymentType
			paymentStatus
			paymentId
			gstInvoice
			duration
			gstAmount
			totalPayable
			getTotalPaidAmount
			totalBaseFare
			qrCode
			expectedDropDatetime
			}
		}
		`;

export default GetFailureBookingByIdQuery;