import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

//impoer swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules"; 
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";

// assets

const desktopAdvertiseImageOne = process.env.REACT_APP_CDN_LINK + `assets/images/desktopAdvertise-1.png`
const desktopAdvertiseImageTwo = process.env.REACT_APP_CDN_LINK + `assets/images/desktopAdvertise-2.png`
const desktopAdvertiseImageThree = process.env.REACT_APP_CDN_LINK + `assets/images/desktopAdvertise-3.png`
const mobileAdvertiseImageOne = process.env.REACT_APP_CDN_LINK + `assets/images/mobileAdvertise-1.png`
const mobileAdvertiseImageTwo = process.env.REACT_APP_CDN_LINK + `assets/images/mobileAdvertise-2.png`
const Vector1 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector1.jpg`
const Vector2 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector2.jpg`


const AdvertiseWithUs = () => {
    const sliderRef = useRef(null);
    const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
    const [AdvertiseWithUsData, setAdvertiseWithUsData] = useState([])

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });


    let pendingObject = { value: true, Response: "pending" }
    let sucessObject = { value: false, Response: "success" }
    let errorObject = { value: false, Response: "error" }

    const AdvertiseWithUsData1 = [
        {
            name: "On Bus Announcements",
            mobileImage: mobileAdvertiseImageOne,
            desktopImage: desktopAdvertiseImageOne,
            description:
                "Grab the opportunity to advertise information of your products and services to the passengers on-board through announcements.",
            comment: "Please write to ",
            commentChangeText: "advertise@flygofirst.com",
        },
        {
            name: "On Bus Sampling",
            mobileImage: mobileAdvertiseImageTwo,
            desktopImage: desktopAdvertiseImageTwo,
            description:
                "Sampling on bus gives you the added advantage which customers can experience your product.",
            comment: "Please write to ",
            commentChangeText: "advertise@flygofirst.com",
        },
        {
            name: "On Bus Sampling",
            mobileImage: desktopAdvertiseImageThree,
            desktopImage: desktopAdvertiseImageThree,
            description:
                " Sampling on bus gives you the added advantage which customers can experience your product.",
            comment: "Please write to ",
            commentChangeText: "advertise@flygofirst.com",
        },
    ];
    
    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);


    const AdvertiseWithUsDataGetApi = () => {
        setLoadingSpiner(pendingObject)
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/partners?populate=*`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => res.json())
        .then(
            (res) => {
                 if (res?.error) {
                    setLoadingSpiner (errorObject)
                } else {
                    setLoadingSpiner (sucessObject)
                    setAdvertiseWithUsData(res?.data)
                }
                return res;
            })
        .catch((err) => {
            setLoadingSpiner(errorObject)
            return err;
        }); 
    }

    useEffect(() => {
        AdvertiseWithUsDataGetApi()
    }, [])

    return (
        <>
            <div
                className={
                    isDesktopOrLaptop ? "advertise-with-us  pt-4 pb-3" : "advertise-with-us py-4"
                }
            >
                <h2 className="advertise-with-us-heading">Promote with Us</h2>
                {/*  */}
                {LoadingSpiner?.value == true ? <Loader /> : 
                                  <div className="slider-content mt-3 margin-bottom-64">
                    <div id="carouselExampleCaptions" className="carousel slide">
                        <div className="carousel-inner">
                            <Swiper
                                className={"py-2 px-1"}
                                spaceBetween={isDesktopOrLaptop ? 32 : 16}
                                ref={sliderRef}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                breakpoints={{
                                    1280: {
                                        slidesPerView: 2.1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1: {
                                        slidesPerView: 1.2,
                                    },
                                }}
                            >
                                { LoadingSpiner?.Response != "error" ? AdvertiseWithUsData?.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index} className="advertisement-box">
                                            <div
                                                className={
                                                    isDesktopOrLaptop ? "d-flex advertisement-text-box" : " advertisement-text-box"
                                                }
                                            >
                                                <div className={isDesktopOrLaptop ? "" : "d-flex align-items-center"}>
                                                    <img
                                                        alt="alt"
                                                        src={data?.attributes?.image}
                                                        className={isDesktopOrLaptop ? "advertise-image" : "advertise-image"}
                                                    ></img>
                                                    {!isDesktopOrLaptop &&
                                                        <p className={"advertise-name px-3"}>{data?.attributes?.title}</p>}
                                                </div>
                                                <div className={isDesktopOrLaptop ? "" : "px-3"}>
                                                    {isDesktopOrLaptop &&
                                                        <p className={"advertise-name pt-3"}>{data?.attributes?.title}</p>}
                                                    <p className="advertise-description">{data?.attributes?.description}</p>
                                                    <p className={isDesktopOrLaptop ? "advertise-comment pt-4" : "advertise-comment pt-4 text-nowrap "}>Please write to <span
                                                        className="advertise-comment-change-text">{data?.attributes?.supportEmail}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            
                            : AdvertiseWithUsData1.map((data, index) => {
                                return (
                                    <SwiperSlide key={index} className="advertisement-box">
                                        <div
                                            className={
                                                isDesktopOrLaptop ? "d-flex advertisement-text-box" : " advertisement-text-box"
                                            }
                                        >
                                            <div className={isDesktopOrLaptop ? "" : "d-flex align-items-center"}>
                                                <img
                                                    alt="alt"
                                                    src={
                                                        isDesktopOrLaptop
                                                            ? data.desktopImage
                                                            : data.mobileImage
                                                    }
                                                    className={isDesktopOrLaptop ? "advertise-image" : "advertise-image"}
                                                ></img>
                                                {!isDesktopOrLaptop &&
                                                    <p className={"advertise-name px-3"}>{data.name}</p>}
                                            </div>
                                            <div className={isDesktopOrLaptop ? "" : "px-3"}>
                                                {isDesktopOrLaptop &&
                                                    <p className={"advertise-name pt-3"}>{data.name}</p>}
                                                <p className="advertise-description">{data.description}</p>
                                                <p className={isDesktopOrLaptop ? "advertise-comment pt-4" : "advertise-comment pt-4 text-nowrap "}>{data.comment}<span
                                                    className="advertise-comment-change-text">{data.commentChangeText}</span>
                                                </p>

                                            </div>


                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                            </Swiper>
                        </div>
                        {isDesktopOrLaptop && (
                            <div className="control-icon gap-24">
                                <button
                                    onClick={handlePrev}
                                    className="carousel-control-prev-sec me-2"
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide="prev"
                                >
                                    <div className="right-icon">
                                        <img src={Vector1} alt="Vector" />
                                    </div>
                                </button>
                                <button
                                    onClick={handleNext}
                                    className="carousel-control-prev-sec"
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide="next"
                                >
                                    <div className="right-icon">
                                        <img src={Vector2} alt="Vector" />
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                }
            </div>


        </>
    );
};

export default AdvertiseWithUs;
