import graphql from "babel-plugin-relay/macro";

const GetCouponByReferenceNumberQuery = graphql`
	  query GetCouponByReferenceNumberQuery($referenceNo:String!) {
          getCouponsByReferenceNo(referenceNo:$referenceNo) {
			    discountId
			    discountSchemeName
			    discountDetails
			    fromDate
			    toDate
			    offerCode
			    discountType
			    imagePath
			    partnerId
			    applyFor
			}
	  }
`

export default GetCouponByReferenceNumberQuery;