import {commitMutation} from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import environment from "../../environment";

const mutation = graphql`
	mutation AddMoneyToWalletMutation($amount:String!, $paymentMode:PaymentModeEnum!){
		addMoneyToWallet(amount:$amount, paymentMode:$paymentMode){
			message
			payment {
			    paymentId
			    amount
			    paymentStatus
			    paymentUrl
			    transactionId
			    transactions {
			        transactionId
			        status
			        currency
			        amount
			        description
			        amountTransferred
			    }
			    paymentFor
			}
		}
	}
	`;

export default (variables, callback, errCallback) => {
	commitMutation(
		environment,
		{
			mutation,
			variables,

			onCompleted: (response, err) => {

				if (response.addMoneyToWallet !== null) {
					callback(response)
				} else {
					errCallback(err[0]);
				}

			},
			onError: err => {

				errCallback(err);
			},
		},
	)
}
