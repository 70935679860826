import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../../../components/Navbar/Navbar"
import { Link } from "react-router-dom"
import Loader from "../../../components/Loader/Loader";
import AuthModalLayout from '../../../layout/AuthModalLayout/AuthModalLayout';
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";

import '../News.scss'
//impoer swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Navigation, Pagination } from "swiper/modules";
import { useNavigate } from "react-router";
import insuranceNew from "../../../assets/icons/whatsNewInsurance.svg";
import Footer from "../../../components/Footer/Footer";
const Vector1 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector1.jpg`
const Vector2 = process.env.REACT_APP_CDN_LINK + `assets/images/static-images/Vector2.jpg`
const desktopImage = 'https://production-nuego-cms.blr1.digitaloceanspaces.com/static-contents/prod-v1/achieve1_388b66d7f8.png'

function AllnewsComponent() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();

    return (
        <div className={""}>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo News"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} />
                    </MobileSubScreenLayout>
            }
        </div>

    )
}


export const MainContent = ({ isDesktopOrLaptop }) => {
    //console.log("parameters.get",parameters.get("pnr"))

    const [loading, setLoading] = useState(false);
    const sliderRef = useRef(null);

    const [topnews, setTopNews] = useState([]);
    const [allnews, setAllnews] = useState([]);

    const navigate = useNavigate();

    const handlePrev = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-news?filters[isTop][$eq]=true&sort=rank:asc&populate=deep,3`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then((res) => {
                setTopNews(res.data)
                console.log(res.data)
            })

            .catch((err) => {
                console.log("res", err);
            });
    }, [])

    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-news?filters[isTop][$eq]=false&sort=rank:asc&populate=deep,3`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((res) => res.json())
            .then((res) => {
                setAllnews(res.data)
            })

            .catch((err) => {
                console.log("res", err);
            });
    }, [])

    const TripCityFun = (items) => {
        navigate(`/newsroom/${items?.id}`, {
        })
    }


    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }

    return (
        <>
            {isDesktopOrLaptop && <Navbar />}
            {loading ? <Loader /> :
                <div className={"main-content Allnews-Page container"}>
                    {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <Link to="/news"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0 '}>Newsroom</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>All News</p>
                    </div>
                    }

                    <section>
                        <div className='d-flex justify-content-between mb-4'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <h2 className='ubuntu-700w-32s-48h black-1E1E26-color mb-0'>Top Stories</h2>
                            </div>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <div className='Naviagation-arrow-div cursor-pointer' onClick={handlePrev}>
                                    <img src={Vector1} alt="" />
                                </div>
                                <div className='Naviagation-arrow-div cursor-pointer' onClick={handleNext}>
                                    <img src={Vector2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <Swiper
                                className={"py-2 px-1"}
                                spaceBetween={isDesktopOrLaptop ? 32 : 16}
                                ref={sliderRef}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                breakpoints={{
                                    1280: {
                                        slidesPerView: 2.5,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    460: {
                                        slidesPerView: 1.3,
                                    },
                                }}
                            >
                                {topnews?.map((data, index) => {
                                    return (
                                        <SwiperSlide key={index} className="advertisement-box">
                                            <div className="allnews-topCardNews cursor-pointer" onClick={(e) => TripCityFun(data)}>
                                                <img className="topnewsImage" src={data?.attributes?.image} alt="" />
                                                <div className="sub-heading-desc">
                                                    <h3>{data?.attributes?.title}</h3>
                                                    <p>{data?.attributes?.imageAltText}</p>
                                                </div>
                                                <div className='d-flex justify-content-start align-items-center gap-2' >
                                                    <img className='icon-24' src={insuranceNew} alt="" />
                                                    <p className='open-400w-16s-24h black-1E1E26-color mb-0'>{data?.attributes?.source}</p>
                                                    <p className='open-400w-16s-24h grey-text mb-0'>{data?.attributes?.date}</p>
                                                </div>

                                            </div>

                                        </SwiperSlide>
                                    );
                                })
                                }
                            </Swiper>
                        </div>
                    </section>

                    <section className="mt-4 all-news-section">
                        <div className='d-flex justify-content-between mb-4'>
                            <div className='d-flex justify-content-start align-items-center gap-2'>
                                <h2 className='ubuntu-700w-32s-48h black-1E1E26-color mb-0'>All Stories</h2>
                            </div>
                            <div className=' select-filter d-flex justify-content-start align-items-center gap-2'>
                                <select name="" id="">
                                    <option value="">May, 2024</option>
                                    <option value="">April, 2024</option>
                                </select>
                            </div>
                        </div>
                        <div className="all-stories-main-div">
                            {
                                allnews.map((el, ind) => {
                                    return <div className="all-stories-single-card cursor-pointer"  onClick={(e) => TripCityFun(el)}>
                                        <img className='allnews-single-main-image' src={el?.attributes?.image} alt="" />
                                        <p className='title-tag'>{el?.attributes?.title}</p>
                                        <div className='d-flex justify-content-start align-items-center gap-2' >
                                            <img className='icon-24' src={insuranceNew} alt="" />
                                            <p className='open-400w-16s-24h grey-text mb-0'>{el?.attributes?.date}</p>
                                        </div>
                                    </div>
                                })
                            }

                        </div>
                    </section>


                </div>

            }
            <Footer />
        </>
    );
}

export default AllnewsComponent


{/* <AuthContextProvider>
<Navbar/>
<div className='Allnews-Page container'>
   
   
</div>
<Footer/>
</AuthContextProvider> */}