//assets
import shareIcon from "../../assets/icons/shareIcon.svg"
import downloadIcon from "../../assets/icons/downloadIcon.svg"
import changeIcon from "../../assets/icons/changeIcon.svg"
import digitalIcon from "../../assets/icons/qr-code.png"


export const bottomNavList = [
    {
        id: "share",
        icon: shareIcon,
        // activeIcon: shareActiveIcon,
        name: "share"
    },
    {
        id: "download",
        icon: downloadIcon,
        // activeIcon: downloadActiveIcon,
        name: "download \n ticket"
    },
    {
        id: "digital",
        icon: digitalIcon,
        // activeIcon: changeActiveIcon,
        name: "E - Ticket"
    },
    {
        id: "change",
        icon: changeIcon,
        // activeIcon: changeActiveIcon,
        name: "Change Booking"
    },

]

export const WithoutLoginBottomNavList = [
    {
        id: "share",
        icon: shareIcon,
        // activeIcon: shareActiveIcon,
        name: "share"
    },
    {
        id: "digital",
        icon: digitalIcon,
        // activeIcon: changeActiveIcon,
        name: "E - Ticket"
    },
    {
        id: "download",
        icon: downloadIcon,
        // activeIcon: downloadActiveIcon,
        name: "download \n ticket"
    },
]