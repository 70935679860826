import React from 'react'
import {useNavigate, useParams} from "react-router-dom";
import "./mobileOffer.scss";
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";
// icons
import copyIcon from "../../assets/icons/copy-icon.svg"

// assets
const mobileOfferBg = process.env.REACT_APP_CDN_LINK + `assets/images/mobileOfferBg.png`
const BackArrow = process.env.REACT_APP_CDN_LINK + `assets/images/back-arrow.png`
const offerBgMap = process.env.REACT_APP_CDN_LINK + `assets/images/offer-bg-map.png`
const cardLogoOne = process.env.REACT_APP_CDN_LINK + `assets/images/offerLogoOne.png`
const cardLogoTwo = process.env.REACT_APP_CDN_LINK + `assets/images/offerLogoTwo.png`
const OfferModalFrame = process.env.REACT_APP_CDN_LINK + `assets/images/offerModalframe.png`

const offerModalData = [
    {
        id: 1,
        frameImage: OfferModalFrame,
        discountDetails: "Get upto ₹200 cashback on MobiKwik Zip",
        superOffer: cardLogoOne,
        toDate: " Hurry! Offer expires on 31st May ",
        offterDatas: [
            {offerData: "No coupon code required to avail the offer"},
            {offerData: "Offer is valid from 18th April 2023 to 31st May 2023"},
            {offerData: "Offer is applicable for a minimum transaction value of INR 400. Minimum assured cashback of INR 40."},
            {offerData: "User must select MobiKwik Zip option on the Payment Gateway to complete the transaction"},
            {offerData: "Offer is valid only once per month per user during the Campaign"},
            {offerData: "Cashback will be credited to customer’s MobiKwik wallet within 24 hours"},
        ]
    },
    {
        id: 2,
        frameImage: OfferModalFrame,
        discountDetails: "Get upto ₹100 cashback on MobiKwik Wallet",
        superOffer: cardLogoTwo,
        toDate: " Hurry! Offer expires on 31st May",
        offerCode: "CODE: MBK100",
        offterDatas: [
            {offerData: "Get up to INR 100 cashback on bookings done with NueGo"},
            {offerData: "Offer is valid from 18th April 2023 to 31st May 2023"},
            {offerData: "Offer is applicable for a minimum transaction value of INR 500."},
            {offerData: "Customers must select the MobiKwik option on the Payment Gateway and enter the coupon code MBK100 there"},
            {offerData: "Offer is valid only once per month per user during the Campaign"},
            {offerData: "Cashback will be credited to customer’s MobiKwik wallet within 24 hours"},
        ]
    }
]
const MobileOffer = () => {
    const param = useParams();
    const navigate = useNavigate()
    const getParams = parseInt(param.id);


    const filterData = offerModalData.filter((data) => {
        return data.id === getParams;
    });
    const copyToClipboardHandler = (value) => {
        if (value !== null) {
            navigator.clipboard.writeText(value);
            cogoToast.success(<CustomToast
                type={"success"}
                message={"Copied to clipboard"}
                heading={"Success"}
            />, {position: "top-center"});
        } else {
            cogoToast.error(<CustomToast
                type={"error"}
                message={"Something went wrong, Unable to get the Coupon"}
                heading={"Error"}
            />, {position: "top-center"});
        }
    };

    return (
        <div className={'mobile-offer'}>
            <div className={'bg-image'} style={{backgroundImage: `url(${mobileOfferBg})`}}>
                <img src={BackArrow} alt={'alt'} onClick={() => navigate(-1)}/>
            </div>
            {
                filterData.map((data, index) => (
                    <div key={index}>
                        <div className={'logo-image'}>
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img src={data.superOffer} alt={'offer-image-thumbnail'}/>
                        </div>
                        <p className={'offer-details text-center'}>
                            {data.discountDetails}
                        </p>
                        {data.offerCode && (<div
                            className={"offer-container w-fit-content d-flex align-items-center  "}
                            onClick={() => copyToClipboardHandler(data.offerCode)}
                        >
                            <img
                                src={copyIcon}
                                alt={"copyIcon"}
                                className={"icon-16"}

                            />
                            <p
                                className={'px-2'}
                                style={{color: "#1E1E26", paddingTop: '12px'}}>
                                {data.offerCode}
                            </p>
                        </div>)}
                        <p className={'expire-date text-center  p-3 '}>{data.toDate}</p>
                        <div className={' terms-and-condition'}>
                            <p className={'terms-and-condition-heading '}>Terms & Conditions</p>
                            <ul>
                                {
                                    data.offterDatas.map((data, index) => (


                                        <li key={index}>{data.offerData}</li>


                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                ))
            }
            <div className={'bg-map'} style={{backgroundImage: `url(${offerBgMap})`}}>

            </div>


        </div>
    )

}

export default MobileOffer


