import React, { useEffect, useState } from "react";
import "../Careers.scss";
import Loader from "../../../components/Loader/Loader";
import FallBack from "../../../components/FallBack";

//assets
const achieve1 = process.env.REACT_APP_CDN_LINK + `assets/images/achieve1.png`
const achieve2 = process.env.REACT_APP_CDN_LINK + `assets/images/achieve2.png`
const achieve3 = process.env.REACT_APP_CDN_LINK + `assets/images/achieve3.png`
const achieve4 = process.env.REACT_APP_CDN_LINK + `assets/images/achieve4.png`


const Achievement = () => {

  const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
  const [AcheivementData, setAcheivementData] = useState([])
  
  let pendingObject = { value: true, Response: "pending" }
  let sucessObject = { value: false, Response: "success" }
  let errorObject = { value: false, Response: "error" }


  useEffect(() => {

    setLoadingSpiner(pendingObject)

    fetch(`${process.env.REACT_APP_CMS_PROD_API}api/achievements?populate=*`,
      {
        headers: {
          'Content-Type': 'application/json',
        },

      })
      .then((res) => res.json())

      .then(
        (res) => {
          if (res?.error) {
            setLoadingSpiner(errorObject)

          } else {
            setLoadingSpiner(sucessObject)
            setAcheivementData(res?.data)
          }

          return res;

        })

      .catch((err) => {
        setLoadingSpiner(errorObject)
        return err;

      });

  }, [])

return (
    <div className="Nuego-achievement">
      <div className="head">NueGo Achievements</div>
      {LoadingSpiner?.value == true ? <Loader /> :
        LoadingSpiner?.Response != "error" ?
          <div className="achieve-flex">
            {AcheivementData?.map((items, id) => {
              return <div className="achieve">
                <img src={items?.attributes?.image} alt={"achieve1"} className={"achieve1"} />
                <div className="sub-head">
                  {items?.attributes?.title}
                </div>
              </div>
            })}
          </div>
          :
          <div className="achieve-flex ">
            <div className="achieve">
              <img src={achieve1} alt={"achieve1"} className={"achieve1"} />
              <div className="sub-head">
                The Rt Hon Alok Sharma, COP26 President and His Excellency Alex Ellis,
                British High Commissioner to India launched NueGo e-buses{" "}
              </div>
            </div>
            <div className="achieve">
              <img src={achieve2} alt={"achieve2"} className={"achieve2"} />
              <div className="sub-head">
                NueGo is now Great Place To Work - Certified organization.
              </div>
            </div>
            <div className="achieve">
              <img src={achieve3} alt={"achieve3"} className={"achieve3"} />
              <div className="sub-head">
                NueGo launch announced on the Press Journal
              </div>
            </div>
            <div className="achieve">
              <img src={achieve4} alt={"achieve4"} className={"achieve4"} />
              <div className="sub-head">
                GreenCell Mobility launches intercity coach NueGo
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default Achievement;
