import React, {useEffect, useState} from 'react'
import {useMediaQuery} from "react-responsive";
import {cancelBasedOnCompanyData} from "../../services/api/DataFetching";
import {convertByPercentage, minutesToHours} from "../../utils/utils";
import Loader from "../Loader/Loader";

const PoliciesComponent = ({item}) => {
	const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
	const [activeTab, setActiveTab] = useState('cancel');

	let componentConstants

	if (isDesktopOrLaptop) {
		componentConstants = {
			mainText_class: "ubuntu-700w-18s-28h",
			tabText_class: "open-700w-18s-28h",
			headingText_class: "open-600w-16s-24h",
			description_class: "open-600w-14s-22h",
			option_class: "open-600w-16s-24h",
			optionRate_class: "open-700w-16s-24h",
			points_class: "open-400w-16s-24h"
		}
	} else {
		componentConstants = {
			mainText_class: "ubuntu-700w-18s-28h",
			tabText_class: "open-700w-14s-22h",
			headingText_class: "open-600w-16s-24h",
			description_class: "open-400w-12s-18h",
			option_class: "open-600w-14s-22h",
			optionRate_class: "open-700w-14s-22h",
			points_class: "open-400w-14s-22h"
		}
	}

	// methods
	const tabHandler = (value) => {
		setActiveTab(value)
	}

	return (
		<div className={'policies-component'}>
			{!isDesktopOrLaptop && <p className={componentConstants.mainText_class + " p-3 "}>Booking Policies</p>}

			<div className={'d-flex tab-wrap'}>
				{/* <div onClick={() => tabHandler("date")}
				     className={(activeTab === 'date' ? " active-tab " : " in-active-tab ")
					     + (!isDesktopOrLaptop && " w-100 text-center ")}>
					<p className={componentConstants.tabText_class + " mb-0 "}>Date & Time Change</p>
				</div> */}
				<div onClick={() => tabHandler("cancel")}
				     className={(activeTab === 'cancel' ? " active-tab " : " in-active-tab ")
					     + (!isDesktopOrLaptop && " w-100 text-center ")}>
					<p className={componentConstants.tabText_class + " mb-0 "}>Cancellation Policy</p>
				</div>
			</div>
			{activeTab === "date" ? <div>
					<DateChangeSection componentConstants={componentConstants}/>
				</div>
				:
				<div>
					<CancellationSection componentConstants={componentConstants} busData={item}/>
				</div>}

		</div>
	)

}

// sections
const DateChangeSection = ({componentConstants}) => {
	let dateCategories = []
	let optionList = []



	return <div className={'px-lg-1 px-3 py-4'}>
		<div className={''}>
			<div className={'row'}>
				<div className="col-6">
					<p className={componentConstants.headingText_class}>
						Rescheduling Charges</p>
				</div>
				<div className="col-6"></div>
			</div>
			<hr className={'horizontal-divider'}/>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>More than 12Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>10% of fare paid</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>Within 12Hrs to 4Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>25% of fare paid</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>Less than 4Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>Not permitted</p></div>
			</div>


		</div>

		<hr className={'horizontal-divider'}/>
		{
			optionList.length > 0 &&
			optionList.map((option, index) => {
				return <div className={'d-flex'} key={index}>
					<div className={'dot'}/>
					<p className={componentConstants.points_class}>option</p>
				</div>
			})
		}
	</div>
}

export const CancellationSection = ({componentConstants, busData}) => {
	const [dateCategories, setDateCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	let optionList = []

	useEffect(() => {

		cancelBasedOnCompanyData((response) => {
			setDateCategories(response)
			setLoading(false)
		}, () => {
			setLoading(false)
		})
		return () => {

		};
	}, []);

	return <div className={'px-lg-1 px-3 py-4'}>
		<div className={'row'}>
			<div className="col-6">
				<p className={componentConstants.headingText_class}>Cancellation Window</p>
			
			</div>
			<div className="col-4">
				<p className={componentConstants.headingText_class}>Refund %</p>
			</div>
			{/* <div className="col-4">
				<p className={componentConstants.headingText_class}>Refund ₹</p>
			</div> */}
		</div>

		{
			loading ?
			<Loader/>
			:
			dateCategories.length > 0 &&
			dateCategories.map((category, index) => {
				return <div className={'row'} key={index}>
					{index==0?
					<div className="col-6">
							<p className={componentConstants.option_class}>Between {minutesToHours(category.fromMinutes)} - {minutesToHours(category.toMinutes)} hrs remaining</p>
					</div>:
					<div className="col-6">
					<p className={componentConstants.option_class}>Before {minutesToHours(category.fromMinutes)}hrs prior to journey</p>
					</div>
					}
					<div className="col-4">
							<p className={componentConstants.option_class}>{category.refundPercentage}%</p>
					</div>
						{/* <div className="col-4">
							<p className={componentConstants.optionRate_class}>₹ {convertByPercentage(parseInt(busData?.AcSeatRate), category.refundPercentage)}</p>
					</div> */}
					</div>
				})
		}


		{/* <div className={''}>
			<div className={'row'}>
				<div className="col-6">
					<p className={componentConstants.headingText_class}>Cancellation Charges</p>
				</div>
				<div className="col-6"></div>
			</div>
			<hr className={'horizontal-divider'}/>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>More than 48Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>0%</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>48Hrs – 12Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>25% of fare paid</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>12Hrs-4Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>50% of fare paid</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>Less than 4Hrs prior to departure*</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>100% of fare paid</p></div>
			</div>
			<div className={'row'}>
				<div className={"col-6"}><p className={componentConstants.option_class}>No-show</p></div>
				<div className="col-6"><p className={componentConstants.points_class}>100% of fare paid</p></div>
			</div>

		</div> */}

		<hr className={'horizontal-divider'}/>
		{/* {
			optionList.length > 0 &&
			optionList.map((option, index) => {
				return <div className={'d-flex'} key={index}>
					<div className={'dot'}/>
					<p className={componentConstants.points_class}>option</p>
				</div>
			})
		} */}
	</div>
}

export default PoliciesComponent
