import graphql from "babel-plugin-relay/macro";

const GetDestinationListingQuery = graphql`
	query GetDestinationListingQuery($cityId: Int!, $searchTerm: String) {
		getDestinationsBySource(cityId: $cityId, searchTerm: $searchTerm) {
			CMCityname
			CMCityid
			}
	}
	`
export default GetDestinationListingQuery