/**
 * @generated SignedSource<<434facf0ed0c99d19f869bc70f7a25c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "limit"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "walletType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "limit",
        "variableName": "limit"
      },
      {
        "kind": "Variable",
        "name": "walletType",
        "variableName": "walletType"
      }
    ],
    "concreteType": "WalletType",
    "kind": "LinkedField",
    "name": "getTransactions",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "TransDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Debit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Credit",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "DebitCreditStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ClosingBalance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "NonPromotionalBalance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "Details",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetTransactionDetailsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetTransactionDetailsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f651dbc3affa293b9eef99a359ad769",
    "id": null,
    "metadata": {},
    "name": "GetTransactionDetailsQuery",
    "operationKind": "query",
    "text": "query GetTransactionDetailsQuery(\n  $limit: Int\n  $walletType: WalletTypeEnum\n) {\n  getTransactions(limit: $limit, walletType: $walletType) {\n    TransDate\n    Debit\n    Credit\n    DebitCreditStatus\n    ClosingBalance\n    NonPromotionalBalance\n    Details\n  }\n}\n"
  }
};
})();

node.hash = "be3819daab1929ed549f0cdd57e42151";

module.exports = node;
