import {commitMutation} from 'react-relay';
import graphql from "babel-plugin-relay/macro";
import environment from '../../environment';

const mutation = graphql`
	mutation UpdateProfileMutation($userId:String!,
	$gender:ProfileGenderEnum!,
	$gstState:String,
	$name:String!,
	$registeredName:String,
	$email:String!,
	$gstin:String,
	$age:Int!){
	updateProfile(
		userId:$userId,
		gender:$gender,
		gstState:$gstState,
		name:$name,
		registeredName:$registeredName,
		email:$email,
		gstin:$gstin,
		age:$age
		){
		userDetail {
		        id
	  		    walletAmount
				greenMilesAmount
		      user {
		        mobileNumber
				    username
				    firstName
				    lastName
				    email
				    isActive
				    savedcontactdetail {
				        user {
				            email
				            mobileNumber
				            firstName
				            lastName
				
				        }
				    }
		      }
		      profilePic
				age
				gender
				registeredName
				gstin
				gstState
				profilePicUrl
		     }
	    
	    }
  }
  `

export default (data, callback, errCallback) => {
	const variables = {
		userId: data.userId,
		name: data.name,
		gender: data.gender,
		gstState: data.gstState,
		registeredName: data.registeredName,
		email: data.email,
		gstin: data.gstin,
		age: data.age
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.updateProfile !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			},
			onError: err => {
				errCallback(err);
			}
		}
	)

}
