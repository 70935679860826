import React from 'react'
import '../ChecklistWidget.scss'
import {MDBModal, MDBModalBody} from "mdbreact";
import BottomTrayComponent from "../../BottomTrayComponent/BottomTrayComponent";
import {useMediaQuery} from "react-responsive";
//icons
import closeIcon from "../../../assets/icons/cross-gray.svg"


const ChecklistNameWidget = ({
                                 nameModal,
                                 setNameModal,
                                 checklistName,
                                 setChecklistName,
                                 componentData,
                                 checklistNameSubmissionHandler
                             }) => {

    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    return (
        <div className={'checklist-name-widget'}>
            {
                isDesktopOrLaptop ?
                    <div className={'desktop-edit-checklist-modal'}>
                        <MDBModal isOpen={nameModal} position={'center'} centered={true}
                                  size={"fluid"}>
                            <MDBModalBody>
                                <div className={'create-checklist-content'}>
                                    <img src={closeIcon} alt={'close-icon'} className={'close-icon'}
                                         onClick={() => setNameModal(false)}/>
                                    <p className={"ubuntu-700w-24s-32h black-1E1E26-color margin-bottom-32"}>Create
                                        Checklist Name</p>

                                    <div>
                                        <div className=" mb-3">
                                            {/* name */}
                                            <p className={componentData.secondary_text_class}>Name </p>
                                            <div className={'common-input-wrap input-name w-75'}>
                                                <input className="border-less-input w-100"
                                                       value={checklistName}
                                                       onChange={(e) => {
                                                           setChecklistName(e.target.value)
                                                       }}
                                                       type={"text"}/>
                                            </div>
                                        </div>

                                        <div className={'d-flex justify-content-end'}>
                                            <button className={'submit-button mr-3'}
                                                    disabled={checklistName.length === 0}
                                                    onClick={checklistNameSubmissionHandler}>Save
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </MDBModalBody>
                        </MDBModal>
                    </div>
                    :
                    <div className={''}>
                        <BottomTrayComponent
                            isOpen={nameModal}
                            title={'Create Checklist Name'}
                            closeHandler={() => setNameModal(false)}
                            hideClearAll={true}
                            removeBottomSpacing={true}
                        >
                            <div className={'create-checklist-content px-3'}>
                                <div>
                                    <div className=" mb-3">
                                        {/* name */}
                                        <p className={componentData.secondary_text_class}>Name </p>
                                        <div className={'common-input-wrap input-name '}>
                                            <input className="border-less-input w-100"
                                                   value={checklistName}
                                                   onChange={(e) => {
                                                       setChecklistName(e.target.value)
                                                   }}
                                                   type={"text"}/>
                                        </div>
                                    </div>

                                    <div className={'d-flex justify-content-center w-100'}>
                                        <button className={'submit-button w-100'}
                                                disabled={checklistName.length === 0}
                                                onClick={checklistNameSubmissionHandler}>Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </BottomTrayComponent>
                    </div>
            }
        </div>
    )
}

export default ChecklistNameWidget
