import React ,{useEffect,useState}from 'react'
import { useNavigate } from 'react-router'
import "./TravelItineraries.scss"
import triputi from "../../../../../assets/magazine/images/triputi.jpeg"
import indore from "../../../../../assets/magazine/images/indore.jpg"
import jaipur from "../../../../../assets/magazine/images/jaipur.jpeg"
import chennai from "../../../../../assets/magazine/images/chennai.jpg"

function TravelItineraries() {
    const [itineraries, setItineraries] = useState([])
    const [showAll, setShowAll] = useState(false);
    const navigate = useNavigate();

    const itemsToDisplay = showAll ? itineraries : itineraries.slice(0, 6);


    let tripData = [

        {

            "day": " 2 Days ",

            "place": "Chennai",

            "image": chennai

        },

        {

            "day": " 2 Days ",

            "place": "Indore",

            "image": indore

        },

        {

            "day": " 2 Days ",

            "place": "jaipur",

            "image": jaipur

        }

        ,{

            "day": " 2 Days ",

            "place": "Tirupati",

            "image": triputi

        },

        {

            "day": " 2 Days ",

            "place": "Chennai",

            "image": chennai

        },

        {

            "day": " 2 Days ",

            "place": "Indore",

            "image": indore

        },
    ]


    useEffect(() => {
        fetch(`${process.env.REACT_APP_CMS_PROD_API}api/plan-trips?populate=*`)
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res?.error) {

                    } else {
                        setItineraries(res?.data)
                    }
                    return res;
                })
            .catch((err) => {
                return err;
            });
    }, [])

    const TripCityFun = (e, items, AllData) => {
        navigate(`/travel-itineraries/trip?city=${items?.attributes?.city?.cityName}`, {
           state: {
               currentData: items,
               AllData
           }
       })
   }

  return (
    <>
    
    <div className='Blog-travel-Iteneraries'>
        <div className='head-subhead'>
            <div className='head'>
                <img className='icon' src="https://cdn.nuego.co.in/greencell/assets/images/Discount.png" alt="" />
                <h2 className='heading'>Travel Itineraries: Route to Adventure</h2>
            </div>
            <h3 className='subhead'>Choose from our thoughtfully curated itinerary and embark on a journey with us</h3>
        </div>
        <div className='itineraries-cards'>
            {
                itemsToDisplay.map((item,ind)=><div onClick={(e) => TripCityFun(e, item, itineraries)} className='itineraries-card' key={ind}>
                    <img className='itinerary-img' src={item?.attributes?.image} alt="" />
                    <div className='name-duration'>
                        <h4 className='name'>{item?.attributes.city.cityName}</h4>
                        <p className='duration'>{item?.attributes.days.length} Days</p>
                    </div>
                </div>

                )
            }
            
        </div>
        <button className='view-all-cta' onClick={()=>setShowAll(!showAll)}>{showAll?'View Less':'View All'}</button>
    </div>
    </>
  )
}

export default TravelItineraries