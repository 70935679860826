import React , {useEffect, useState} from 'react'
import { useMediaQuery } from "react-responsive";
import insuranceNew from "../../../assets/icons/whatsNewInsurance.svg";
import nuegoLogo from '../../../assets/icons/nuegoLogoAnnouncement.svg'
import ReactMarkdown from "react-markdown";
// import downArrow from "../../../assets/icons/downArrow-green.svg"
const readMore = process.env.REACT_APP_CDN_LINK + `assets/images/read-more-icon.png`

function AnnouncementComponent() {

  const [topAnnouncement, setTopAnnouncement] = useState([]);

  const [showAll, setShowAll] = useState(false);


  const itemsToDisplay = showAll ? topAnnouncement : topAnnouncement.slice(0, 3);

  useEffect(()=>{
      fetch(`${process.env.REACT_APP_CMS_PROD_API}api/latest-announcements?sort=rank:asc&populate=deep,3`,
      {
          headers: {
              'Content-Type': 'application/json',
          },
      })
      .then((res) => res.json())
      .then((res) => {
        setTopAnnouncement(res.data)
          })

      .catch((err) => {
          console.log("res",err);
      });
  },[])

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
});

  return (
    <div className='section-Announcement'>
        <div className='d-flex justify-content-between mb-4'>
            <div className='d-flex justify-content-start align-items-center gap-2'>
                <img src={insuranceNew} alt=""  className='icon-48'/>
                <h2 className='announcement-heading-main'>Announcements</h2>
            </div>
            { topAnnouncement.length>3 && <div className='d-flex justify-content-start align-items-center gap-2 cursor-pointer' onClick={()=>setShowAll(!showAll)}>
               
                <h2 className='view-all teal-22BBB0-color mb-0'>{showAll?'View Less':'View All'}</h2>
                <img src={readMore} alt="" />
            </div>}
        </div>
        <div class="accordion accordion-flush d-flex flex-column gap-3" id="accordionFlushExample">

{ 
  itemsToDisplay.map((item,index)=>{

    return <div class="accordion-item">
    <h2 class="accordion-header" id={`flush-heading${index}`}>
      <button class="accordion-button p-0 rounded collapsed d-flex flex-row gap-2 align-items-center justify-content-between w-100" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
        <div className='d-flex flex-row gap-2 align-items-center' >
          <img  className='rounded accordion-img' src={item?.attributes?.image} alt="" /> 
          <p className='announcement-heading'>{item?.attributes?.title}</p>
          </div>
        {isDesktopOrLaptop && 
        <div className='d-flex flex-row gap-2 align-items-center' ><img style={{width:"24px", height: '24px'}} className='rounded-circle' src={nuegoLogo} alt="" /> <p className='mb-0'>NueGo</p> . <p className='mb-0'>{item?.attributes?.date}</p></div>}
      </button>
    </h2>
    <div id={`flush-collapse${index}`} class="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
      <div class="accordion-body" ><ReactMarkdown>{item?.attributes?.description}</ReactMarkdown> </div> 
    </div>
  </div>

  })
} 
        </div>
        
    </div>
  )
}

export default AnnouncementComponent