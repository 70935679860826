import graphql from "babel-plugin-relay/macro";

const RescheduleStatusCheckQuery = graphql`
		query RescheduleStatusCheckQuery($bookingId:String){
			checkRescheduleStatus(bookingId:$bookingId){
				rescheduleStatus {
				    allowReschedule
				    rescheduleCharge
				}
			}
		}
	
	`

export default RescheduleStatusCheckQuery